import React from "react";
import { Box, Typography } from "@mui/material";
import "pages/Products/product.css";

export const RadioSelector = ({
  data,
  onChange,
  level,
  title,
  defaultSelected,
  isChecked,
  imageId,
  setImageId,
  radioSelected,
  setRadioSelected,
  selectValue,
}: any) => {
  return (
    <>
      <Box>
        <input
          type="radio"
          id={data?.id}
          name={title}
          value={JSON.stringify(data)}
          onChange={(e) => onChange(e, level)}
          defaultChecked={isChecked}
          checked={JSON.stringify(data) === selectValue}
          style={{ visibility: "hidden", position: "absolute" }}
        />
        <label
          style={{
            cursor: "pointer",
            fontSize: "20px",
            fontWeight: 600,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center ",
            gap: "10px",
          }}
          htmlFor={data?.id}
          onClick={() => {
            // setImage(data?.icon);
            setImageId(data?.id);
            setRadioSelected(true);
          }}
        >
          <img
            className="radioImage"
            src="https://printcalgary.com/wp-content/uploads/2021/10/01-6.jpg"
            alt=""
          />
          <Box>
            {data?.title && (
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "700",
                  fontFamily: "rajdhani",
                  color: "#000",
                }}
              >
                {data?.title}
              </Typography>
            )}
            {data?.description && (
              <Typography
                sx={{
                  fontFamily: "sans-serif",
                  fontSize: ".9rem",
                  color: "#000",
                }}
              >
                {data?.description}
              </Typography>
            )}
          </Box>
        </label>
      </Box>
    </>
  );
};
