import { BasicButton, ContainerBox, MediaCard } from "components";
// import { TextareaAutosize, Typography } from "@mui/material";
import { BasicInput } from "./../../components/Input/BasicInput";
import { ChangeEvent, useRef, useState, FormEvent } from "react";
import { errorToaster } from "utils/toaster";
import { goToTop } from "utils/goToTop";
import TextField from "@mui/material/TextField";
// import { type } from './../../types/login.d';
import { SendFileMediaCard } from "assets";
import { Box } from "@mui/material";

export const RequestAQuote = () => {
  const [formData, setFormData] = useState<any>({
    product_name: "",
    quantity: "",
    size: "",
    product_details: "",
    name: "",
    phone: "",
    company_name: "",
    email: "",
    need_design: "",
    hear_about_us: "Google Search",
    promo_code: "",
    // file_upload: "",
    colours: "",
    side: "",
    turnaround: "",

    // company_name: "",
    // country_name: "",
    // email_address: "",
    // first_name: "",
    // last_name: "",
    // phone: "",
    // postcode: "",
    // province: "",
    // street_address_1: "",
    // street_address_2: "",
    // town_city: "",
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  // const [checkedValues, setCheckedValues] = useState<{ [key: string]: string }>({
  //   need_design: "",
  //   side: "",
  //   colours:"",
  //   turnaround:"",
  // });

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const radioValue = event.target.value;
    const radioName = event.target.name;

    setFormData((prevValues: any) => ({
      ...prevValues,
      [radioName]: radioValue,
    }));
  };

  const handleFileChange = (event: any) => {
    const files = event.target.files[0];

    setFormData({
      ...formData,
      file_upload: files,
    });
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formData?.product_name.trim() === "") {
      errorToaster("Please fill product name");
    } else if (formData?.quantity.trim() === "") {
      errorToaster("Please fill quantity");
    } else if (formData?.size.trim() === "") {
      errorToaster("Please fill size");
    } else if (formData?.product_details.trim() === "") {
      errorToaster("Please fill product details");
    } else if (formData?.name.trim() === "") {
      errorToaster("Please fill name");
    } else if (formData?.phone.trim() === "") {
      errorToaster("Please fill phone number");
    } else if (
      /^(?:\+?1[-.\s]?)?(?:\([2-9][0-9]{2}\)|[2-9][0-9]{2})[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/.test(
        formData?.phone
      ) !== true
    ) {
      errorToaster("Phone Number is not valid");
    } else if (formData?.email.trim() === "") {
      errorToaster("Please fill email id");
    } else if (
      /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData?.email) !==
      true
    ) {
      errorToaster("Email is not valid");
    } else {
      setFormData({
        product_name: "",
        quantity: "",
        size: "",
        product_details: "",
        name: "",
        phone: "",
        company_name: "",
        email: "",
        need_design: "",
        hear_about_us: "Google Search",
        promo_code: "",
        // file_upload: "",
        colours: "",
        side: "",
        turnaround: "",
      });
      // Clear the file input field
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      // Reset the form
      if (formRef.current) {
        formRef.current.reset();
      }
      goToTop();
    }
  };

  return (
    <>
      <Box sx={{ marginBottom: "60px" }}>
        <MediaCard
          title="Request a Quote for printed Materials Today!"
          subtitle1=" Whether you need logo stickers, business cards or banners, Print
          Calgary has you"
          subtitle2="covered. Contact Us today at &nbsp;(403)235-1616 &nbsp;for your
          free quote!"
          minHeight="300px"
          backgroundImage={SendFileMediaCard}
        />
      </Box>
      <ContainerBox>
        <Box
          sx={{
            border: "1px dotted #ec0a90",
            borderRadius: "6px",
            backgroundColor: "#f6f6f6",
          }}
        >
          <form ref={formRef} onSubmit={handleFormSubmit}>
            <Box sx={{ padding: "40px 30px" }}>
              <Box
                sx={{
                  display: { xs: "flex", md: "flex" },
                  marginBottom: { xs: "20px", md: "20px" },
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", md: "row" },
                  //@ts-ignore
                  gap: "20px",

                  // //@ts-ignore
                  // "@media (max-width:600px)": {
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   gap: "10px",
                  //   marginBottom: "10px",
                  // },
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", md: "30%" },
                    // //@ts-ignore
                    // "@media (max-width:600px)": {
                    //   width: "100%",
                    // },
                  }}
                >
                  <p style={{ fontSize: "18px", fontWeight: "600" }}>
                    Product Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <BasicInput
                    placeholder="Product Name"
                    type="text"
                    name="product_name"
                    value={formData?.product_name}
                    onChange={handleChange}
                    size="100%"
                    isRequired
                  />
                </Box>
                <Box
                  sx={{
                    width: { sx: "100%", md: "30%" },
                    // //@ts-ignore
                    // "@media (max-width:600px)": {
                    //   width: "100%",
                    // },
                  }}
                >
                  <p style={{ fontSize: "18px", fontWeight: "600" }}>
                    Quantity <span style={{ color: "red" }}>*</span>
                  </p>
                  <BasicInput
                    placeholder="Quantity"
                    type="number"
                    name="quantity"
                    value={formData?.quantity}
                    onChange={handleChange}
                    size="100%"
                    isRequired
                  />
                </Box>
                <Box
                  sx={{
                    width: { sx: "100%", md: "30%" },
                    // //@ts-ignore
                    // "@media (max-width:600px)": {
                    //   width: "100%",
                    // },
                  }}
                >
                  <p style={{ fontSize: "18px", fontWeight: "600" }}>
                    Size <span style={{ color: "red" }}>*</span>
                  </p>
                  <BasicInput
                    placeholder="Size"
                    type="text"
                    name="size"
                    value={formData?.size}
                    onChange={handleChange}
                    size="100%"
                    isRequired
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  flexDirection: { xs: "column", md: "row" },
                  //@ts-ignore
                  gap: "20px",
                  //  @ts-ignore
                  // "@media (max-width:600px)": {
                  //   flexDirection: "column",
                  // },
                }}
                className="second row"
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>
                      Need Design?
                    </p>
                    <Box sx={{ display: "flex" }}>
                      <label
                        htmlFor="designOption1"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          id="designOption1"
                          name="need_design"
                          value="I need Custom Design"
                          //@ts-ignore
                          checked={
                            formData["need_design"] === "I need Custom Design"
                          }
                          onChange={handleChangeChecked}
                        />{" "}
                        I need Custom Design
                      </label>

                      <label
                        htmlFor="designOption2"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          id="designOption2"
                          name="need_design"
                          value="I have a Print Ready File"
                          //@ts-ignore
                          checked={
                            formData["need_design"] ===
                            "I have a Print Ready File"
                          }
                          onChange={handleChangeChecked}
                        />{" "}
                        I have a Print Ready File
                      </label>
                    </Box>
                  </Box>
                  <Box>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>Side</p>
                    <Box sx={{ display: "flex" }}>
                      <label
                        htmlFor="sideOption1"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          id="sideOption1"
                          name="side"
                          value="Single Sided"
                          //@ts-ignore
                          checked={formData["side"] === "Single Sided"}
                          onChange={handleChangeChecked}
                        />{" "}
                        Single Sided
                      </label>

                      <label
                        htmlFor="sideOption2"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          id="sideOption2"
                          name="side"
                          value="Double Sided"
                          //@ts-ignore
                          checked={formData["side"] === "Double Sided"}
                          onChange={handleChangeChecked}
                        />{" "}
                        Double Sided
                      </label>
                    </Box>
                  </Box>
                </Box>

                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>
                      Colours
                    </p>
                    <Box sx={{ display: "flex" }}>
                      <label
                        htmlFor="colourOption1"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          id="colourOption1"
                          name="colours"
                          value="Black & White"
                          //@ts-ignore
                          checked={formData["colours"] === "Black & White"}
                          onChange={handleChangeChecked}
                        />{" "}
                        Black & White
                      </label>

                      <label
                        htmlFor="colourOption2"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          id="colourOption2"
                          name="colours"
                          value="Full Colour"
                          //@ts-ignore
                          checked={formData["colours"] === "Full Colour"}
                          onChange={handleChangeChecked}
                        />{" "}
                        Full Colour
                      </label>
                    </Box>
                  </Box>
                  <Box>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>
                      Turnaround
                    </p>
                    <Box sx={{ display: "flex" }}>
                      <label
                        htmlFor="turnAroundOption1"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          id="turnAroundOption1"
                          name="turnaround"
                          value="No Rush"
                          //@ts-ignore
                          checked={formData["turnaround"] === "No Rush"}
                          onChange={handleChangeChecked}
                        />{" "}
                        No Rush
                      </label>

                      <label
                        htmlFor="turnAroundOption2"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          id="turnAroundOption2"
                          name="turnaround"
                          value="4 Days"
                          //@ts-ignore
                          checked={formData["turnaround"] === "4 Days"}
                          onChange={handleChangeChecked}
                        />{" "}
                        4 Days
                      </label>
                      <label
                        htmlFor="turnAroundOption3"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          id="turnAroundOption3"
                          name="turnaround"
                          value="2 Days"
                          //@ts-ignore
                          checked={formData["turnaround"] === "2 Days"}
                          onChange={handleChangeChecked}
                        />{" "}
                        2 Days
                      </label>

                      <label
                        htmlFor="turnAroundOption4"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          id="turnAroundOption4"
                          name="turnaround"
                          value="Same Day"
                          //@ts-ignore
                          checked={formData["turnaround"] === "Same Day"}
                          onChange={handleChangeChecked}
                        />{" "}
                        Same Day
                      </label>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* ProductDetails */}
              <Box sx={{ width: "100%", marginBottom: "20px" }}>
                <p style={{ fontSize: "18px", fontWeight: "600" }}>
                  Product Details <span style={{ color: "red" }}>*</span>
                </p>
                {/* <TextareaAutosize
                  placeholder="Product Details"
                  name = "product_details"
                  value = {formData?.product_details}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    minHeight: "80px",
                    padding: "5px 14px",
                    fontSize: "16px",
                    borderRadius: "8px",
                    resize: "vertical",
                  }}
                  
                /> */}

                <BasicInput
                  multiline={true}
                  rows={6}
                  placeholder="Product Details"
                  type="text"
                  name="product_details"
                  value={formData?.product_details}
                  onChange={handleChange}
                  size="100%"
                  isRequired
                  backgroundColor="#fff"
                />
              </Box>
              {/* bottom section  */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // marginBottom: "20px",
                  flexDirection: { xs: "column", md: "row" },
                  // //@ts-ignore
                  // "@media (max-width:600px)": {
                  //   flexDirection: "column",
                  // },
                }}
              >
                <Box
                  //   sx={{
                  //   // // width: "49%",
                  //   // display: "flex",
                  //   // flexDirection: "column",

                  //   // gap: "20px",
                  //   // //@ts-ignore
                  //   // '@media (max-width:600px)': {
                  //   //   width: "100%",
                  //  },
                  // }

                  sx={{
                    width: { xs: "100%", md: "49%" },
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: { xs: "20px", md: "" },
                    //@ts-ignore
                    gap: "20px",
                  }}
                >
                  {/* name input fields  */}
                  <Box>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>
                      Name <span style={{ color: "red" }}>*</span>
                    </p>
                    <BasicInput
                      placeholder="Name"
                      type="text"
                      name="name"
                      value={formData?.name}
                      onChange={handleChange}
                      size="100%"
                      isRequired
                    />
                  </Box>
                  <Box>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </p>
                    <BasicInput
                      placeholder="Phone Number"
                      type="phone"
                      name="phone"
                      value={formData?.phone}
                      onChange={handleChange}
                      size="100%"
                      isPhone
                      isRequired
                    />
                  </Box>
                  <Box>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>
                      How do you hear about us?
                    </p>
                    <select
                      name="hear_about_us"
                      value={formData?.hear_about_us}
                      onChange={handleChange}
                      id="hear_about_us"
                      style={{
                        width: "100%",
                        height: "43px",
                        border: "1px solid #cfc9c9",
                        borderRadius: "4px",
                        outlineColor: "#EC0A90", // Remove the default outline
                        // //@ts-ignore
                        // "&:focus": {
                        //   outline: "#EC0A90", // Set your desired background color
                        // },
                      }}
                    >
                      <option value="Google Search">Google Search</option>
                      <option value="Social Media">Social Media</option>
                      <option value="Advertisement">Advertisement</option>
                      <option value="Friend Reference">Friend Reference</option>
                      <option value="Others">Others</option>
                    </select>
                  </Box>
                </Box>
                <Box
                  //   sx={{
                  //   // // width: "49%",
                  //   // display: "flex",
                  //   // flexDirection: "column",

                  //   // gap: "20px",
                  //   // //@ts-ignore
                  //   // '@media (max-width:600px)': {
                  //   //   width: "100%",
                  //  },
                  // }

                  sx={{
                    width: { xs: "100%", md: "49%" },
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: { xs: "20px", md: "" },
                    //@ts-ignore
                    gap: "20px",
                  }}
                >
                  <Box>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>
                      Company Name
                    </p>
                    <BasicInput
                      placeholder="Company Name"
                      type="text"
                      name="company_name"
                      value={formData?.company_name}
                      onChange={handleChange}
                      size="100%"
                    />
                  </Box>
                  <Box>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>
                      Email <span style={{ color: "red" }}>*</span>
                    </p>
                    <BasicInput
                      placeholder="Email"
                      type="emailid"
                      name="email"
                      value={formData?.email}
                      onChange={handleChange}
                      size="100%"
                      isRequired
                    />
                  </Box>
                  <Box>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>
                      Promo Code
                    </p>
                    <BasicInput
                      placeholder="Promo Code"
                      type="text"
                      name="promo_code"
                      value={formData?.promo_code}
                      onChange={handleChange}
                      size="100%"
                    />
                  </Box>
                </Box>
              </Box>

              {/* file */}
              {/* <Box sx={{ marginBottom: { xs: "15px", md: "20px" } }}>
                <p style={{ fontSize: "18px", fontWeight: "600" }}>
                  Upload your file here
                </p>
                <BasicInput
                  type="file"
                  //@ts-ignore
                  ref={fileInputRef}
                  name="file_upload"
                  // value={formData?.file_upload}
                  onChange={handleFileChange}
                  size="100%"
                />
              </Box> */}
              <Box sx={{ marginBottom: { xs: "15px", md: "20px" } }}>
                <p style={{ fontSize: "18px", fontWeight: "600" }}>
                  Upload your file here
                </p>
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  ref={fileInputRef}
                  name="file_upload"
                  // value={formData?.file_upload}
                  onChange={handleFileChange}
                />
              </Box>

              <BasicButton
                placeholder="Send"
                width="100%"
                fontSize={{ xs: "20px", md: "17px" }}
                padding="10px"
                buttonType="Submit"
              />
            </Box>
          </form>
        </Box>
      </ContainerBox>
    </>
  );
};
