// import React, { useEffect, useRef } from "react";

// interface LazyImageProps {
//   skeletonSrc?: string;
//   actualSrc?: string;
//   alt?: string;
//   width?: any;
//   height?: any;
//   aspectratio?: any;
// }

// const LazyImage: React.FC<LazyImageProps> = ({
//   skeletonSrc,
//   actualSrc,
//   alt,
//   width,
//   height,
//   aspectratio,
// }) => {
//   const imageRef = useRef<HTMLImageElement>(null);

//   useEffect(() => {
//     const lazyImageObserver = new IntersectionObserver(
//       (entries, observer) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             const lazyImage = entry.target as HTMLImageElement;
//             lazyImage.src = lazyImage.dataset.src || "";
//             lazyImageObserver.unobserve(lazyImage);
//           }
//         });
//       },
//       { threshold: 0.5 }
//     );

//     if (imageRef.current) {
//       lazyImageObserver.observe(imageRef.current);
//     }

//     return () => {
//       lazyImageObserver.disconnect();
//     };
//   }, []);

//   return (
//     <img
//       ref={imageRef}
//       src={skeletonSrc}
//       data-src={actualSrc} // Make sure actualSrc is correctly set here
//       loading="lazy"
//       alt={alt}
//       width={width}
//       height={height}
//       aspect-ratio={aspectratio}
//     />
//   );
// };

// export default LazyImage;

import React, { useEffect, useRef } from "react";

interface LazyImageProps {
  skeletonSrc?: string;
  actualSrc?: string;
  alt?: string;
  width?: any;
  height?: any;
  aspectratio?: any;
}

const LazyImage: React.FC<LazyImageProps> = ({
  skeletonSrc,
  actualSrc,
  alt,
  width,
  height,
  aspectratio,
}) => {
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const lazyImageObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target as HTMLImageElement;
            lazyImage.src = lazyImage.dataset.src || "";
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (imageRef.current) {
      lazyImageObserver.observe(imageRef.current);
    }

    return () => {
      lazyImageObserver.disconnect();
    };
  }, [actualSrc]); // Add actualSrc as a dependency

  return (
    <img
      ref={imageRef}
      src={skeletonSrc}
      data-src={actualSrc}
      loading="lazy"
      alt={alt}
      width={width}
      height={height}
      aspect-ratio={aspectratio}
    />
  );
};

export default LazyImage;
