import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { BasicButton, BasicInput, BreadCrumbs } from "components";
import { ContainerBox } from "./../../components/Containers/ContainerBox";
import { Typography } from "@mui/material";
import { lostPasswordAction } from "redux/Actions/lost.password.actions";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { errorToaster } from "utils/toaster";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const LostPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();
  const lostPasswordReducer = useSelector(
    (state: any) => state?.lostPasswordReducer?.listArr
  );
  const lostPasswordReducerLoading = useSelector(
    (state: any) => state?.lostPasswordReducer?.loading
  );
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleClick = () => {
    const payload = {
      email: email,
    };

    if (email.trim() === "") {
      errorToaster("Please fill the email address");
    } else if (
      /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email.trim()) !== true
    ) {
      errorToaster("Email is not valid");
    } else {
      dispatch(lostPasswordAction(payload));
    }
  };
  useEffect(() => {
    if (lostPasswordReducer?.status === 200) {
      navigate(`/emailVerify`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lostPasswordReducer]);
  return (
    <>
      <Box sx={{ background: "#f5f5f5", padding: "15px 0", marginTop: "20px" }}>
        <ContainerBox>
          <BreadCrumbs link="Page" />
        </ContainerBox>
      </Box>

      <Box sx={{ padding: "10px" }}>
        <ContainerBox>
          <Box sx={{ marginLeft: "10px" }}>
            <Typography
              sx={{ fontSize: "17px", fontWeight: 500, marginBottom: "14px" }}
            >
              Lost your password? Please enter your username or email address.
              You will receive a link to create a new password via email.
            </Typography>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>
                Username or email
              </Typography>
              <BasicInput
                size="medium"
                type="emailid"
                name="email"
                value={email}
                onChange={handleEmailChange}
                // sx={{
                //   width: { md: "40rem", sm: "40rem", xs: "20rem" },
                //   marginTop: "10px",
                //   marginBottom: "10px",
                // }}
              />

              {lostPasswordReducerLoading ? (
                <Box sx={{ margin: "1rem 0" }}>
                  <CircularProgress size={40} />
                </Box>
              ) : (
                <BasicButton
                  margin="10px 0"
                  placeholder="Reset Password"
                  height="100%"
                  onClick={handleClick}
                />
              )}
            </Box>
          </Box>
        </ContainerBox>
      </Box>
    </>
  );
};

export default LostPassword;
