import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { goToTop } from "utils/goToTop";
import { theme } from "theme";

type Props = {
  link?: any | undefined;
  allProductListReducer?: any;
  allProductListData?: any;
};

export const BreadCrumbs = (props: Props) => {
  const navigate = useNavigate();
  function goBack() {
    goToTop();
    navigate(-1);
  }

  let updatedIndex = props?.allProductListReducer;
  const handleNext = () => {
    if (updatedIndex < props?.allProductListData.length - 2) {
      updatedIndex = updatedIndex + 1;
      navigate(
        `/products/${props?.allProductListData[updatedIndex]?.name
          .toLowerCase()
          .split(" ")
          .filter((elem: any) => elem !== "–")
          .join("-")}/`,
        {
          state: { id: `${props?.allProductListData[updatedIndex]?.id}` },
        }
      );
    }
  };
  const handlePrev = () => {
    if (updatedIndex > 0) {
      updatedIndex = updatedIndex - 1;
      navigate(
        `/products/${props?.allProductListData[updatedIndex]?.name
          .toLowerCase()
          .split(" ")
          .filter((elem: any) => elem !== "–")
          .join("-")}/`,
        {
          state: { id: `${props?.allProductListData[updatedIndex]?.id}` },
        }
      );
    }
  };

  return (
    <>
      <Box
        aria-label="breadcrumb"
        sx={{
          fontSize: "20px",
          color: "#000",
          fontWeight: "500",
          textTransform: "capitalize",
          justifyContent: "space-between",
          width: "100%",
          display: { xs: "none", lg: "flex" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box>
            <Link color="inherit" to="/" onClick={goToTop}>
              Home
            </Link>
            {" / "}
            {/* change made by suraj  */}
            {/* <Link color="inherit" to={props?.link} onClick={goToTop}> */}
            <Link
              color="inherit"
              to="#"
              onClick={goToTop}
              style={{ pointerEvents: "none", color: "#999" }}
            >
              {props?.link}
            </Link>
          </Box>
          <Box>
            {props?.allProductListReducer >= 0 ? (
              <Box sx={{ display: "flex", gap: "20px" }}>
                {updatedIndex > 0 ? (
                  <Typography
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: "1.2rem",
                      color: "grey",
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                    onClick={handlePrev}
                  >
                    <ArrowBackIcon />
                    Previous
                  </Typography>
                ) : null}

                {updatedIndex < props?.allProductListData.length - 2 ? (
                  <Typography
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: "1.2rem",
                      color: "grey",

                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                    onClick={handleNext}
                  >
                    Next
                    <ArrowForwardIcon />
                  </Typography>
                ) : null}
              </Box>
            ) : (
              <Typography
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "1.2rem",
                  color: "grey",

                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
                onClick={goBack}
              >
                <ArrowBackIcon />
                Previous
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
