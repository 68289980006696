export const ORDER_TYPE = {
  REQUEST: "ORDER_TYPE_REQUEST",
  SUCCESS: "ORDER_TYPE_SUCCESS",
  FALIURE: "ORDER_TYPE_FAILURE",
};

export const GET_ORDER_TYPE = {
  REQUEST: "GET_ORDER_TYPE_REQUEST",
  SUCCESS: "GET_ORDER_TYPE_SUCCESS",
  FALIURE: "GET_ORDER_TYPE_FAILURE",
};
export const SINGLE_ORDER_TYPE = {
  REQUEST: "GET_SINGLE_ORDER_TYPE_REQUEST",
  SUCCESS: "GET_SINGLE_ORDER_TYPE_SUCCESS",
  FALIURE: "GET_SINGLE_ORDER_TYPE_FAILURE",
};
export const PLACE_ORDER_TYPE = {
  REQUEST: "GET_PLACE_ORDER_TYPE_REQUEST",
  SUCCESS: "GET_PLACE_ORDER_TYPE_SUCCESS",
  FALIURE: "GET_PLACE_ORDER_TYPE_FAILURE",
};
export const UPDATE_BASKET_TYPE = {
  REQUEST: "GET_UPDATE_BASKET_TYPE_REQUEST",
  SUCCESS: "GET_UPDATE_BASKET_TYPE_SUCCESS",
  FALIURE: "GET_UPDATE_BASKET_TYPE_FAILURE",
};
export const UPDATE_CART_PRODUCT_QUANTITY_TYPE = {
  REQUEST: "UPDATE_CART_PRODUCT_QUANTITY_TYPE_REQUEST",
  SUCCESS: "UPDATE_CART_PRODUCT_QUANTITY_TYPE_SUCCESS",
  FALIURE: "UPDATE_CART_PRODUCT_QUANTITY_TYPE_FAILURE",
  RESET: "UPDATE_CART_PRODUCT_QUANTITY_TYPE_RESET",

};


export const GET_CART_CHECKOUT = {
  REQUEST: "GET_CART_CHECKOUT_REQUEST",
  SUCCESS: "GET_CART_CHECKOUT_SUCCESS",
  FALIURE: "GET_CART_CHECKOUT_FAILURE"
}

export const GET_COUPON_VERIFICATION = {
  REQUEST: "GET_COUPON_VERIFICATION_REQUEST",
  SUCCESS: "GET_COUPON_VERIFICATION_SUCCESS",
  FAILURE: "GET_COUPON_VERIFICATION_FAILURE"
}

export const COUPON_UPDATED = {
  TRUE: "COUPON_UPDATED_TRUE",
  REMOVE: "COUPON_UPDATED_REMOVED",
  FALSE: "COUPON_UPDATED_FALSE"
}