import { Box } from "@mui/material";
import React from "react";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  quantity?: any;
  setDecrease?: any;
  setIncrease?: any;
  setQuantity?: any;
  item?: any;
  getSingle?: boolean;
};

// const Counter: React.FC<CounterProps> = ({ initialCount = 0 }) => {
const Counter: any = ({
  setIncrease,
  setDecrease,
  quantity,
  setQuantity,
  item,
  getSingle,
}: Props) => {
  return (
    <Box
      className="counter"
      sx={{
        border: "1px solid #D0D0D0",
        width: "150px",
        height: "100%",
        padding: "10px",
        borderRadius: "5px",
      }}
    >
      <Box
        className="btn__container"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <RemoveIcon
          sx={{
            border: "none",
            backgroundColor: "#fff",
            fontSize: "16px",
            cursor: "pointer",
            opacity:
              quantity === (getSingle ? item?.product?.min_qty : item?.min_qty)
                ? 0.5
                : 1,
          }}
          onClick={setDecrease}
          // onClick={setQuantity(item?.qty - 1)}
        />

        <input
          style={{
            border: "none",
            fontWeight: "bold",
            fontSize: "16px",
            width: "70%",
            textAlign: "center",
          }}
          type="number"
          min="1"
          required
          pattern="[0-9]+"
          value={quantity}
          // onKeyDown={(event) => preventBackspace(event)}
          onChange={(e) => setQuantity(parseInt(e.currentTarget.value))}
          readOnly
        />
        <AddIcon
          sx={{
            border: "none",
            backgroundColor: "#fff",
            fontSize: "16px",
            cursor: "pointer",
            opacity:
              quantity === (getSingle ? item?.product?.max_qty : item?.max_qty)
                ? 0.5
                : 1,
          }}
          onClick={setIncrease}
          // onClick={() => setQuantity(quantity + 1)}
        />
      </Box>
    </Box>
  );
};

export default Counter;
