import { GUEST_USER_TYPE } from "redux/Actions/action.types/guest.types";

const initialState = {
  listArr: [],
  loading: true,
};

export const guestReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GUEST_USER_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GUEST_USER_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case GUEST_USER_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
