import React from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./SwiperSlider.css";
import ClientLogoCard from "../Cards/ClientLogoCard";
import { Cards, ContainerBox } from "components";

type Props = {
  data?: any | undefined;
  productCard?: boolean;
};

const SwiperSlider = (props: Props) => {
  const getSlidesPerView = () => {
    switch (true) {
      case window.innerWidth < 768:
        return 1;
      case window.innerWidth >= 768 && window.innerWidth <= 1024:
        return 2;
      case window.innerWidth >= 1024:
        return 4;
      default:
        break;
    }
  };

  return (
    <ContainerBox maxWidth="1600px" overflow="hidden">
      <Swiper
        className="swiperSliderWrapper"
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={50}
        loop={false}
        slidesPerView={getSlidesPerView()}
        speed={1500}
        // autoplay={{
        //   delay: 1000,
        //   disableOnInteraction: false,
        // }}
        navigation={props?.productCard ? true : false}
        // pagination={{ clickable: props?.productCard ? true : false }}
        // scrollbar={{ draggable: true }}
        // onSlideChange={() =>}
      >
        {props?.data?.map((val: any, idx: any) => {
          return (
            <SwiperSlide className="customSwiper" key={idx}>
              {props?.productCard ? (
                <Cards
                  height="200px"
                  key={idx}
                  Products={val}
                  maxWidth="200px"
                />
              ) : (
                <ClientLogoCard Images={val?.img} />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </ContainerBox>
  );
};

export default SwiperSlider;
