import { combineReducers } from "redux";
import {
  allProductListReducer,
  singleProductReducer,
  getCartReducer,
  addToCartReducer,
  deleteCartReducer,
  getProductBasketReducer,
  updateProductBasketReducer,
  getCartCheckoutReducer,
  getCouponUpdated,
} from "./productListReducer";
import { allBlogListReducer, singleBlogReducer ,blogCommentReducer} from "./blogListReducer";
import { authReducer } from "./loginReducer";
import { guestReducer } from "./guestReducer";
import { registerReducer } from "./registerReducer";
import { promotionReducer } from "./promotionReducer";
import { designGalleryReducer } from "./designGalleryReducer";
import { faqReducer } from "./faqReducer";
import { changePasswordReducer } from "./changePasswordReducer";
import { getWishlistReducer, patchWishlistReducer } from "./wishlistReducer";
import { templateReducer } from "./templateReducer";
import {
  orderReducer,
  getorderReducer,
  getSingleOrderReducer,
  placeOrderReducer,
  updateCartProductQuantityReducer,
} from "./orderReducer";
import { addReviewReducer } from "./addReviewReducer";
import { requestAQuoteReducer } from "./requestAQuoteReducer";
import {
  getUserAddressReducer,
  patchUserAddressReducer,
  getUserProfileReducer,
  patchUserProfileReducer,
} from "./userAddressListReducer";
import { lostPasswordReducer } from "./lostpassword";
const appReducer = combineReducers({
  allProductListReducer,
  allBlogListReducer,
  singleBlogReducer,
  authReducer,
  guestReducer,
  registerReducer,
  singleProductReducer,
  promotionReducer,
  designGalleryReducer,
  faqReducer,
  getCartReducer,
  addToCartReducer,
  deleteCartReducer,
  blogCommentReducer,
  getUserAddressReducer,
  patchUserAddressReducer,
  getWishlistReducer,
  getUserProfileReducer,
  patchUserProfileReducer,
  templateReducer,
  patchWishlistReducer,
  orderReducer,
  getorderReducer,
  getSingleOrderReducer,
  placeOrderReducer,
  updateCartProductQuantityReducer,
  getProductBasketReducer,
  updateProductBasketReducer,
  lostPasswordReducer,
  getCartCheckoutReducer,
  changePasswordReducer,
  getCouponUpdated,
  addReviewReducer,
  requestAQuoteReducer,
});
const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;
