import React, { useState, useEffect } from "react";
import ReactImageViewer from "./ReactImageViewer";
// import StackGrid from "react-stack-grid";
import { ImageList, ImageListItem } from "@mui/material";
import "./gallery.css";
import { useDispatch, useSelector } from "react-redux";
import { designGalleryAction } from "redux/Actions/designGallery.actions";
import { LoadingComponent } from "components/Loading";

const ImageViewer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currImg, setCurrImg] = useState(Number);
  const [list, setList] = useState([]);

  const handleImageClick = (index: any) => {
    setIsOpen(true);
    setCurrImg(index);
  };

  const handleImageClose = () => {
    setIsOpen(false);
  };

  const gotoNextImg = () => {
    setCurrImg(currImg + 1);
  };

  const gotoPrevImg = () => {
    setCurrImg(currImg - 1);
  };

  const dispatch = useDispatch();
  const data = useSelector(
    (state: any) => state?.designGalleryReducer?.listArr?.results
  );

  useEffect(() => {
    //@ts-ignore
    dispatch(designGalleryAction());
  }, [dispatch]);

  useEffect(() => {
    const newData = data?.map((item: any) => {
      return {
        ...item,
        src: item.base_image_url,
        base_image_url: undefined,
      };
    });
    if (newData?.length > 0) {
      setList(newData);
    }
  }, [data]);

  return (
    <>
      {data?.length > 0 ? (
        <>
          <ImageList sx={{ width: "100%" }} variant="masonry" cols={4} gap={8}>
            {list?.map((item: any, index: any) => (
              <ImageListItem
                key={index}
                onClick={() => handleImageClick(index)}
              >
                <img
                  src={item?.src}
                  srcSet={item?.src}
                  alt={item?.name}
                  loading="lazy"
                  style={{ cursor: "pointer" }}
                />
              </ImageListItem>
            ))}
          </ImageList>

          <ReactImageViewer
            imgs={list}
            isOpen={isOpen}
            onClose={handleImageClose}
            currImg={currImg}
            onClickPrev={gotoPrevImg}
            onClickNext={gotoNextImg}
          />
        </>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
};

export default ImageViewer;
