import React from "react";
import SelectedType from "./SelectedType";

import "./product.css";
type Props = {
  data: any;
  payloadObject: any;
  setPayloadObject: any;
  totalPrice?: any;
  setTotalPrice?: any;
  isPage?: string;
  setOrderQuantiy?: any;
  orderQuantiy?: any;
};

const ParentComponent = ({
  data,
  payloadObject,
  setPayloadObject,
  totalPrice,
  setTotalPrice,
  isPage,
  setOrderQuantiy,
  orderQuantiy,
}: Props) => {
  return (
    <>
      {data?.map((item: any, idx: number) => {
        return (
          <React.Fragment key={idx}>
            <SelectedType
              data={item}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              payloadObject={payloadObject}
              setPayloadObject={setPayloadObject}
              isPage={isPage}
              orderQuantiy={orderQuantiy}
              setOrderQuantiy={setOrderQuantiy}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ParentComponent;
