import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  overflowY: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: { xs: "80vh", md: "90vh" },
  width: "70vw",
  bgcolor: "background.paper",
  // border: "1px solid #000",
  boxShadow: 24,
  // p: 4,
};

type Props = {
  open: boolean;
  handleModalClose: any;
  handleModalOpen: any;
  children: any;
};

export function BasicModal({
  open,
  handleModalClose,
  handleModalOpen,
  children,
}: Props) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
    </div>
  );
}
