/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { RadioSelector, Required } from "components";
import "pages/Products/product.css";
import { useLocation } from "react-router-dom";
import Slider from "@mui/material/Slider";

const SelectedType = ({
  data,
  totalPrice,
  setTotalPrice,
  payloadObject,
  setPayloadObject,
  isPage,
  setOrderQuantiy,
  orderQuantiy,
}: any) => {
  const Location = useLocation();
  const [selectDataLevel, setSelectedDataLevel] = useState<any>({
    level1: [],
    level2: [],
    level3: [],
    level4: [],
  });
  const [selectSliderLevel, setSelectedSliderLevel] = useState<any>({
    level1: "",
    level2: "",
    level3: "",
    level4: "",
  });
  const [isExist, setExist] = useState<boolean>(false);
  const [imageId, setImageId] = useState();
  const [radioSelected, setRadioSelected] = useState(false);

  useEffect(() => {
    setSelectedDataLevel({
      level1: [],
      level2: [],
      level3: [],
      level4: [],
    });
  }, [Location?.pathname]);

  const handleChange = (e: any, cbName?: any) => {
    const { name, value } = e.target;
    try {
      const subChild = JSON.parse(value);
      if (cbName) {
        setSelectedDataLevel((prev: any) => ({
          ...prev,
          [cbName]: subChild,
        }));

        const updatedData = totalPrice?.map((x: any) =>
          x.pID === data.id ? { ...x, price: subChild?.price } : x
        );
        const updatedPyl = payloadObject?.map((x: any) =>
          x.pID === data.id
            ? {
                ...x,
                //@ts-ignore
                id: subChild?.id,
              }
            : x
        );

        if (isExist) {
          setTotalPrice(updatedData);
          setPayloadObject(updatedPyl);
        } else {
          setTotalPrice((prev: any) => [
            ...prev,
            { pID: data?.id, price: subChild?.price },
          ]);
          setPayloadObject((prev: any) => [
            ...prev,
            {
              pID: data?.id,
              id: subChild?.id,
            },
          ]);
        }
      } else {
        setSelectedDataLevel((prev: any) => ({
          ...prev,
          [name]: subChild,
        }));

        const updatedData = totalPrice?.map((x: any) =>
          x.pID === data.id ? { ...x, price: subChild?.price } : x
        );
        const updatedPyl = payloadObject?.map((x: any) => {
          if (x.pID === data.id) {
            return {
              ...x,
              //@ts-ignore
              id: subChild?.id,
            };
          } else {
            return x;
          }
        });

        if (isExist) {
          setTotalPrice(updatedData);
          setPayloadObject(updatedPyl);
        } else {
          setTotalPrice((prev: any) => [
            ...prev,
            { pID: data?.id, price: subChild?.price },
          ]);
          setPayloadObject((prev: any) => [
            ...prev,
            {
              pID: data?.id,
              id: subChild?.id,
            },
          ]);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleQtyChange = (e: any) => {
    const { name, value: quantity } = e?.target;
    setSelectedSliderLevel((prev: any) => ({
      ...prev,
      [name]: quantity,
    }));
    // setOrderQuantiy((prev:any)=>[...prev, quantity]);
    const updatedData = totalPrice?.map((x: any) =>
      x.pID === data.id
        ? { ...x, price: selectDataLevel[name]?.price * quantity }
        : x
    );
    const updatedPyl = payloadObject?.map((x: any) => {
      if (x.pID === data.id) {
        return {
          ...x,
          //@ts-ignore
          id: selectDataLevel[name]?.id,
        };
      } else {
        return x;
      }
    });

    if (isExist) {
      setTotalPrice(updatedData);
      setPayloadObject(updatedPyl);
    } else {
      setTotalPrice((prev: any) => [
        ...prev,
        { pID: data?.id, price: selectDataLevel[name]?.price * quantity },
      ]);
      setPayloadObject((prev: any) => [
        ...prev,
        {
          pID: data?.id,
          id: selectDataLevel[name]?.id,
        },
      ]);
    }
  };

  useEffect(() => {
    let localArr: number[] = [];
    if (selectSliderLevel?.level1) {
      localArr?.push(selectSliderLevel?.level1);
    }
    if (selectSliderLevel?.level2) {
      localArr?.push(selectSliderLevel?.level2);
    }
    if (selectSliderLevel?.level3) {
      localArr?.push(selectSliderLevel?.level3);
    }
    if (selectSliderLevel?.level4) {
      localArr?.push(selectSliderLevel?.level4);
    }
    if (localArr?.length) setOrderQuantiy(localArr);
  }, [selectSliderLevel, setOrderQuantiy]);

  useEffect(() => {
    for (let { pID } of totalPrice) {
      if (pID === data.id) setExist(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice]);

  useEffect(() => {
    for (let { pID } of payloadObject) {
      if (pID === data.id) setExist(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadObject]);

  //! level 1
  useEffect(() => {
    // debugger;
    const findIndex = data?.values?.findIndex(({ selected }: any) => selected);
    setSelectedDataLevel((prev: any) => ({
      ...prev,
      level1: findIndex > -1 ? data?.values?.[findIndex] : data?.values?.[0],
    }));
    if (data?.type?.toLowerCase() === "radio") {
      if (findIndex > -1) {
        if (data?.values?.[findIndex]?.sub_com?.length === 0) {
          setTotalPrice((prev: any) => [
            ...prev,
            {
              pID: data?.id,
              //@ts-ignore
              price:
                findIndex > -1
                  ? data?.values?.[findIndex]?.price
                  : data?.values?.[0]?.price,
            },
          ]);
          setPayloadObject((prev: any) => [
            ...prev,
            {
              pID: data?.id,
              id:
                findIndex > -1
                  ? data?.values?.[findIndex]?.id
                  : data?.values?.[0]?.id,
            },
          ]);
        }
      } else {
        if (data?.values?.[0]?.sub_com?.length === 0) {
          setTotalPrice((prev: any) => [
            ...prev,
            {
              pID: data?.id,
              //@ts-ignore
              price: data?.values?.[0]?.price,
            },
          ]);
          setPayloadObject((prev: any) => [
            ...prev,
            {
              pID: data?.id,
              id: data?.values?.[0]?.id,
            },
          ]);
        }
      }
    } else {
      if (findIndex > -1) {
        if (data?.values?.[findIndex]?.sub_com?.length === 0) {
          setPayloadObject((prev: any) => [
            ...prev,
            {
              pID: data?.id,
              id:
                findIndex > -1
                  ? data?.values?.[findIndex]?.id
                  : data?.values?.[0]?.id,
            },
          ]);
          setTotalPrice((prev: any) => [
            ...prev,
            {
              pID: data?.id,
              //@ts-ignore
              price:
                findIndex > -1
                  ? data?.values?.[findIndex]?.price
                  : data?.values?.[0]?.price,
            },
          ]);
        }
      } else {
        if (data?.values?.[0]?.sub_com?.length === 0) {
          setPayloadObject((prev: any) => [
            ...prev,
            {
              pID: data?.id,
              id: data?.values?.[0]?.id,
            },
          ]);
          setTotalPrice((prev: any) => [
            ...prev,
            {
              pID: data?.id,
              //@ts-ignore
              price: data?.values?.[0]?.price,
            },
          ]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //! level 2
  useEffect(() => {
    // debugger;
    if (selectDataLevel?.level1?.sub_com?.length === 0) {
      setSelectedDataLevel((prev: any) => ({
        ...prev,
        //@ts-ignore
        level2: [],
      }));
    } else {
      //@ts-ignore
      if (selectDataLevel?.level1?.sub_com?.[0]?.values?.length) {
        const findIndex =
          selectDataLevel?.level1?.sub_com?.[0]?.values?.findIndex(
            ({ selected }: any) => selected
          );
        setSelectedDataLevel((prev: any) => ({
          ...prev,
          //@ts-ignore
          level2:
            findIndex > -1
              ? prev?.level1?.sub_com?.[0]?.values[findIndex]
              : prev?.level1?.sub_com?.[0]?.values[0],
        }));

        const isSlider =
          selectDataLevel?.level1?.sub_com?.[0]?.type?.toLowerCase() ===
          "slider";
        const orderQty =
          selectDataLevel?.level1?.sub_com?.[0]?.values?.[0]?.orderQuantity;
        const updatedData = totalPrice?.map((x: any) =>
          x.pID === data.id
            ? {
                ...x,
                //@ts-ignore
                price: isSlider
                  ? findIndex > -1
                    ? selectDataLevel?.level1?.sub_com?.[0]?.values[findIndex]
                        ?.price * orderQty
                    : selectDataLevel?.level1?.sub_com?.[0]?.values[0]?.price *
                      orderQty
                  : findIndex > -1
                  ? selectDataLevel?.level1?.sub_com?.[0]?.values[findIndex]
                      ?.price
                  : selectDataLevel?.level1?.sub_com?.[0]?.values[0]?.price,
              }
            : x
        );

        const updatedPyl = payloadObject?.map((x: any) => {
          return x.pID === data.id
            ? {
                ...x,
                //@ts-ignore
                id:
                  findIndex > -1
                    ? selectDataLevel?.level1?.sub_com?.[0]?.values[findIndex]
                        ?.id
                    : selectDataLevel?.level1?.sub_com?.[0]?.values[0]?.id,
              }
            : x;
        });
        let IsExist = false;
        for (let { pID } of payloadObject) {
          if (data?.id === pID) {
            IsExist = true;
          }
        }
        if (isExist) {
          setTotalPrice(updatedData);
          setPayloadObject(updatedPyl);
        } else {
          if (IsExist) {
            setTotalPrice(updatedData);
            setPayloadObject(updatedPyl);
          } else {
            setTotalPrice((prev: any) => [
              ...prev,
              {
                pID: data?.id,
                //@ts-ignore
                price: isSlider
                  ? findIndex > -1
                    ? selectDataLevel?.level1?.sub_com?.[0]?.values[findIndex]
                        ?.price * orderQty
                    : selectDataLevel?.level1?.sub_com?.[0]?.values[0]?.price *
                      orderQty
                  : findIndex > -1
                  ? selectDataLevel?.level1?.sub_com?.[0]?.values[findIndex]
                      ?.price
                  : selectDataLevel?.level1?.sub_com?.[0]?.values[0]?.price,
              },
            ]);
            setPayloadObject((prev: any) => [
              ...prev,
              {
                pID: data?.id,
                id:
                  findIndex > -1
                    ? selectDataLevel?.level1?.sub_com?.[0]?.values?.[findIndex]
                        ?.id
                    : selectDataLevel?.level1?.sub_com?.[0]?.values?.[0]?.id,
              },
            ]);
          }
        }
      }
    }
  }, [selectDataLevel?.level1]);

  //! level 3
  useEffect(() => {
    // debugger;
    if (selectDataLevel?.level2?.sub_com?.length === 0) {
      setSelectedDataLevel((prev: any) => ({
        ...prev,
        //@ts-ignore
        level3: [],
      }));
    } else {
      //@ts-ignore
      if (selectDataLevel?.level2?.sub_com?.[0]?.values?.length) {
        const findIndex =
          selectDataLevel?.level2?.sub_com?.[0]?.values?.findIndex(
            ({ selected }: any) => selected
          );

        setSelectedDataLevel((prev: any) => ({
          ...prev,
          //@ts-ignore
          level3:
            findIndex > -1
              ? prev?.level2?.sub_com?.[0]?.values[findIndex]
              : prev?.level2?.sub_com?.[0]?.values[0],
        }));

        const isSlider =
          selectDataLevel?.level2?.sub_com?.[0]?.type?.toLowerCase() ===
          "slider";
        const orderQty =
          selectDataLevel?.level2?.sub_com?.[0]?.values?.[0]?.orderQuantity;

        const updatedData = totalPrice?.map((x: any) =>
          x.pID === data.id
            ? {
                ...x,
                //@ts-ignore
                price: isSlider
                  ? findIndex > -1
                    ? selectDataLevel?.level2?.sub_com?.[0]?.values[findIndex]
                        ?.price * orderQty
                    : selectDataLevel?.level2?.sub_com?.[0]?.values[0]?.price *
                      orderQty
                  : findIndex > -1
                  ? selectDataLevel?.level2?.sub_com?.[0]?.values[findIndex]
                      ?.price
                  : selectDataLevel?.level2?.sub_com?.[0]?.values[0]?.price,
              }
            : x
        );
        const updatedPyl = payloadObject?.map((x: any) =>
          x.pID === data.id
            ? {
                ...x,
                //@ts-ignore
                id:
                  findIndex > -1
                    ? selectDataLevel?.level2?.sub_com?.[0]?.values[findIndex]
                        ?.id
                    : selectDataLevel?.level2?.sub_com?.[0]?.values[0]?.id,
              }
            : x
        );
        //   "383 isExist",
        //   isExist,
        //   data?.id,
        //   payloadObject,
        //   updatedData,
        //   updatedPyl
        // );
        let IsExist = false;
        for (let { pID } of payloadObject) {
          if (data?.id === pID) {
            IsExist = true;
          }
        }

        if (isExist) {
          setTotalPrice(updatedData);
          setPayloadObject(updatedPyl);
        } else {
          if (IsExist) {
            setTotalPrice(updatedData);
            setPayloadObject(updatedPyl);
          } else {
            setTotalPrice((prev: any) => [
              ...prev,
              {
                pID: data?.id,
                //@ts-ignore
                price: isSlider
                  ? findIndex > -1
                    ? selectDataLevel?.level2?.sub_com?.[0]?.values[findIndex]
                        ?.price * orderQty
                    : selectDataLevel?.level2?.sub_com?.[0]?.values[0]?.price *
                      orderQty
                  : findIndex > -1
                  ? selectDataLevel?.level2?.sub_com?.[0]?.values[findIndex]
                      ?.price
                  : selectDataLevel?.level2?.sub_com?.[0]?.values[0]?.price,
              },
            ]);
            setPayloadObject((prev: any) => [
              ...prev,
              {
                pID: data?.id,
                id:
                  findIndex > -1
                    ? selectDataLevel?.level2?.sub_com?.[0]?.values?.[findIndex]
                        ?.id
                    : selectDataLevel?.level2?.sub_com?.[0]?.values?.[0]?.id,
              },
            ]);
          }
        }
      }
    }
  }, [selectDataLevel?.level2]);

  //! level 4
  useEffect(() => {
    if (selectDataLevel?.level3?.sub_com?.length === 0) {
      setSelectedDataLevel((prev: any) => ({
        ...prev,
        //@ts-ignore
        level4: [],
      }));
    } else {
      //@ts-ignore
      if (selectDataLevel?.level3?.sub_com?.[0]?.values?.length) {
        const findIndex =
          selectDataLevel?.level3?.sub_com?.[0]?.values?.findIndex(
            ({ selected }: any) => selected
          );

        setSelectedDataLevel((prev: any) => ({
          ...prev,
          //@ts-ignore
          level4:
            findIndex > -1
              ? prev?.level3?.sub_com?.[0]?.values[findIndex]
              : prev?.level3?.sub_com?.[0]?.values[0],
        }));
        //@ts-ignore
        const isSlider =
          selectDataLevel?.level1?.sub_com?.[0]?.type?.toLowerCase() ===
          "slider";
        const orderQty =
          selectDataLevel?.level1?.sub_com?.[0]?.values?.[0]?.orderQuantity;

        const updatedData = totalPrice?.map((x: any) =>
          x.pID === data.id
            ? {
                ...x,
                //@ts-ignore
                price: isSlider
                  ? findIndex > -1
                    ? selectDataLevel?.level3?.sub_com?.[0]?.values[findIndex]
                        ?.price * orderQty
                    : selectDataLevel?.level3?.sub_com?.[0]?.values[0]?.price *
                      orderQty
                  : findIndex > -1
                  ? selectDataLevel?.level3?.sub_com?.[0]?.values[findIndex]
                      ?.price
                  : selectDataLevel?.level3?.sub_com?.[0]?.values[0]?.price,
              }
            : x
        );
        const updatedPyl = payloadObject?.map((x: any) =>
          x.pID === data.id
            ? {
                ...x,
                //@ts-ignore
                id:
                  findIndex > -1
                    ? selectDataLevel?.level3?.sub_com?.[0]?.values[findIndex]
                        ?.id
                    : selectDataLevel?.level3?.sub_com?.[0]?.values[0]?.id,
              }
            : x
        );
        let IsExist = false;
        for (let { pID } of payloadObject) {
          if (data?.id === pID) {
            IsExist = true;
          }
        }

        if (isExist) {
          setTotalPrice(updatedData);
          setPayloadObject(updatedPyl);
        } else {
          if (IsExist) {
            setTotalPrice(updatedData);
            setPayloadObject(updatedPyl);
          } else {
            setTotalPrice((prev: any) => [
              ...prev,
              {
                pID: data?.id,
                //@ts-ignore
                price: isSlider
                  ? selectDataLevel?.level3?.sub_com?.[0]?.values[0]?.price *
                    orderQty
                  : selectDataLevel?.level3?.sub_com?.[0]?.values[0]?.price,
              },
            ]);
            setPayloadObject((prev: any) => [
              ...prev,
              {
                pID: data?.id,
                id: selectDataLevel?.level3?.sub_com?.[0]?.values?.[0]?.id,
              },
            ]);
          }
        }
      }
    }
  }, [selectDataLevel?.level3]);

  return (
    <>
      {
        //! level 1
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            // border: "1px solid #f8f8f8",
            padding: "10px",
          }}
        >
          {data?.title && (
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "700",
                fontFamily: "Rajdhani",
                color: "#000",
                backgroundColor: "#f8f8f8",
                padding: "10px",
                display: "flex",
                alignItems: "start",
                gap: "2px",
              }}
            >
              {data?.title} <Required />
            </Typography>
          )}
          {data?.type?.toLowerCase() === "radio" ? (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                border: "1px solid #f8f8f8",
                padding: "10px",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {data?.values?.map((item: any, idx: number) => {
                  return (
                    <React.Fragment key={idx}>
                      <RadioSelector
                        data={item}
                        onChange={handleChange}
                        level={"level1"}
                        title={data?.title}
                        isChecked={
                          isPage === "edit"
                            ? item?.selected === true
                            : idx === 0
                        }
                        imageId={imageId}
                        setImageId={setImageId}
                        radioSelected={radioSelected}
                        setRadioSelected={setRadioSelected}
                        selectValue={JSON.stringify(selectDataLevel?.level1)}
                      />
                    </React.Fragment>
                  );
                })}
              </Box>
            </Box>
          ) : (
            <>
              {data?.type?.toLowerCase() === "slider" ? (
                <Slider
                  name="level1"
                  //@ts-ignore
                  size="small"
                  defaultValue={
                    isPage === "edit" ? data?.values?.[0]?.orderQuantity : 1
                  }
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  onChange={handleQtyChange}
                  min={data?.values?.[0]?.min_value}
                  max={data?.values?.[0]?.max_value}
                />
              ) : (
                <Box>
                  <select
                    name="level1"
                    // value={JSON.stringify()}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      padding: "5px",
                      outline: "none",
                      fontSize: "18px",
                    }}
                  >
                    {data?.values?.map((child: any, idx: number) => {
                      return (
                        <option
                          key={idx}
                          value={JSON.stringify(child)}
                          selected={
                            isPage === "edit" && child?.selected === true
                          }
                        >
                          {child?.title}
                        </option>
                      );
                    })}
                  </select>
                </Box>
              )}
            </>
          )}
        </Box>
      }
      {
        //! level 2
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            // border: "1px solid #f8f8f8",
            padding: "10px",
          }}
        >
          {selectDataLevel?.level1?.sub_com?.[0]?.title && (
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "700",
                fontFamily: "Rajdhani",
                color: "#000",
                backgroundColor: "#f8f8f8",
                padding: "10px",
                display: "flex",
                alignItems: "start",
                gap: "2px",
              }}
            >
              {selectDataLevel?.level1?.sub_com?.[0]?.title} <Required />
            </Typography>
          )}
          {selectDataLevel?.level1?.sub_com?.[0]?.values?.length &&
            (selectDataLevel?.level1?.sub_com?.[0]?.type?.toLowerCase() ===
            "radio" ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {selectDataLevel?.level1?.sub_com?.[0]?.values?.map(
                  (item: any, idx: number) => {
                    return (
                      <React.Fragment key={idx}>
                        <RadioSelector
                          data={item}
                          onChange={handleChange}
                          level={"level2"}
                          title={selectDataLevel?.level1?.sub_com?.[0]?.title}
                          isChecked={
                            isPage === "edit"
                              ? item?.selected === true
                              : idx === 0
                          }
                          imageId={imageId}
                          setImageId={setImageId}
                          radioSelected={radioSelected}
                          setRadioSelected={setRadioSelected}
                          selectValue={JSON.stringify(selectDataLevel?.level2)}
                        />
                      </React.Fragment>
                    );
                  }
                )}
              </Box>
            ) : (
              <>
                {selectDataLevel?.level1?.sub_com?.[0]?.type?.toLowerCase() ===
                "slider" ? (
                  <Slider
                    name="level2"
                    //@ts-ignore
                    size="large"
                    defaultValue={
                      isPage === "edit"
                        ? selectDataLevel?.level1?.sub_com?.[0]?.values?.[0]
                            ?.orderQuantity
                        : 1
                    }
                    aria-label="Always visible"
                    valueLabelDisplay="on"
                    onChange={handleQtyChange}
                    min={
                      selectDataLevel?.level1?.sub_com?.[0]?.values?.[0]
                        ?.min_value
                    }
                    max={
                      selectDataLevel?.level1?.sub_com?.[0]?.values?.[0]
                        ?.max_value
                    }
                  />
                ) : (
                  <select
                    name="level2"
                    value={JSON.stringify(selectDataLevel?.level2)}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      padding: "5px",
                      outline: "none",
                      fontSize: "18px",
                    }}
                  >
                    {selectDataLevel?.level1?.sub_com?.[0]?.values?.map(
                      (subChild: any, idx: number) => {
                        return (
                          <option key={idx} value={JSON.stringify(subChild)}>
                            {subChild?.title}
                          </option>
                        );
                      }
                    )}
                  </select>
                )}
              </>
            ))}
        </Box>
      }
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          // border: "1px solid #f8f8f8",
          padding: "10px",
        }}
      >
        {
          //! level 3
          selectDataLevel?.level2?.sub_com?.[0]?.title && (
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "700",
                fontFamily: "Rajdhani",
                color: "#000",
                backgroundColor: "#f8f8f8",
                padding: "10px",
                display: "flex",
                alignItems: "start",
                gap: "2px",
              }}
            >
              {selectDataLevel?.level2?.sub_com?.[0]?.title} <Required />
            </Typography>
          )
        }
        {selectDataLevel?.level2?.sub_com?.[0]?.values?.length ? (
          selectDataLevel?.level2?.sub_com?.[0]?.type?.toLowerCase() ===
          "radio" ? (
            <Box>
              {selectDataLevel?.level2?.sub_com?.[0]?.values?.map(
                (subChild: any, idx: number) => {
                  return (
                    <React.Fragment key={idx}>
                      <RadioSelector
                        data={subChild}
                        onChange={handleChange}
                        level={"level3"}
                        title={selectDataLevel?.level2?.sub_com?.[0]?.title}
                        isChecked={
                          isPage === "edit"
                            ? subChild?.selected === true
                            : idx === 0
                        }
                        imageId={imageId}
                        setImageId={setImageId}
                        radioSelected={radioSelected}
                        setRadioSelected={setRadioSelected}
                        selectValue={JSON.stringify(selectDataLevel?.level3)}
                      />
                    </React.Fragment>
                  );
                }
              )}
            </Box>
          ) : (
            <Box>
              {selectDataLevel?.level2?.sub_com?.[0]?.type?.toLowerCase() ===
              "slider" ? (
                <Slider
                  name="level3"
                  //@ts-ignore
                  size="large"
                  defaultValue={
                    isPage === "edit"
                      ? selectDataLevel?.level2?.sub_com?.[0]?.values?.[0]
                          ?.orderQuantity
                      : 1
                  }
                  aria-label="Always visible"
                  valueLabelDisplay="on"
                  onChange={handleQtyChange}
                  min={
                    selectDataLevel?.level2?.sub_com?.[0]?.values?.[0]
                      ?.min_value
                  }
                  max={
                    selectDataLevel?.level2?.sub_com?.[0]?.values?.[0]
                      ?.max_value
                  }
                />
              ) : (
                <select
                  name="level3"
                  onChange={handleChange}
                  value={JSON.stringify(selectDataLevel?.level3)}
                  style={{
                    width: "100%",
                    padding: "5px",
                    outline: "none",
                    fontSize: "18px",
                  }}
                >
                  {selectDataLevel?.level2?.sub_com?.[0]?.values?.map(
                    (subChild: any, idx: number) => {
                      return (
                        <option key={idx} value={JSON.stringify(subChild)}>
                          {subChild?.title}
                        </option>
                      );
                    }
                  )}
                </select>
              )}
            </Box>
          )
        ) : null}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          // border: "1px solid #f8f8f8",
          padding: "10px",
        }}
      >
        {
          //! level 4
          selectDataLevel?.level3?.sub_com?.[0]?.title && (
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "700",
                fontFamily: "Rajdhani",
                color: "#000",
                backgroundColor: "#f8f8f8",
                padding: "10px",
                display: "flex",
                alignItems: "start",
                gap: "2px",
              }}
            >
              {selectDataLevel?.level3?.sub_com?.[0]?.title} <Required />
            </Typography>
          )
        }
        {selectDataLevel?.level3?.sub_com?.[0]?.values?.length &&
          (selectDataLevel?.level3?.sub_com?.[0]?.type?.toLowerCase() ===
          "radio" ? (
            <Box>
              {selectDataLevel?.level3?.sub_com?.[0]?.values?.map(
                (subChild: any, idx: number) => {
                  return (
                    <React.Fragment key={idx}>
                      <RadioSelector
                        data={subChild}
                        onChange={handleChange}
                        level={"level4"}
                        title={selectDataLevel?.level3?.sub_com?.[0]?.title}
                        isChecked={
                          isPage === "edit"
                            ? subChild?.selected === true
                            : idx === 0
                        }
                        imageId={imageId}
                        setImageId={setImageId}
                        radioSelected={radioSelected}
                        setRadioSelected={setRadioSelected}
                        selectValue={JSON.stringify(selectDataLevel?.level4)}
                      />
                    </React.Fragment>
                  );
                }
              )}
            </Box>
          ) : selectDataLevel?.level3?.sub_com?.[0]?.type?.toLowerCase() ===
            "slider" ? (
            <Slider
              name="level4"
              //@ts-ignore
              size="large"
              defaultValue={
                isPage === "edit"
                  ? selectDataLevel?.level3?.sub_com?.[0]?.values?.[0]
                      ?.orderQuantity
                  : 1
              }
              aria-label="Always visible"
              valueLabelDisplay="on"
              onChange={handleQtyChange}
              min={
                selectDataLevel?.level3?.sub_com?.[0]?.values?.[0]?.min_value
              }
              max={
                selectDataLevel?.level3?.sub_com?.[0]?.values?.[0]?.max_value
              }
            />
          ) : (
            <select
              name="level4"
              value={JSON.stringify(selectDataLevel?.level4)}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "5px",
                outline: "none",
                fontSize: "18px",
              }}
            >
              {selectDataLevel?.level3?.sub_com?.[0]?.values?.map(
                (subChild: any, idx: number) => {
                  return (
                    <option key={idx} value={JSON.stringify(subChild)}>
                      {subChild?.title}
                    </option>
                  );
                }
              )}
            </select>
          ))}
      </Box>
    </>
  );
};

export default SelectedType;
