import { useState } from "react";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Required } from "components/Notification/Required";

type Props = {
  label?: string | undefined;
  type?: string | undefined;
  placeholder?: string | undefined;
  size?: string | undefined;
  backgroundColor?: any | undefined;
  color?: any | undefined;
  phonetype?: boolean | undefined;
  value?: any | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  name?: any;
  isPhone?: boolean;
  InputProps?: any;
  multiline?: any;
  rows?: number;
};

export const BasicInput = ({
  label,
  type,
  value,
  onChange,
  isRequired,
  placeholder,
  size,
  backgroundColor,
  phonetype,
  color,
  name,
  isPhone,
  InputProps,
  multiline,
  rows,
}: Props) => {
  // const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   event.preventDefault();
  // };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Perform required validation
    if (isRequired && inputValue.trim() === "") {
      setError(true);
      setErrorMsg(`${name} is required.`);
    } else {
      setError(false);
      setErrorMsg("");
    }

    // Perform specific input validations based on the type
    if (type === "phone" && isPhone && inputValue.trim() !== "") {
      // Perform phone number validation
      // Example: Check if the phone number is valid
      const phoneRegex =
        /^(?:\+1[-.\s]?)?(?:\([2-9][0-9]{2}\)|[2-9][0-9]{2})[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/;
      const isValidPhone = phoneRegex.test(inputValue);
      setError(!isValidPhone);
      setErrorMsg(`${name} is not Valid.`);
    } else if (type === "postcode" && inputValue.trim() !== "") {
      // Perform postcode validation
      // Example: Check if the postcode is valid
      // change made by suraj
      const postcodeRegex =
        /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/;
      const isValidPostcode = postcodeRegex.test(inputValue.toUpperCase());
      setError(!isValidPostcode);
      setErrorMsg(`${name} is not Valid.`);
    } else if (type === "emailid" && inputValue.trim() !== "") {
      // Perform email validation
      // Example: Check if the email is valid
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      const isValidEmail = emailRegex.test(inputValue);
      setError(!isValidEmail);
      setErrorMsg(`${name} is not Valid.`);
    }

    // Call the onChange prop
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        {label && (
          <Typography
            mb="5px"
            sx={{ fontWeight: "400", color: color ? color : "#969696" }}
          >
            {label}
            {isRequired && <Required />}
          </Typography>
        )}
        {/* <TextField
          sx={{
            borderRadius: "none",
            outline: "none",
            backgroundColor: "#fff",
            width: { md: size, sm: size, xs: "100%" },
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              padding: "16px 14px",
            },
          }}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          // required={isRequired}
          name={name}
          error={error}
          InputProps={InputProps}
          helperText={error && errorMsg}
          multiline={multiline}
          rows={rows}
        /> */}
        <TextField
          sx={{
            borderRadius: "none",
            outline: "none",
            backgroundColor: "#fff",
            width: { md: size, sm: size, xs: "100%" },
            "& .MuiInputBase-input": {
              // Updated the class name for the input element
              padding: "10px 14px",
            },
          }}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          // required={isRequired}
          name={name}
          error={error}
          InputProps={InputProps}
          helperText={error && errorMsg}
          multiline={multiline}
          rows={rows}
        />
      </Box>
    </>
  );
};
