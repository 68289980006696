/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Dispatch } from "redux";

import {
  addToCartAction,
  getCartAction,
  singleProductAction,
} from "../../redux/Actions/product.actions";
import { useNavigate } from "react-router-dom";

import { BasicButton, BreadCrumbs, ContainerBox } from "components";
import Ratings from "components/Ratings";
import { LoadingComponent } from "components/Loading";
import Counter from "components/Counter/Counter";
import MyImage from "./MyImage";
import ParentComponent from "./SelectCategories";
import { goToTop } from "utils/goToTop";
import { WishlistItem } from "components";
import { DataNotFound } from "components";
import { ToHTML } from "utils/ToHTML";
import "./table.css";
import DescriptionTabs from "common/Header/DescriptionTabs";
import SwiperSlider from "components/Swiper/SwiperSlider";
import { resetPostOrder } from "redux/Actions/order.actions";
import { ProductSwiperMain } from "components/Cards/SwiperCard/ProductSwiperMain";

type Props = {
  flag?: number;
};

// const URL = process.env.REACT_APP_API_URL;
export const SingleProduct = (props: Props) => {
  const location = useLocation();
  const id = location?.state?.id;
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  const theme = useTheme();

  const [quantity, setQuantity] = useState(1);
  const [payloadObject, setPayloadObject] = useState([]);
  const [isTrigger, setTrigger] = useState(false);
  const [cartAddingMethod, setCartAddingMethod] = useState<any>("");
  const [finalDispatchData, setFinalDispatchData] = useState({
    product_id: "",
    quantity: "",
    payloadObject: [],
    buyNow: false,
  });

  const [totalPrice, setTotalPrice] = useState<any>([]);
  const [selectedTotalPrice, setSelectedTotalPrice] = useState(0);
  const [orderQuantiy, setOrderQuantiy] = useState([]);

  const loading = useSelector(
    (state: any) => state?.singleProductReducer?.loading
  );

  const singleProductReducer = useSelector(
    (state: any) => state?.singleProductReducer?.listArr
  );

  const allProductListReducer = useSelector(
    (state: any) => state?.allProductListReducer?.listArr?.results
  );
  const addToCartReducer = useSelector(
    (state: any) => state?.addToCartReducer?.listArr?.addToCartDetails
  );

  const orderSelector = useSelector(
    (state: any) => state?.orderReducer?.listArr?.details
  );

  const currentPostIndex = allProductListReducer?.findIndex((elem: any) => {
    return elem?.id === singleProductReducer?.id;
  });

  const addToCart = (
    product_id: any,
    quantity: any
    // payload: any,
  ) => {
    setTrigger(true);
    const buyNow = false;
    setFinalDispatchData((prev: any) => ({
      ...prev,
      product_id,
      quantity,
      buyNow,
    }));
    // navigate(`/checkout`);
    setCartAddingMethod("addTocart");
  };

  const handleBuyNow = (
    product_id: any,
    quantity: any
    // payload: any,
  ) => {
    setTrigger(true);
    const buyNow = true;
    setFinalDispatchData((prev: any) => ({
      ...prev,
      product_id,
      quantity,
      buyNow,
    }));
    dispatch(getCartAction());
    setCartAddingMethod("buyNow");
    // navigate(`/checkout`);
    // goToTop();
  };

  const setDecrease = () => {
    quantity > singleProductReducer?.min_qty
      ? setQuantity(quantity - 1)
      : setQuantity(1);
  };

  const setIncrease = () => {
    quantity < singleProductReducer?.max_qty
      ? setQuantity(quantity + 1)
      : setQuantity(quantity);
  };

  useEffect(() => {
    if (addToCartReducer?.message && cartAddingMethod === "buyNow") {
      navigate(`/checkout`);
      goToTop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToCartReducer]);

  useEffect(() => {
    if (props?.flag !== 1) {
      dispatch(singleProductAction(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isTrigger) {
      // alert("283 worked");
      // handleFilterpayload();
      setTrigger(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrigger]);

  useEffect(() => {
    if (payloadObject?.length) {
      setFinalDispatchData((prev: any) => ({
        ...prev,
        payloadObject,
      }));
    }
  }, [payloadObject]);

  useEffect(() => {
    const getTotalPrice = totalPrice?.reduce((prev: any, next: any) => {
      return (prev = prev + next?.price);
    }, 0);
    const finalTotalPrice = getTotalPrice * quantity;
    // const finalTotalPriceFloat = (
    //   Math.floor(finalTotalPrice * 100) / 100
    // )?.toFixed(2);

    setSelectedTotalPrice(finalTotalPrice);
  }, [totalPrice, quantity]);

  useEffect(() => {
    if (isTrigger) {
      if (finalDispatchData?.payloadObject?.length) {
        const { product_id, quantity, payloadObject, buyNow } =
          finalDispatchData;
        const getCompArr = payloadObject?.map(({ id }: any) => id);
        if (product_id && payloadObject?.length) {
          dispatch(
            addToCartAction(
              product_id,
              quantity,
              getCompArr,
              buyNow,
              orderQuantiy
            )
          );
        }
      }
    }
  }, [finalDispatchData, dispatch]);

  useEffect(() => {
    setTotalPrice([]);
    setPayloadObject([]);
    setQuantity(1);
  }, [location?.pathname]);

  useEffect(() => {
    if (orderSelector?.success) {
      dispatch(resetPostOrder());
    }
  }, [orderSelector, dispatch]);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {singleProductReducer ? (
            <>
              {/* this ternary operater is used to make modification according to modal view  */}
              {props?.flag === 1 ? null : (
                <Box
                  sx={{
                    background: "#f5f5f5",
                    padding: "15px 0",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <ContainerBox>
                    <BreadCrumbs
                      link="products"
                      allProductListReducer={currentPostIndex}
                      allProductListData={allProductListReducer}
                    />
                  </ContainerBox>
                </Box>
              )}

              <Box sx={{ padding: "15px 0" }}>
                <ContainerBox maxWidth={props?.flag === 1 ? "80vw" : ""}>
                  <Box
                    sx={{ padding: { md: "0 20px", sm: "0 20px", xs: "0" } }}
                  >
                    {props?.flag === 1 ? null : (
                      <Box
                        sx={{
                          borderBottom: "1px solid #e0e0e0",
                          paddingBottom: "20px",
                          paddingTop: "20px",
                          marginBottom: "35px",
                        }}
                      >
                        <Typography
                          marginBottom="10px"
                          fontSize="25px"
                          fontFamily="oswald"
                          fontWeight="600"
                        >
                          {singleProductReducer?.name}
                        </Typography>
                        <Box display="flex">
                          <Ratings
                            fontSize="20px"
                            readOnly={true}
                            value={singleProductReducer?.overall_rating}
                          />
                          <Typography
                            color={theme.palette.primary.main}
                            fontSize="18px"
                            fontWeight="500"
                            paddingLeft="10px"
                          >
                            {singleProductReducer?.total_reviews} Customer
                            Reviews
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        gap: "1rem",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          width: { xs: "100%", md: "40%" },
                          marginTop: props?.flag === 1 ? "25px" : "",
                        }}
                      >
                        {singleProductReducer?.image_url &&
                        props?.flag === 1 ? (
                          <Box
                            sx={{
                              width: "100%",
                              // height: "100%",
                              overflow: "hidden",
                            }}
                          >
                            <ProductSwiperMain
                              isMain
                              data={singleProductReducer?.image_url}
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MyImage imgs={singleProductReducer?.image_url} />
                          </Box>
                        )}
                        {/* 
                        {singleProductReducer?.image_url ||
                        props?.flag === 1 ? (
                          <>
                           
                            <LazyImage
                              skeletonSrc={LoadingImage}
                              actualSrc={`${URL}${singleProductReducer?.base_image_url}`}
                              alt={singleProductReducer?.name}
                              width="100%"
                            />
                            
                          </>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MyImage imgs={singleProductReducer?.image_url} />
                          </Box>
                        )} */}
                        {props?.flag === 1 ? (
                          <BasicButton
                            placeholder="View Details"
                            width="100%"
                            //change made by suraj
                            onClick={() => {
                              navigate(
                                // `/singleProduct/${singleProductReducer?.id}`
                                `/products/${singleProductReducer?.name
                                  .toLowerCase()
                                  .split(" ")
                                  .filter((elem: any) => elem !== "–")
                                  .join("-")}`,
                                {
                                  state: { id: singleProductReducer?.id },
                                }
                              );
                              goToTop();
                            }}
                          />
                        ) : null}
                      </Box>
                      <Box
                        sx={{
                          width: { xs: "100%", md: "50%" },
                        }}
                      >
                        {props?.flag === 1 ? (
                          <Box
                            sx={{
                              borderBottom: "1px solid #e0e0e0",
                              paddingBottom: "20px",
                              paddingTop: "20px",
                              marginBottom: "35px",
                            }}
                          >
                            <Typography
                              marginBottom="10px"
                              fontSize="25px"
                              fontFamily="oswald"
                              fontWeight="600"
                            >
                              {singleProductReducer?.name}
                            </Typography>
                            <Box display="flex">
                              <Ratings
                                fontSize="20px"
                                readOnly={true}
                                value={singleProductReducer?.overall_rating}
                              />
                              <Typography
                                color={theme.palette.primary.main}
                                fontSize="18px"
                                fontWeight="500"
                                paddingLeft="10px"
                              >
                                {singleProductReducer?.total_reviews} Customer
                                Reviews
                              </Typography>
                            </Box>
                          </Box>
                        ) : null}
                        <Typography
                          sx={{
                            fontSize: "25px",
                            fontWeight: "700",
                            fontFamily: "Rajdhani",
                            color: theme.palette.primary.main,
                          }}
                        >
                          ${singleProductReducer?.base_price}
                        </Typography>
                        <Box
                          sx={{
                            fontSize: "19px",
                            fontWeight: "500",
                            color: "#000",
                            paddingTop: "15px",
                            overflowX: "auto",
                          }}
                        >
                          <div className="pricing-table-container">
                            <Box
                              sx={{
                                fontSize: "17px",
                                fontWeight: "500",
                                fontStyle: "normal",
                                lineHeight: "26px",
                              }}
                            >
                              {ToHTML(singleProductReducer?.long_description)}
                            </Box>
                          </div>
                        </Box>

                        <Box>
                          {singleProductReducer?.product_about?.map(
                            (item: any, index: any) => {
                              return (
                                <Typography
                                  key={index}
                                  sx={{
                                    fontSize: "19px",
                                    fontWeight: "500",
                                    color: "#000",
                                    paddingTop: "15px",
                                  }}
                                >
                                  {item}
                                </Typography>
                              );
                            }
                          )}
                        </Box>

                        <Box sx={{ margin: "10px 0" }}>
                          {singleProductReducer !== undefined && (
                            <ParentComponent
                              data={singleProductReducer?.ui_components}
                              payloadObject={payloadObject}
                              setPayloadObject={setPayloadObject}
                              //@ts-ignore
                              totalPrice={totalPrice}
                              setTotalPrice={setTotalPrice}
                              orderQuantiy={orderQuantiy}
                              setOrderQuantiy={setOrderQuantiy}
                              isPage="add"
                            />
                          )}
                        </Box>
                        <Box sx={{ marginTop: "15px" }}>
                          <Typography
                            sx={{
                              fontSize: "25px",
                              fontWeight: "700",
                              fontFamily: "Rajdhani",
                              color: "#000",
                            }}
                          >
                            Options Amount
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "25px",
                              fontWeight: "700",
                              fontFamily: "Rajdhani",
                              color: theme.palette.primary.main,
                            }}
                          >
                            {/* {isNaN(newSumm)
                              ? singleProductReducer?.base_price
                              : newSumm} */}
                            {`$${selectedTotalPrice?.toFixed(2)}`}
                          </Typography>
                        </Box>
                        <hr />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", lg: "row" },
                            margin: "1rem 0",
                            justifyContent: "space-between",
                            gap: "10px",
                            height: "auto",
                          }}
                        >
                          <Counter
                            setQuantity={setQuantity}
                            quantity={quantity}
                            setDecrease={setDecrease}
                            setIncrease={setIncrease}
                            item={singleProductReducer}
                          />

                          <BasicButton
                            placeholder="Add To Basket"
                            onClick={() => {
                              selectedTotalPrice > 0 &&
                                addToCart(singleProductReducer?.id, quantity);
                            }}
                          />
                          <BasicButton
                            placeholder="Buy Now"
                            // onClick={handleBuyNow}
                            onClick={() => {
                              selectedTotalPrice > 0 &&
                                handleBuyNow(
                                  singleProductReducer?.id,
                                  quantity
                                );
                            }}
                            isBlue
                          />
                          <WishlistItem
                            productId={
                              singleProductReducer?.id &&
                              singleProductReducer?.id
                            }
                            isWishlisted={singleProductReducer?.is_wishlist}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {props?.flag === 1 ? null : (
                      <DescriptionTabs
                        description={ToHTML(
                          singleProductReducer?.long_description
                        )}
                        productId={singleProductReducer?.id}
                        productReviews={singleProductReducer}
                      />
                    )}
                    {props?.flag === 1 ? null : (
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "2.5rem",
                            fontWeight: "800",
                            padding: "20px 0",
                          }}
                        >
                          Related Products
                        </Typography>
                        <SwiperSlider
                          data={allProductListReducer}
                          productCard={true}
                        />
                      </Box>
                    )}
                  </Box>
                </ContainerBox>
                {/* <ImageSwiperVertical /> */}
              </Box>
            </>
          ) : (
            <DataNotFound />
          )}
        </>
      )}
    </>
  );
};
