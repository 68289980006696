import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ContainerBox } from "components";
import { SectionHeading } from "components/SectionHeading/SectionHeading";
import React from "react";

type Props = {
  title?: string | undefined;
  backgroundImage?: any | undefined;
  minHeight?: any | undefined;
  subtitle1?: any | undefined;
  subtitle2?: any | undefined;
};
export const MediaCard = (props: Props) => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${props.backgroundImage})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#050505",
            position: "absolute",
            minHeight: props?.minHeight,
            width: "100%",
            opacity: "0.5",
            transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
          }}
        ></Box>

        <ContainerBox maxWidth="1200px">
          <Box
            sx={{
              minHeight: props.minHeight,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SectionHeading zIndex="11" color="#fff" heading={props?.title} />

            <Typography
              sx={{
                zIndex: "11",
                color: "#fff",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              {props?.subtitle1}
              <br />
              {props?.subtitle2}
            </Typography>
          </Box>
        </ContainerBox>
      </Box>
    </>
  );
};
