import { Box } from "@mui/material";
import { ContainerBox, DisplayCard, MediaCard } from "components";
import { DataNotFound } from "components/Containers/DataNotFound";
import SwiperContainer from "components/Swiper/Swiper";
import React, { useEffect, Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { promotionAction } from "redux/Actions/promotion.action";
import { FaqMediaCard } from "assets";

type Props = {};

export const Promotions = (props: Props) => {
  const dispatch: Dispatch<any> = useDispatch();
  const promotionList = useSelector(
    (state: any) => state?.promotionReducer?.listArr
  );
  useEffect(() => {
    dispatch(promotionAction());
  }, [dispatch]);

  return (
    <>
      <Box sx={{ marginBottom: "60px" }}>
        <MediaCard
          title="Promotions"
          minHeight="250px"
          backgroundImage={FaqMediaCard}
        />
      </Box>
      <ContainerBox>
        <section>
          {promotionList ? (
            <DisplayCard
              flexDirection="row"
              maxWidth="100%"
              imgWidth="50%"
              isHorizontal
              data={promotionList?.results}
            />
          ) : (
            <DataNotFound />
          )}
        </section>
      </ContainerBox>
      <SwiperContainer />
    </>
  );
};
