import { ADD_TO_CART_API, UPDATE_PRODUCT_BASKET_API } from "redux/constantAPI";
import {
  allProductList,
  deleteCart,
  getCart,
  getProductBasket,
  singleProduct,
  getCartCheckoutApi,
  getCouponVerificationApi,
} from "../restApi";
import {
  ADD_TO_CART_TYPE,
  ALL_PRODUCT_LIST_TYPE,
  DELETE_CART_TYPE,
  GET_CART_TYPE,
  GET_PRODUCT_BASKET_TYPE,
  SINGLE_PRODUCT_TYPE,
  UPDATE_PRODUCT_BASKET_TYPE,
} from "./action.types/action.types";
import { errorToaster, successToaster } from "utils/toaster";
import {
  COUPON_UPDATED,
  GET_CART_CHECKOUT,
  GET_COUPON_VERIFICATION,
} from "./action.types/order.types";

import axios from "axios";

export const requestProductList = () => {
  return {
    type: ALL_PRODUCT_LIST_TYPE.REQUEST,
  };
};

export const successProductList = (payload: any) => {
  return {
    type: ALL_PRODUCT_LIST_TYPE.SUCCESS,
    payload,
  };
};

export const faliureProductList = (payload: any) => {
  return {
    type: ALL_PRODUCT_LIST_TYPE.FALIURE,
    payload,
  };
};

export const allProductListAction = (sort?: any) => {
  return (dispatch: any) => {
    dispatch(requestProductList());
    allProductList(sort)
      .then((response) => {
        dispatch(successProductList(response));
      })
      .catch((error) => dispatch(faliureProductList(error)));
  };
};

//single product

export const requestSingleProduct = () => {
  return {
    type: SINGLE_PRODUCT_TYPE.REQUEST,
  };
};

export const successSingleProduct = (payload: any) => {
  return {
    type: SINGLE_PRODUCT_TYPE.SUCCESS,
    payload,
  };
};

export const faliureSingleProduct = (payload: any) => {
  return {
    type: SINGLE_PRODUCT_TYPE.FALIURE,
    payload,
  };
};

export const singleProductAction = (id: any) => {
  return (dispatch: any) => {
    dispatch(requestSingleProduct());
    singleProduct(id)
      .then((response) => {
        dispatch(successSingleProduct(response?.data));
      })
      .catch((error) => dispatch(faliureSingleProduct(error)));
  };
};

// Get Product Basket

export const requestProductBasket = () => {
  return {
    type: GET_PRODUCT_BASKET_TYPE.REQUEST,
  };
};

export const successProductBasket = (payload: any) => {
  return {
    type: GET_PRODUCT_BASKET_TYPE.SUCCESS,
    payload,
  };
};

export const faliureProductBasket = (payload: any) => {
  return {
    type: GET_PRODUCT_BASKET_TYPE.FALIURE,
    payload,
  };
};

export const getProductBasketAction = (id: any) => {
  return (dispatch: any) => {
    dispatch(requestProductBasket());
    getProductBasket(id)
      .then((response) => {
        dispatch(successProductBasket(response?.data));
      })
      .catch((error) => dispatch(faliureProductBasket(error)));
  };
};

// UPDATE PRODUCT BASKET

export const requestUpdateProductBasket = () => {
  return {
    type: UPDATE_PRODUCT_BASKET_TYPE.REQUEST,
  };
};

export const successUpdateProductBasket = (payload: any) => {
  return {
    type: UPDATE_PRODUCT_BASKET_TYPE.SUCCESS,
    payload,
  };
};

export const faliureUpdateProductBasket = (payload: any) => {
  return {
    type: UPDATE_PRODUCT_BASKET_TYPE.FALIURE,
    payload,
  };
};

export const updateProductBasketAction =
  (product: any, qty: any, components: any, orderQuantiy?: any) =>
  async (dispatch: any) => {
    let token = localStorage.getItem("guesttoken");
    if (localStorage.getItem("logtoken")) {
      token = localStorage.getItem("logtoken");
    } else {
      token = localStorage.getItem("guesttoken");
    }
    dispatch({
      type: UPDATE_PRODUCT_BASKET_TYPE.REQUEST,
      payload: {
        orderQuantiy,
        product,
        qty,
        components: components,
      },
    });

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${UPDATE_PRODUCT_BASKET_API}${product}/`,
        {
          orderQuantiy,
          product,
          qty,
          components: components,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const updateProductBasketDetails = response.data;

        dispatch({
          type: UPDATE_PRODUCT_BASKET_TYPE.SUCCESS,
          payload: { updateProductBasketDetails },
        });
        dispatch(getCartAction());
        successToaster(updateProductBasketDetails.message);
      } else {
        const error = response.data;

        dispatch({
          type: UPDATE_PRODUCT_BASKET_TYPE.FALIURE,
          payload: { error },
        });

        // errorToaster(error.response.data.message);
      }
    } catch (error: any) {
      dispatch({
        type: UPDATE_PRODUCT_BASKET_TYPE.FALIURE,
        payload: { error: error.message },
      });
      errorToaster(error.response.data.message);
    }
  };

// ADD to CART

export const addToCartAction =
  (product: any, qty: any, components: any, buyNow: any, orderQuantiy?: any) =>
  async (dispatch: any) => {
    let token = localStorage.getItem("guesttoken");
    if (localStorage.getItem("logtoken")) {
      token = localStorage.getItem("logtoken");
    } else {
      token = localStorage.getItem("guesttoken");
    }
    dispatch({
      type: ADD_TO_CART_TYPE.REQUEST,
      payload: {
        orderQuantiy,
        product,
        qty,
        components: components,
      },
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${ADD_TO_CART_API}`,
        {
          orderQuantiy,
          product,
          qty,
          components: components,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const addToCartDetails = response.data;
        const cartItem = response?.data?.data?.id;
        const payloadData = {
          cart_items: [cartItem],
        };

        dispatch({
          type: ADD_TO_CART_TYPE.SUCCESS,
          payload: { addToCartDetails },
        });
        if (buyNow) {
          dispatch(getCartCheckout(payloadData));
          dispatch(getCartAction());
        }

        successToaster(addToCartDetails.message);
      } else {
        const error = response.data;

        dispatch({ type: ADD_TO_CART_TYPE.FALIURE, payload: { error } });

        // errorToaster(error.response.data.message);
      }
    } catch (error: any) {
      dispatch({
        type: ADD_TO_CART_TYPE.FALIURE,
        payload: { error: error.message },
      });
      errorToaster(error.response.data.message);
    }
  };

// GET CART

export const requestCart = () => {
  return {
    type: GET_CART_TYPE.REQUEST,
  };
};

export const successCart = (payload: any) => {
  return {
    type: GET_CART_TYPE.SUCCESS,
    payload,
  };
};

export const faliureCart = (payload: any) => {
  return {
    type: GET_CART_TYPE.FALIURE,
    payload,
  };
};

export const getCartAction = (isLoad?:boolean) => {
  return (dispatch: any) => {
    isLoad && dispatch(requestCart());
    getCart()
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(successCart(response));
        }
      })
      .catch((error) => dispatch(faliureCart(error)));
  };
};

// Checkout cart

export const requestCartCheckout = () => {
  return {
    type: GET_CART_CHECKOUT.REQUEST,
  };
};

export const successCartCheckout = (payload: any) => {
  return {
    type: GET_CART_CHECKOUT.SUCCESS,
    payload,
  };
};

export const faliureCartCheckout = (payload: any) => {
  return {
    type: GET_CART_CHECKOUT.FALIURE,
    payload,
  };
};

export const getCartCheckout = (payload: any) => {
  return (dispatch: any) => {
    dispatch(requestCartCheckout());
    getCartCheckoutApi(payload)
      .then((response) => {
        if (response?.status === 200 && response) {
          
          sessionStorage.setItem("checkoutId", response?.data?.data?.id);
          dispatch(successCartCheckout(response?.data));
        }
      })
      .catch((error) => dispatch(faliureCartCheckout(error)));
  };
};

// Delete from cart

export const requestDeleteCart = () => {
  return {
    type: DELETE_CART_TYPE.REQUEST,
  };
};

export const successDeleteCart = (payload: any) => {
  return {
    type: DELETE_CART_TYPE.SUCCESS,
    payload,
  };
};

export const faliureDeleteCart = (payload: any) => {
  return {
    type: DELETE_CART_TYPE.FALIURE,
    payload,
  };
};

export const deleteCartAction = (id: any) => {
  return (dispatch: any) => {
    dispatch(requestDeleteCart());
    deleteCart(id)
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(successDeleteCart(response.data));
          successToaster(response?.data?.message);
        }
      })
      .catch((error) => dispatch(faliureDeleteCart(error)));
  };
};

export const deleteCartResetAction = () => {
  return (dispatch: any) => {
    dispatch(successDeleteCart(null));
  };
};

export const requestCouponVerfication = () => {
  return {
    type: GET_COUPON_VERIFICATION.REQUEST,
  };
};

export const successCouponVerfication = (payload: any) => {
  return {
    type: GET_COUPON_VERIFICATION.SUCCESS,
    payload,
  };
};

export const failedCouponVerification = (payload: any) => {
  return {
    type: GET_COUPON_VERIFICATION.FAILURE,
    payload,
  };
};

export const couponUpdatedTrue = () => {
  return {
    type: COUPON_UPDATED.TRUE,
  };
};

export const couponUpdatedFalse = () => {
  return {
    type: COUPON_UPDATED.FALSE,
  };
};

export const couponRemoved = () => {
  return {
    type: COUPON_UPDATED.REMOVE,
  };
};

export const getCouponVerificationAction = (payload: any) => {
  return (dispatch: any) => {
    dispatch(requestCouponVerfication());
    getCouponVerificationApi(payload)
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(successCouponVerfication(response?.data));
          successToaster(response?.data?.message);
          sessionStorage?.setItem("coupon", JSON.stringify(response?.data));
          dispatch(couponUpdatedTrue());
        }
      })
      .catch((error) => {
        errorToaster(error?.response?.data?.message);
        dispatch(failedCouponVerification(error));
        sessionStorage?.removeItem("coupon");
        dispatch(couponRemoved());
      });
  };
};
