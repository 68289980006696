import { Box, Typography } from "@mui/material";
import { ContainerBox } from "components";
import { LocationImg } from "assets";

type Props = {};

export const Location = (props: Props) => {
  return (
    <Box sx={{ background: "#000" }}>
      <ContainerBox>
        <section>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                color: "#FFFFFF",
                fontFamily: "Oswald, Sans-serif",
                lineHeight: "1.3em",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "60px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  fontFamily: "Oswald, Sans-serif",

                  lineHeight: "1.3em",
                  letterSpacing: "2.5px",
                }}
              >
                VISIT OUR LOCATION
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Rajdhani, Sans-serif",
                  fontSize: "22px",
                  fontWeight: "600",
                }}
              >
                Hours: 9am - 5pm weekdays
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Rajdhani, Sans-serif",
                  fontSize: "22px",
                  fontWeight: "600",
                }}
              >
                closed Sat / Sun
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Rajdhani, Sans-serif",
                  fontSize: "35px",
                  fontWeight: "600",
                }}
              >
                (403) 235-1616
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Rajdhani, Sans-serif",
                  fontSize: "35px",
                  fontWeight: "600",
                }}
              >
                orders@printcalgary.com
              </Typography>
            </Box>
            <Box sx={{ width: "60%" }}>
              <img
                loading="lazy"
                style={{
                  width: "100%",
                }}
                src={LocationImg}
                alt=""
              />
            </Box>
          </Box>
        </section>
      </ContainerBox>
    </Box>
  );
};
