/* eslint-disable no-restricted-globals */
import { Box, Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

type Props = {
  value?: any | undefined;
  fontSize?: any | undefined;
  name?: any;
  onChange?: any;
  readOnly?: any;
};

const Ratings = (props: Props) => {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {props?.readOnly ? (
          <Rating
            name="read-only"
            readOnly
            value={props?.value}
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55, fontSize: props.fontSize }} />
            }
          />
        ) : (
          <Rating
            name="simple-controlled"
            value={props?.value}
            onChange={props?.onChange}
          />
        )}
      </Box>
    </>
  );
};

export default Ratings;
