import { getLostPassword } from "redux/restApi";
import { LOST_PASSWORD_TYPE } from "./action.types/lost.password.types";
import { errorToaster } from "utils/toaster";

export const requestLostPassword = () => {
  return {
    type: LOST_PASSWORD_TYPE.REQUEST,
  };
};

export const successLostPassword = (payload: any) => {
  return {
    type: LOST_PASSWORD_TYPE.SUCCESS,
    payload,
  };
};

export const faliureLostPassword = (payload: any) => {
  return {
    type: LOST_PASSWORD_TYPE.FALIURE,
    payload,
  };
};

export const lostPasswordAction = (data: any) => {
  return (dispatch: any) => {
    dispatch(requestLostPassword());
    getLostPassword(data)
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(successLostPassword(response));
        }
      })
      .catch((error) => {errorToaster(error.response.data.message);
        dispatch(faliureLostPassword(error))});
  };
};
