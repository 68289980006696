import { toast } from "react-toastify";

export const successToaster = (message: any) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const errorToaster = (message: any) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const warningToaster = (message: any) => {
  toast.warning(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const informationToaster = (message: any) => {
  toast.info(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
