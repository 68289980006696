import * as React from "react";

import {
  Button,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Stack,
  Box,
} from "@mui/material";

import { NavLink, Link } from "react-router-dom";
import { goToTop } from "utils/goToTop";
import { DataNotFound } from "components";
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type Props = {
  data?: any | undefined;
  heading?: any | undefined;
  width?: any | undefined;
  gridSize?: any | undefined;
  margin?: any | undefined;
  link?: any | undefined;
  padding?: any | undefined;
  isProduct?: boolean | undefined;
};
export function MenuListComposition({
  data,
  heading,
  width,
  gridSize,
  margin,
  link,
  padding,
  isProduct,
}: Props) {
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(true);
  };

  const handleClose = (event: any) => {
    setOpen(false);
  };
  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          className="menulistDropdown"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          // @ts-ignore
          onMouseEnter={(e) => handleToggle(e)}
          onMouseLeave={(e) => handleClose(e)}
          sx={{
            height: "8vh",
            fontSize: "1rem",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* caret box is implmented by suraj  */}
            {/* <Box
                className="caret"
                sx={{
                    
                      rotate:"-90deg",
                      color:"black",
                      position: "absolute",
                      display:"inline-block",
                    
                      // top:"94px",
                      top: '-1px',
                      right: '-14px',
                     
                     
                  "&::before": {
                    content: '"<"',
                    // content: '"\f0d7"',
                    speak: "none",
                    fontStretch: "normal",
                    fontWeight: "normal",
                    fontFamily: "tb-icon",
                    fontSize:"16px",
                   
                   
                  },
               
                }}
              ></Box> */}

            <NavLink
              to={link}
              className={(navData) =>
                navData.isActive ? "activeNavItem menuListItem" : "menuListItem"
              }
              onClick={goToTop}
            >
              {heading}
            </NavLink>

            <KeyboardArrowDownIcon
              className="caret"
              sx={{ color: "#1b1b1b", fontSize: "1rem" }}
            />
          </span>
        </Button>

        <Popper
          sx={{
            margin: margin,
            zIndex: "666",
            width: width,
            borderTop: "1px solid #e1e1e1",
            textTransform: "capitalize",
          }}
          open={open}
          //@ts-ignore
          onMouseEnter={(e) => handleToggle(e)}
          onMouseLeave={(e) => handleClose(e)}
          placement="bottom-start"
          disablePortal
        >
          <Paper>
            <div>
              {data?.length > 0 ? (
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  sx={{ padding: padding }}
                >
                  <Box
                    sx={{
                      // backgroundColor: "yellow",
                      width: "100vw",
                      height: isProduct ? "450px" : "auto",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    {data?.map((item: any, index: any) => {
                      return (
                        <React.Fragment key={index}>
                          <Box>
                            <MenuItem
                              key={index}
                              disableRipple
                              disableTouchRipple
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                            >
                              {isProduct ? (
                                <>
                                  {isProduct && (
                                    <NavLink
                                      style={({ isActive }) => ({
                                        color: isActive ? "#ec0a90" : "#000",
                                      })}
                                      //? here Ascii key is sent so we have to filter using that
                                      to={`/products/${item?.name
                                        .toLowerCase()
                                        .split(" ")
                                        .filter((elem: any) => elem !== "–")
                                        .join("-")}/`}
                                      onClick={(e) => {
                                        goToTop();
                                        handleClose(e);
                                      }}
                                      state={{
                                        id: item?.id,
                                      }}
                                    >
                                      {item?.name}
                                    </NavLink>
                                  )}
                                </>
                              ) : (
                                <Link
                                  onClick={(e) => {
                                    goToTop();
                                    handleClose(e);
                                  }}
                                  to={item?.link}
                                >
                                  {item?.name}
                                </Link>
                              )}
                            </MenuItem>
                          </Box>
                        </React.Fragment>
                      );
                    })}
                  </Box>
                </MenuList>
              ) : (
                <DataNotFound />
              )}
            </div>
          </Paper>
        </Popper>
      </div>
    </Stack>
  );
}
