import React, { useEffect, useState, Dispatch } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Home as HomeIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { Notification } from "components/Notification/Notification";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { goToTop } from "utils/goToTop";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getCartAction } from "redux/Actions/product.actions";
import { getWishlistAction } from "redux/Actions/wishlist.actions";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
export const BottomNav = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedRoute, setSelectedRoute] = useState("/");

  const handleNavigationChange = (event: any, newValue: any) => {
    setSelectedRoute(newValue);
    navigate(newValue);
  };
  const dispatch: Dispatch<any> = useDispatch();
  const getCart = useSelector(
    (state: any) => state?.getCartReducer?.listArr?.data?.results
  );

  const getWishlist = useSelector(
    (state: any) => state?.getWishlistReducer?.listArr?.data?.data
  );

  useEffect(() => {
    dispatch(getCartAction());
    dispatch(getWishlistAction());
  }, [dispatch]);

  const activeIconStyle = {
    color: "#00AEEF",
  };

  return (
    <>
      <BottomNavigation
        showLabels
        value={selectedRoute}
        onChange={handleNavigationChange}
        sx={{
          padding: "2rem 0",
          boxShadow: "0 0 20px 0 rgba(0,0,0,0.1)",
        }}
      >
        <BottomNavigationAction
          onClick={goToTop}
          sx={{
            color: theme.palette.primary.main,
            background:
              selectedRoute === "/"
                ? activeIconStyle.color
                : theme.palette.primary.main,
          }}
          label="Home"
          value="/"
          icon={
            selectedRoute === "/" ? (
              <HomeIcon
                sx={{
                  color: theme.palette.primary.main,
                  transition: "all .2s linear",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00AEEF",
                  },
                }}
              />
            ) : (
              <HomeOutlinedIcon
                sx={{
                  color: theme.palette.primary.main,
                  transition: "all .2s linear",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00AEEF",
                  },
                }}
              />
            )
          }
        />
        <BottomNavigationAction
          onClick={goToTop}
          label="Wishlist"
          value="/wishlist"
          icon={
            <Notification
              icon={
                selectedRoute === "/wishlist" ? (
                  <FavoriteIcon
                    sx={{
                      color: theme.palette.primary.main,
                      transition: "all .2s linear",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#00AEEF",
                      },
                    }}
                  />
                ) : (
                  <FavoriteBorderOutlinedIcon
                    sx={{
                      color: theme.palette.primary.main,
                      transition: "all .2s linear",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#00AEEF",
                      },
                    }}
                  />
                )
              }
              badgeContent={getWishlist?.length > 0 ? getWishlist?.length : "0"}
            />
          }
        />
        <BottomNavigationAction
          onClick={goToTop}
          label="Cart"
          value="/cart"
          icon={
            <Notification
              icon={
                selectedRoute === "/cart" ? (
                  <ShoppingCartIcon
                    sx={{
                      color: theme.palette.primary.main,
                      transition: "all .2s linear",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#00AEEF",
                      },
                    }}
                  />
                ) : (
                  <ShoppingCartOutlinedIcon
                    sx={{
                      color: theme.palette.primary.main,
                      transition: "all .2s linear",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#00AEEF",
                      },
                    }}
                  />
                )
              }
              badgeContent={getCart?.length > 0 ? getCart?.length : "0"}
            />
          }
        />
        <BottomNavigationAction
          onClick={goToTop}
          label="Account"
          value="/myAccount"
          icon={
            selectedRoute === "/myAccount" ? (
              <PersonIcon
                sx={{
                  color: theme.palette.primary.main,
                  transition: "all .2s linear",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00AEEF",
                  },
                }}
              />
            ) : (
              <PersonOutlineIcon
                sx={{
                  color: theme.palette.primary.main,
                  transition: "all .2s linear",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00AEEF",
                  },
                }}
              />
            )
          }
        />
      </BottomNavigation>
    </>
  );
};
