export const GUEST_USER_API = "/user/api/v1/create_guest_user";

export const REGISTER_API = "/user/api/v1/create_user";

export const GET_USER_ADDRESS_API = "/user/api/v1/user_address";

export const GET_USER_PROFILE_API = "/user/api/v1/user_profile";

export const LOGIN_API = "/admin_handler/api/v1/login";

export const ALL_PRODUCT_API = "/product/api/v1/product/";

export const ALL_BLOGS_API = "/product/api/v1/blog/";

export const PROMOTION_LIST_API = "/product/api/v1/promotion";

export const DESIGN_GALLERY = "/product/api/v1/design_gallery";

export const FAQ_API = "/product/api/v1/faqs";

export const ADD_TO_CART_API = "/product/api/v1/cart/";

export const GET_CART_API = "/product/api/v1/cart/";

export const UPDATE_PRODUCT_BASKET_API = "/product/api/v1/cart/";

export const UPDATE_CART_PRODUCT_QUANTITY_API = "/product/api/v1/cart_qty";

export const GET_TEMPLATE_API = "/product/api/v1/template";

export const GET_WISHLIST_API = "/product/api/v1/wishlist";

export const ORDER_API = "/order/api/v1/order/";

export const LOST_PASSWORD_API = "/user/api/v1/reset_pssword";

export const CHANGE_PASSWORD_API = "/user/api/v1/change_pssword";

export const ADD_REVIEW_API = "/product/api/v1/rating";

export const GET_CART_CHECKOUT_API = "/order/api/v1/checkout/";

export const POST_BLOG_COMMENTS = "/product/api/v1/blog_comments";

export const GET_COUPON_VERIFICATION_API = "/coupon/api/v1/validate_coupon";

export const REQUEST_A_QUOTE_API = "";

export const FILE_UPLOAD_API = "/order/api/v1/uploadOrderFiles";


