import { GET_WISHLIST_TYPE, PATCH_WISHLIST_TYPE } from "redux/Actions/action.types/action.types";

const initialState = {
    listArr: [],
    loading: true,
  };

export const getWishlistReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case GET_WISHLIST_TYPE.REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_WISHLIST_TYPE.SUCCESS:
        return {
          ...state,
          listArr: action.payload,
          loading: false,
        };
      case GET_WISHLIST_TYPE.FALIURE:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };
  

  export const patchWishlistReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case PATCH_WISHLIST_TYPE.REQUEST:
        return {
          ...state,
          loading: true,
        };
      case PATCH_WISHLIST_TYPE.SUCCESS:
        return {
          ...state,
          listArr: action.payload,
          loading: false,
        };
      case PATCH_WISHLIST_TYPE.FALIURE:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };