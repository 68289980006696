import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { BasicButton } from "components/Button";
import { goToTop } from "utils/goToTop";
import LazyImage from "components/LazyImage";
import { LoadingImage } from "assets";

interface HorizontalCardProps {
  imageSrc?: string;
  title?: string;
  date?: string;
  description?: any;
  blogs?: boolean;
  width?: any;
  id?: any;
  category?: any;
}

export const HorizontalCard: React.FC<HorizontalCardProps> = ({
  imageSrc,
  title,
  date,
  description,
  blogs,
  width,
  id,
  category,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          margin: "20px 0",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ position: "relative", width: { xs: "100%", md: width } }}>
          <LazyImage
            skeletonSrc={LoadingImage}
            actualSrc={imageSrc ?? ""}
            alt={title ?? ""}
            width="100%"
          />

          {/* <img
            // loading="lazy"
            style={{
              width: "100%",
            }}
            src={imageSrc}
            alt="pic"
          /> */}
          {/* {blogs && (
            <Box>
              <Icon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: theme.palette.primary.main,
                  color: "#fff",
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  padding: "5px",
                  fontSize: "2rem",
                }}
              >
                <PushPinOutlinedIcon />
              </Icon>
            </Box>
          )} */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            gap: "15px",
            width: { xs: "100%", md: "50%" },
          }}
        >
          <Box>
            <Typography fontSize="23px" fontWeight="800">
              {/* <Link to={`singleBlog/${id}`} onClick={goToTop}> */}
              <Link
                //? here Ascii key is sent so we have to filter using that
                to={`/blogs/${title
                  ?.toLowerCase()
                  .split(" ")
                  .filter((elem: any) => elem !== "–")
                  .join("-")}`}
                state={{
                  id: id,
                }}
                onClick={goToTop}
              >
                {title}
              </Link>
            </Typography>
            {blogs && (
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography>{date}</Typography>
                <Link
                  to={`/blogs/category/${category?.name
                    ?.split(" ")
                    ?.join("-")
                    ?.toLowerCase()}`}
                  // to="/blogs"
                  onClick={goToTop}
                  style={{ display: "flex" }}
                  state={{ categoryId: category?.id }}
                >
                  {category?.name}
                </Link>
              </Box>
            )}
          </Box>
          {blogs && (
            <>
              <Box>
                <Box
                  sx={{
                    fontFamily: "Open Sans, Sans-serif",
                    color: "#555555 ",
                    lineHeight: "27px",
                  }}
                >
                  {/* {description?.length > 200
                    ? `${description.substring(0, 200)}...`
                    : description} */}
                  {description}
                </Box>
              </Box>

              <Link
                //? here Ascii key is sent so we have to filter using that
                to={`/blogs/${title
                  ?.toLowerCase()
                  .split(" ")
                  .filter((elem: any) => elem !== "–")
                  .join("-")}`}
                state={{
                  id: id,
                }}
                onClick={goToTop}
              >
                <BasicButton
                  placeholder="Continue Reading"
                  width="fit-content"
                  icon={<AddIcon />}
                  flexDirection="row-reverse"
                />
              </Link>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default HorizontalCard;
