import { template } from "redux/restApi";
import { TEMPLATE_TYPE } from "./action.types/action.types";


export const requestTemplate = () => {
    return {
      type: TEMPLATE_TYPE.REQUEST,
    };
  };
  
  export const successTemplate = (payload: any) => {
    return {
      type: TEMPLATE_TYPE.SUCCESS,
      payload,
    };
  };
  
  export const faliureTemplate = (payload: any) => {
    return {
      type: TEMPLATE_TYPE.FALIURE,
      payload,
    };
  };
  
  export const tempalteAction = () => {
    return (dispatch: any) => {
      dispatch(requestTemplate());
      template()
        .then((response) => {
          if(response?.status === 200 && response){
            dispatch(successTemplate(response));
          }
        })
        .catch((error) => dispatch(faliureTemplate(error)));
    };
  };
  