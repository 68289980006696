import { AddressForm, BasicButton, BreadCrumbs } from "components";
import { Box, Typography } from "@mui/material";
import { getUserAddressAction } from "redux/Actions/userAddress.actions";
import { useDispatch, useSelector } from "react-redux";
import { ContainerBox } from "components";
import React, { Dispatch, useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  postOrderAction,
  singleOrderListAction,
} from "redux/Actions/order.actions";
import StripeCheckout from "react-stripe-checkout";
import { stateWiseTax } from "utils/constant";
import { StyledCoupon } from "components/Coupon";
import {
  couponUpdatedFalse,
  getCartAction,
} from "redux/Actions/product.actions";
import { goToTop } from "utils/goToTop";
import { errorToaster } from "utils/toaster";

type Props = {};

export const Checkout = (props: Props) => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const Location = useLocation();
  const [checkoutBillingData, setCheckoutBillingData] = useState<any>({
    company_name: "",
    country_name: "",
    email_address: "",
    first_name: "",
    last_name: "",
    phone: "",
    postcode: "",
    province: "",
    street_address_1: "",
    street_address_2: "",
    town_city: "",
  });

  const [checkoutShippingData, setCheckoutShippingData] = useState<any>({
    company_name: "",
    country_name: "",
    email_address: "",
    first_name: "",
    last_name: "",
    phone: "",
    postcode: "",
    province: "",
    street_address_1: "",
    street_address_2: "",
    town_city: "",
  });
  const [agreement, setAgreement] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [description, setDescription] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [stripe, setStripe] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [couponAvailable, setCouponAvailable] = useState(false);
  const [orderData, setOrderData] = useState(false);

  const [isShowId, setShowId] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const payMethodForm = useRef(null);

  const [isStripeDisable, setIsStripeDisable] = useState(false);

  const userAddressListReducer = useSelector(
    (state: any) => state?.getUserAddressReducer?.listArr?.data?.data
  );

  const cartItems = useSelector(
    (state: any) => state?.getCartReducer?.listArr?.data?.results
  );

  const orderSelector = useSelector(
    (state: any) => state?.orderReducer?.listArr?.details
  );

  const couponUpdate = useSelector((state: any) => state?.getCouponUpdated);

  const handleChangeCheckbox = (event: any) => {
    setAgreement(event?.target?.checked);
  };

  const headings = ["Product", "Subtotal"];

  useEffect(() => {
    const {
      email_address,
      first_name,
      last_name,
      phone,
      postcode,
      street_address_1,
      town_city,
    } = checkoutBillingData || {};
    if (
      email_address &&
      first_name &&
      last_name &&
      phone &&
      postcode &&
      street_address_1 &&
      town_city
    ) {
      setDisabled(false);
    } else setDisabled(true);
  }, [checkoutBillingData]);

  useEffect(() => {
    if (agreement) {
      if (
        checkoutShippingData?.email_address &&
        checkoutShippingData?.first_name &&
        checkoutShippingData?.last_name &&
        checkoutShippingData?.phone &&
        checkoutShippingData?.postcode &&
        checkoutShippingData?.street_address_1 &&
        checkoutShippingData?.town_city &&
        checkoutBillingData?.email_address &&
        checkoutBillingData?.first_name &&
        checkoutBillingData?.last_name &&
        checkoutBillingData?.phone &&
        checkoutBillingData?.postcode &&
        checkoutBillingData?.street_address_1 &&
        checkoutBillingData?.town_city
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
        //@ts-ignore
        payMethodForm?.current?.reset();
        setStripe(false);
        setShowId("");
      }
    }
  }, [checkoutBillingData, checkoutShippingData, agreement]);

  const handleBillingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.name === "province") {
      sessionStorage.removeItem("province"); //? temporay fix for province
    }
    setCheckoutBillingData({
      ...checkoutBillingData,
      [e.target.name]: e.target.value,
    });
  };

  const handleShippingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.name === "province") {
      sessionStorage.removeItem("province"); //? temporay fix for province
    }
    setCheckoutShippingData({
      ...checkoutShippingData,
      [e.target.name]: e.target.value,
    });
  };
  const paymentData = [
    {
      method: "Direct bank transfer",
      value: "DIRECT_BANK_TRANSFER",
      description:
        "Make your payment directly into our bank account . Please use your Order ID as the payment reference.Your order will not be shipped until the fundshave cleared in our account.",
    },
    {
      method: "Cash on delivery",
      value: "CASH_ON_DELIVERY",
      description: "Pay with cash upon delivery.",
    },
    { method: "Stripe", value: "STRIPE", description: "Pay via Stripe." },
  ];

  const handleFormValidations = () => {
    let errorMsg = "";
    if (
      checkoutBillingData?.first_name?.trim() === "" ||
      !checkoutBillingData?.first_name
    ) {
      // errorToaster("Please fill first name");
      errorMsg = "Please fill first name";
    } else if (
      checkoutBillingData?.last_name?.trim() === "" ||
      !checkoutBillingData?.last_name
    ) {
      // errorToaster("Please fill last name");
      errorMsg = "Please fill last name";
    } else if (
      checkoutBillingData?.street_address_1?.trim() === "" ||
      !checkoutBillingData?.street_address_1
    ) {
      // errorToaster("Please fill street address");
      errorMsg = "Please fill street address";
    } else if (
      checkoutBillingData?.town_city?.trim() === "" ||
      !checkoutBillingData?.town_city
    ) {
      // errorToaster("Please fill town/city");
      errorMsg = "Please fill town/city";
    } else if (
      checkoutBillingData?.postcode?.trim() === "" ||
      !checkoutBillingData?.postcode
    ) {
      // errorToaster("Please fill postcode");
      errorMsg = "Please fill postcode";
    } else if (
      /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(
        checkoutBillingData?.postcode?.toUpperCase()
      ) !== true
    ) {
      // errorToaster("Postal Code is not valid");
      errorMsg = "Postal Code is not valid";
    } else if (checkoutBillingData?.phone?.trim() === "") {
      errorToaster("Please fill phone");
    } else if (
      /^(?:\+1[-.\s]?)?(?:\([2-9][0-9]{2}\)|[2-9][0-9]{2})[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/.test(
        checkoutBillingData?.phone
      ) !== true
    ) {
      // errorToaster("Phone Number is not valid");
      errorMsg = "Phone Number is not valid";
    } else if (checkoutBillingData?.email_address?.trim() === "") {
      // errorToaster("Please fill email address");
      errorMsg = "Please fill email address";
    } else if (
      /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
        checkoutBillingData?.email_address
      ) !== true
    ) {
      // errorToaster("Email is not valid");
      errorMsg = "Email is not valid";
    } else {
      if (agreement === false) {
        if (paymentMode === "") {
          // errorToaster("Please select payment mode");
          errorMsg = "Please select payment mode";
        } else {
          return true;
        }
      } else {
        if (
          checkoutShippingData?.first_name?.trim() === "" ||
          !checkoutShippingData?.first_name
        ) {
          // errorToaster("Please fill first name");
          errorMsg = "Please fill first name";
        } else if (
          checkoutShippingData?.last_name?.trim() === "" ||
          !checkoutShippingData?.last_name
        ) {
          // errorToaster("Please fill last name");
          errorMsg = "Please fill last name";
        } else if (
          checkoutShippingData?.street_address_1?.trim() === "" ||
          !checkoutShippingData?.street_address_1
        ) {
          // errorToaster("Please fill street address");
          errorMsg = "Please fill street address";
        } else if (
          checkoutShippingData?.town_city?.trim() === "" ||
          !checkoutShippingData?.town_city
        ) {
          // errorToaster("Please fill town/city");
          errorMsg = "Please fill town/city";
        } else if (
          checkoutShippingData?.postcode?.trim() === "" ||
          !checkoutShippingData?.postcode
        ) {
          // errorToaster("Please fill postcode");
          errorMsg = "Please fill postcode";
        } else if (
          /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(
            checkoutShippingData?.postcode?.toUpperCase()
          ) !== true
        ) {
          // errorToaster("Postal Code is not valid");
          errorMsg = "Postal Code is not valid";
        } else if (checkoutShippingData?.phone?.trim() === "") {
          // errorToaster("Please fill phone");
          errorMsg = "Please fill phone";
        } else if (
          /^(?:\+1[-.\s]?)?(?:\([2-9][0-9]{2}\)|[2-9][0-9]{2})[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/.test(
            checkoutShippingData?.phone
          ) !== true
        ) {
          // errorToaster("Phone Number is not valid");
          errorMsg = "Phone Number is not valid";
        } else if (checkoutShippingData?.email_address?.trim() === "") {
          // errorToaster("Please fill email address");
          errorMsg = "Please fill email address";
        } else if (
          /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
            checkoutShippingData?.email_address
          ) !== true
        ) {
          // errorToaster("Email is not valid");
          errorMsg = "Email is not valid";
        } else {
          return true;
        }
      }
    }
    if (errorMsg) {
      errorToaster(errorMsg);
    }
  };

  const handleShowPayMode = (e: any, idx: string) => {
    setShowId(idx);
    if (e.target.checked) {
      setPaymentMode(e.target.value);
    } else {
      setPaymentMode("");
    }
    if (e.target.value === "STRIPE") {
      setStripe(true);
    } else {
      setStripe(false);
    }
  };

  const handleCouponClick = () => {
    let element = document.getElementsByClassName("ClickBox")[0];
    element.classList.toString() === "ClickBox MuiBox-root css-1pai5xs tst"
      ? element.classList.remove("tst")
      : element.classList.add("tst");
  };

  const handlePlaceOrder = (e?: React.ChangeEvent<HTMLInputElement>) => {
    const isValidation = handleFormValidations();
    if (isValidation) {
      if (paymentMode === "") {
        errorToaster("Please select payment mode");
      } else {
        // @ts-ignore
        let tax = parseInt(sessionStorage.getItem("tax"));
        let product = cartItems.map((item: any) => ({
          cart_id: item?.id,
          document: item?.product.base_image_url,
        }));
        dispatch(
          postOrderAction(
            discountedAmount,
            tax,
            product,
            agreement,
            paymentMode,
            description,
            checkoutShippingData,
            checkoutBillingData
          )
        );
        setOrderData(true);
        goToTop();
        // navigate("/orders");
        // navigate(`/orders/${orderSelector?.data?.id}`);
      }
    }
  };

  const onToken = (token: any) => {
    const checkoutId = sessionStorage.getItem("checkoutId");

    token["checkoutId"] = checkoutId;
    const stripePaymentRes = token;
    let tax = sessionStorage.getItem("tax");
    let isStripe = true;
    let product = cartItems.map((item: any) => ({
      cart_id: item?.id,
      document: item?.product.base_image_url,
    }));
    dispatch(
      postOrderAction(
        discountedAmount,
        tax,
        product,
        agreement,
        paymentMode,
        description,
        checkoutShippingData,
        checkoutBillingData,
        stripePaymentRes,
        isStripe
      )
    );
    setOrderData(true);
    goToTop();
  };

  const getProductTax = (price?: any) => {
    let province: any;
    if (sessionStorage.getItem("province")) {
      province = sessionStorage.getItem("province");
    } else {
      province = document?.getElementById("provinceSelectBox")?.textContent;
    }
    const taxPercent = stateWiseTax?.filter(
      (item) => item?.province === province
    )[0]?.taxPercent;
    const taxAmount = totalAmount - (totalAmount * 100) / (100 + taxPercent);
    // taxAmount && setCartTax(taxAmount)
    //@ts-ignore
    sessionStorage.setItem("tax", taxAmount?.toFixed(2));
    return taxAmount.toFixed(2);
  };

  useEffect(() => {
    if (sessionStorage?.getItem("coupon")) {
      setCouponAvailable(true); //? To show coupon applied component
      //?parsing coupon from session storage
      const coupon = sessionStorage?.getItem("coupon");
      const couponRes = coupon && JSON?.parse(coupon);

      //? product information available in the cart
      //? products will output ids of available product in the cart ---- example output [1,3,6,8]
      const products = cartItems?.map((item: any) => item?.product?.id);
      //? productsWithPrice will have have quantity and price of the product alone with id -----example output [{id:1, base_price: 2500, qty: 6}....]
      const productsWithPrice = cartItems?.map((item: any) => ({
        id: item?.product?.id,
        base_price: item?.product?.base_price,
        qty: item?.qty,
      }));
      //? need to short the products to compare or validate with couponRes as api will always output sorted array of products
      const sortedProducts =
        //@ts-ignore
        products && [...new Set(products)]?.sort((a, b) => a - b);
      //? parse only productIds from couponRes
      const couponEnabledProducts = couponRes?.data?.product;

      //? if discount is in percentage or it is flat(it is dealt in else part)
      if (couponRes?.data?.is_percentage) {
        //? validating whole cart is eligble for coupon of specific products

        //? if true
        if (
          JSON.stringify(sortedProducts) ===
          JSON.stringify(couponEnabledProducts)
        ) {
          const discountValue = (totalAmount * couponRes?.data?.discount) / 100;
          setDiscount(discountValue);
          setDiscountedAmount(totalAmount - discountValue);
        } else {
          //? if not (whole cart is not eligible for coupon)

          //? couponVerifiedProducts verify products which are eligble for coupon
          const couponVerifiedProducts = sortedProducts?.filter(
            (element: any) => couponEnabledProducts?.includes(element)
          );
          //? couponVerifiedProductsWithPrice will fetch the price and quantity of the product eligible for coupon
          const couponVerifiedProductsWithPrice = productsWithPrice?.filter(
            (element: any) => couponVerifiedProducts?.includes(element?.id)
          );
          //? priceEligibleForDiscount is final price which is eligible for discount (using item and quantity of each of the item to calculate that)
          const priceEligibleForDiscount =
            couponVerifiedProductsWithPrice?.reduce(
              (acc: any, item: any) => acc + item?.base_price * item?.qty,
              0
            );
          //? fetch the discount
          const discountValue =
            (priceEligibleForDiscount * couponRes?.data?.discount) / 100;
          setDiscount(discountValue);
          setDiscountedAmount(totalAmount - discountValue);
        }
      } else {
        const discountValue = couponRes?.data?.discount;
        //? max discount value should not be greater than total amount
        if (discountValue > totalAmount) {
          setDiscount(totalAmount);
        } else {
          setDiscount(discountValue);
        }
        setDiscountedAmount(Math?.max(totalAmount - discountValue, 0));
      }
    } else {
      setDiscountedAmount(totalAmount);
    }
    dispatch(couponUpdatedFalse());
  }, [dispatch, cartItems, totalAmount, couponUpdate?.updated]);

  useEffect(() => {
    if (couponUpdate?.removed === true) {
      setCouponAvailable(false);
      setDiscount(0);
      setDiscountedAmount(totalAmount);
    }
  }, [totalAmount, couponUpdate?.removed]);

  //? to navigate from checkout page to order page we have given the dependency of orderData true on click of the place holder
  useEffect(() => {
    if (orderSelector?.success && orderData) {
      navigate(`/orders/${orderSelector?.data?.id}`);
      dispatch(singleOrderListAction(orderSelector?.data?.id));
      dispatch(getCartAction());
      setOrderData(false);
      // setViewOrder(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSelector, orderData]);

  useEffect(() => {
    const getGuestToken = localStorage.getItem("guesttoken");
    !getGuestToken && dispatch(getUserAddressAction());
  }, [dispatch]);

  useEffect(() => {
    setCheckoutBillingData(userAddressListReducer?.billing_address);
    setCheckoutShippingData(userAddressListReducer?.shipping_address);
  }, [userAddressListReducer]);

  useEffect(() => {
    let amount = cartItems?.reduce(
      (acc: any, item: any) => acc + item.product.base_price * item.qty,
      0
    );

    setTotalAmount(amount);
    setSubTotalAmount(amount);
  }, [cartItems]);

  useEffect(() => {
    if (Location?.pathname === "/checkout") {
      if (orderSelector?.success) navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Location?.pathname]);

  useEffect(() => {
    if (discountedAmount === 0) {
      setIsStripeDisable(true);
    }
  }, [discountedAmount]);

  return (
    <>
      <ContainerBox maxWidth="1200px">
        <BreadCrumbs link="Checkout" />
        <section>
          <Box
            sx={{
              marginBottom: "1rem",
              width: { md: "50%", sm: "50%", xs: "100%" },
            }}
          >
            <Typography
              sx={{
                fontSize: "1.8rem",
                fontWeight: "700",
                marginBottom: "10px",
              }}
            >
              Checkout
            </Typography>
            <Box>
              <p style={{ fontSize: "18px", fontWeight: "600" }}>
                Have a Coupon?{" "}
                <span
                  style={{ color: "#ec0a90", cursor: "pointer" }}
                  onClick={handleCouponClick}
                >
                  Click Here to enter your code
                </span>
              </p>
              <Box
                className={"ClickBox"}
                sx={{
                  overflow: "hidden",
                  height: "0px",
                  transition: "300ms ease-in-out",
                }}
              >
                <StyledCoupon
                  checkout={true}
                  heading={"If you have a coupon code, please apply it below."}
                />
              </Box>
            </Box>
          </Box>
          <hr style={{ marginBottom: "1rem" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "row", xs: "column" },
              justifyContent: "space-between",
              gap: "3rem",
            }}
          >
            <Box sx={{ width: { md: "50%", sm: "50%", xs: "100%" } }}>
              <AddressForm
                formData={checkoutBillingData}
                setFormData={setCheckoutBillingData}
                data={userAddressListReducer?.billing_address}
                handleChange={handleBillingChange}
                isCheckout
                none
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) => handleChangeCheckbox(e)}
                />
                <Typography sx={{ fontWeight: "bold" }}>
                  Deliver to a different address?
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  <Typography>Order notes (optional)</Typography>
                  <textarea
                    style={{
                      width: "100%",
                      resize: "none",
                      padding: "10px",
                    }}
                    rows={10}
                    placeholder="Notes about your order, e.g. special notes for delivery."
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </Box>
              </Box>
              {agreement ? (
                <AddressForm
                  title="Shipping Details"
                  data={userAddressListReducer?.shipping_address}
                  formData={checkoutShippingData}
                  setFormData={setCheckoutShippingData}
                  handleChange={handleShippingChange}
                  isCheckout
                  none
                />
              ) : (
                ""
              )}
            </Box>
            <Box sx={{ width: { md: "40%", sm: "40%", xs: "1005" } }}>
              <Typography sx={{ fontSize: "1.8rem", fontWeight: "700" }}>
                Orders
              </Typography>
              <Box
                sx={{
                  // width: { xs: "100%", md: "30%" },
                  borderLeft: "1px solid #e1e1e1",
                  // padding: "1rem 1.5rem",
                }}
              >
                <Box
                  sx={{
                    maxHeight: "50vh",
                    // overflow: "hidden",
                    overflow: "auto",
                    // scrollBehavior: "auto",
                  }}
                >
                  <Table>
                    <TableHead sx={{ background: "#f5f5f5" }}>
                      <TableRow>
                        {headings?.map((item: any, index: number) => {
                          return (
                            <TableCell
                              key={index}
                              sx={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                fontFamily: "sans-serif",
                              }}
                            >
                              {item}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartItems?.map((item: any, index: any) => {
                        const getShortDesc = JSON.parse(
                          item?.product?.short_description
                        );
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  // alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    // border: "1px solid",
                                  }}
                                >
                                  <img
                                    src={item?.product?.base_image_url}
                                    alt={item?.product?.name}
                                    width={50}
                                    loading="lazy"
                                  />
                                  <Link
                                    to={`/products/${item?.product?.name
                                      .toLowerCase()
                                      .split(" ")
                                      .filter((elem: any) => elem !== "–")
                                      .join("-")}/`}
                                    state={{
                                      id: item?.product?.id,
                                    }}
                                    onClick={goToTop}
                                  >
                                    {/* <Link
                                    to={`/singleProduct/${item?.product?.id}`}
                                  > */}
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                        fontFamily: "sans-serif",
                                        ml: 2,
                                      }}
                                      variant="body1"
                                    >
                                      {item?.product?.name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        // fontWeight: "bold",
                                        fontSize: "0.8rem",
                                        fontFamily: "sans-serif",
                                        // color: "#EC0A90",
                                        ml: 2,
                                        textDecoration: "none",
                                      }}
                                      variant="body1"
                                    >
                                      Price: ${item?.product?.base_price}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        // fontWeight: "bold",
                                        fontSize: "0.8rem",
                                        fontFamily: "sans-serif",
                                        // color: "#EC0A90",
                                        ml: 2,
                                        textDecoration: "none",
                                      }}
                                      variant="body1"
                                    >
                                      Quantity: {item?.qty}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {getShortDesc?.map(
                                        ({ key: title, value }: any) => {
                                          return (
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                // fontWeight: "bold",
                                                fontSize: "0.8rem",
                                                fontFamily: "sans-serif",
                                                color: "#EC0A90",
                                                ml: 2,
                                                textDecoration: "none",
                                                whiteSpace: "nowrap",
                                              }}
                                              variant="body1"
                                            >
                                              <span style={{ color: "#000" }}>
                                                {title}
                                                {" : "}
                                              </span>
                                              <span>{value}</span>
                                            </Typography>
                                          );
                                        }
                                      )}
                                    </Box>
                                  </Link>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                fontFamily: "sans-serif",
                                // ml: 2,
                              }}
                            >
                              {`$${(
                                (item?.product?.base_price).toFixed(2) *
                                item?.qty
                              ).toFixed(2)}`}
                              <br></br>
                              {/* (incl. tax) */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
                <Table>
                  {/* <TableRow>
                    <TableCell>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "sans-serif",
                          ml: 2,
                        }}
                        variant="body1"
                      >
                        Subtotal
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography>
                        {`$${subTotalAmount?.toFixed(2)}`}
                      </Typography>
                    </TableCell>
                  </TableRow> */}

                  <TableRow>
                    <TableCell>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "sans-serif",
                          ml: 2,
                        }}
                        variant="body1"
                      >
                        SUBTOTAL
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        fontFamily: "sans-serif",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {`$${subTotalAmount?.toFixed(2)}`}
                    </TableCell>
                  </TableRow>
                  {couponAvailable && (
                    <TableRow>
                      <TableCell>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            fontFamily: "sans-serif",
                            ml: 2,
                          }}
                          variant="body1"
                        >
                          Coupon Applied
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography color="#EC0A90">
                          {`$${discount?.toFixed(2)}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "sans-serif",
                          ml: 2,
                        }}
                        variant="body1"
                      >
                        Shipping
                      </Typography>
                    </TableCell>

                    <TableCell>Free shipping</TableCell>
                  </TableRow>
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        fontFamily: "sans-serif",
                        ml: 2,
                      }}
                      variant="body1"
                    >
                      TOTAL
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      fontFamily: "sans-serif",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`$${discountedAmount?.toFixed(2)}`}
                    <br></br>
                    (includes{" "}
                    <Typography color="#EC0A90">
                      {`$${getProductTax(cartItems)}`}
                    </Typography>{" "}
                    Tax)
                  </TableCell>
                </Table>
              </Box>
              <Box sx={{ paddingTop: "1rem" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
                  Payment Method
                </Typography>
                <Box sx={{ border: "1px solid", padding: "20px " }}>
                  <form ref={payMethodForm}>
                    {paymentData?.map(
                      ({ method, value, description }: any, index: any) => {
                        return (
                          <div>
                            {discountedAmount === 0 && method === "Stripe" ? (
                              ""
                            ) : (
                              <label
                                htmlFor={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "12px",
                                  margin: "0px",
                                }}
                                onClick={() =>
                                  isDisabled && alert("Pls first fill the form")
                                }
                              >
                                <input
                                  type="radio"
                                  name="payMode"
                                  value={value}
                                  id={index}
                                  onChange={(e) => handleShowPayMode(e, index)}
                                  disabled={isDisabled && isStripeDisable}
                                />
                                <Typography
                                  style={{
                                    color: isDisabled ? "#afafaf" : "#000",
                                  }}
                                >
                                  {method}
                                </Typography>
                              </label>
                            )}
                            {isShowId === index && (
                              <Typography
                                sx={{
                                  transition: "0.6s ease-out",
                                  padding: "10px",
                                  backgroundColor: "#ececec",
                                }}
                              >
                                {description}
                              </Typography>
                            )}
                          </div>
                        );
                      }
                    )}
                  </form>
                </Box>

                <Typography sx={{ margin: "10px 0" }}>
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our privacy policy.
                </Typography>

                {stripe ? (
                  <Box sx={{ width: "100%" }}>
                    {/* <BasicButton
                          placeholder="Pay With Card"
                          width="100%"
                          onClick={handlePlaceOrder}
                        /> */}
                    <Box>
                      <StripeCheckout
                        token={onToken}
                        name="Print Calgary Payment Gateway"
                        currency="CAD"
                        amount={discountedAmount * 100}
                        stripeKey="pk_test_51NAQkzSAcGl6xfgP3cYXJ6eUfR3Dxr65kZbe7NMP68l4SFrNasW3lwyOrMCg6ONfFaNwKVbKNKXMlUWMdAsaDuKT00pdrGZEOy"
                        triggerEvent="onClick"
                        // @ts-ignore
                        // label={cartOrderId?.toString()}
                      />
                    </Box>
                  </Box>
                ) : (
                  <BasicButton
                    placeholder="Place Order"
                    width="100%"
                    onClick={(e: any) => {
                      handlePlaceOrder(e);
                      // goToTop();
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </section>
      </ContainerBox>
    </>
  );
};
