import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContainerBox } from "../../components/Containers/ContainerBox";
import { SectionHeading } from "../../components/SectionHeading/SectionHeading";
import { Box } from "@mui/material";
import { goToTop } from "utils/goToTop";
import { DataNotFound } from "components/Containers/DataNotFound";

type Props = {};

export const ProductList = (props: Props) => {
  // const loading = useSelector(
  //   (state: any) => state?.allProductListReducer?.loading
  // );
  const productList = useSelector(
    (state: any) => state?.allProductListReducer?.listArr?.results
  );

  const columnCount = 5;
  const itemsPerColumn = Math.ceil(productList?.length / columnCount);
  const columns = [];

  for (let i = 0; i < columnCount; i++) {
    const startIndex = i * itemsPerColumn;
    const endIndex = Math.min(startIndex + itemsPerColumn, productList?.length);
    const columnItems = productList?.slice(startIndex, endIndex);

    columns.push(
      <div key={i} className="column">
        {columnItems?.map((item: any, index: any) => {
          return (
            <>
              <li
                key={index}
                style={{
                  paddingBottom: "5px",
                  // fontWeight: "500",
                  fontSize: "16 px",
                  color: "#131111",
                }}
              >
                {/* <Link to={`singleProduct/${item.id}`} onClick={goToTop}> */}
                <Link
                  // here Ascii key is sent so we have to filter using that
                  to={`/products/${item?.name
                    .toLowerCase()
                    .split(" ")
                    .filter((elem: any) => elem !== "–")
                    .join("-")}`}
                  state={{
                    id: item?.id,
                  }}
                  onClick={goToTop}
                >
                  {item?.name}
                </Link>
              </li>
            </>
          );
        })}
      </div>
    );
  }

  return (
    <section>
      <ContainerBox>
        <SectionHeading heading="Products" marginBottom="0px" />
        {productList?.length > 0 ? (
          <Box
            sx={{
              listStyleType: "none",
              // columnCount: "5",
              padding: "50px 0",
              textTransform: "capitalize",
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(5, 1fr)",
              },
            }}
          >
            {columns?.map((column) => column)}
          </Box>
        ) : (
          <DataNotFound />
        )}
      </ContainerBox>
    </section>
  );
};
