import React, { useState, Dispatch, useEffect } from "react";
import { IconButton } from "@mui/material";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import {
  getWishlistAction,
  patchWishlistAction,
  resetWishList,
} from "redux/Actions/wishlist.actions";
import { useDispatch, useSelector } from "react-redux";

type Props = {
  productId: any;
  isWishlisted: boolean;
};

export const WishlistItem = ({ productId, isWishlisted }: Props) => {
  const theme = useTheme();
  const [isWished, setIsWished] = useState(isWishlisted && isWishlisted);
  const [payload, setPayload] = useState({});

  const wishlistSelector = useSelector(
    (state: any) => state?.patchWishlistReducer?.listArr
  );

  const dispatch: Dispatch<any> = useDispatch();
  const handleWishClick = () => {
    if (isWished) {
      setPayload({
        product: productId,
        type: "drop",
      });
    } else {
      setPayload({
        product: productId,
        type: "add",
      });
    }
    setIsWished((prevIsWished: any) => !prevIsWished);
  };

  useEffect(() => {
    setIsWished(isWishlisted);
  }, [isWishlisted]);

  useEffect(() => {
    if (wishlistSelector?.success === true) {
      dispatch(getWishlistAction());
      // dispatch(singleProductAction(productId));
      dispatch(resetWishList());
    }
  }, [wishlistSelector, isWished, dispatch]);

  useEffect(() => {
    if (Object?.keys(payload)?.length !== 0) {
      dispatch(patchWishlistAction(payload));
    }
  }, [payload, dispatch]);

  return (
    <IconButton
      disableFocusRipple
      disableRipple
      onClick={handleWishClick}
      sx={{
        color: isWished ? theme?.palette?.primary?.main : "",
      }}
    >
      {isWished ? <Favorite /> : <FavoriteBorder />}
    </IconButton>
  );
};
