import { Box, useTheme } from "@mui/material";
import React, { Dispatch, useEffect, useState } from "react";
import { ContainerBox, DataNotFound } from "components";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import { Cards } from "./Cards";
import { Grid, Typography, Icon } from "@mui/material";
import WindowIcon from "@mui/icons-material/Window";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { allProductListAction } from "redux/Actions/product.actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingComponent } from "components";
import { goToTop } from "utils/goToTop";
type Props = {
  productList?: any | undefined;
  sliceData?: any | undefined;
  maxWidth: any | undefined;
  height?: any | undefined;
  pagination?: any | undefined;
};

export const ProductCardList = (props: Props) => {
  const theme = useTheme();
  // const [posts, setPosts] = useState([]);
  const [sortValue, setSortValue] = useState("default");

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(12);
  const dispatch: Dispatch<any> = useDispatch();
  const loading = useSelector(
    (state: any) => state?.allProductListReducer?.loading
  );
  const productList = useSelector(
    (state: any) => state?.allProductListReducer?.listArr?.results
  );

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const dataCurentPosts = productList?.slice(indexOfFirstPost, indexOfLastPost);

  const handleSortOption = (selectedOption: string) => {
    // dispatch(allProductListAction(selectedOption));
    setSortValue(selectedOption);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      // setPosts(productList);
    };
    fetchPosts();
  }, [productList]);

  const [isListView, setIsListView] = useState(true);

  const handleViewChange = (view: any) => {
    setIsListView(view === "list");
  };

  const paginate = (pageNumber: any) => {
    goToTop();
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    if (sortValue !== undefined) {
      dispatch(allProductListAction(sortValue));
    }
  }, [sortValue, dispatch]);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : productList?.length > 0 ? (
        <ContainerBox maxWidth="1300px">
          {props?.pagination === "true" ? (
            <Box
              sx={{
                margin: "2rem 0",
                display: { xs: "none", lg: "flex" },
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Rajdhani",
                  fontWeight: "500",
                  fontSize: "1.1rem",
                }}
              >
                Showing results {indexOfFirstPost + 1} - {indexOfLastPost} from{" "}
                {productList?.length} results
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center ",
                  gap: "10px",
                }}
              >
                <Typography>Sort By :</Typography>
                <select
                  onChange={(e) => handleSortOption(e.target.value)}
                  style={{
                    width: "auto",
                    lineHeight: "40px",
                    border: "1px solid #ccc",
                    outline: "none",
                    padding: "10px",
                  }}
                  value={sortValue}
                >
                  <option value="default">Default Sorting</option>
                  <option value="low_to_high">
                    Sort by price: low to high
                  </option>
                  <option value="high_to_low">
                    Sort by price: high to low
                  </option>
                </select>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    sx={{
                      color: isListView ? theme.palette.primary.main : "#ccc",
                      cursor: "pointer",
                    }}
                    onClick={() => handleViewChange("list")}
                  >
                    <WindowIcon />
                  </Icon>
                  <Icon
                    sx={{
                      color: isListView ? "#ccc" : theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={() => handleViewChange("grid")}
                  >
                    <TableRowsIcon />
                  </Icon>
                </Box>
              </Box>
            </Box>
          ) : (
            ""
          )}
          <Box sx={{}}>
            {isListView ? (
              <Grid container spacing={2}>
                {dataCurentPosts
                  ?.slice(0, props?.sliceData)
                  ?.map((value: any, index: any) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <Cards
                            height={props?.height}
                            key={index}
                            Products={value}
                            maxWidth={props?.maxWidth}
                          />
                        </Grid>
                      </React.Fragment>
                    );
                  })}
              </Grid>
            ) : (
              <>
                {dataCurentPosts
                  ?.slice(0, props?.sliceData)
                  ?.map((value: any, index: any) => {
                    return (
                      <React.Fragment key={index}>
                        <Cards
                          height={props?.height}
                          key={index}
                          Products={value}
                          flexDirection="row"
                          isList
                          maxWidth={props?.maxWidth}
                        />
                      </React.Fragment>
                    );
                  })}
              </>
            )}
          </Box>
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
              width: "100%",
            }}
          >
            {props?.pagination ? (
              <PaginationComponent
                totalPosts={productList?.length}
                currentPage={currentPage}
                postsPerPage={postsPerPage}
                paginate={paginate}
              />
            ) : (
              " "
            )}
          </Box>
        </ContainerBox>
      ) : (
        <DataNotFound />
      )}
    </>
  );
};
