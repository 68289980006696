import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  item?: any;
  onChange?: any;
  counter?: any;
  setCounter?: any;
};

export const CartCounter = ({ item, onChange, counter, setCounter }: Props) => {
  const [counterState, setCounterState] = useState(0);
  const [checkState, setCheckState] = useState(false);
  const [getId, setId] = useState(null);

  const onHandleChange = (value: any, id: any) => {
    setCounter(value);
    setCounterState(value);
    setId(id);
    setCheckState(true);
  };

  const increase = (count: any, id: any) => {
    if (count <= item?.product?.max_qty) {
      onHandleChange(count, id);
    }
  };

  const decrease = (count: any, id: any) => {
    if (count >= item?.product?.min_qty) {
      onHandleChange(count, id);
    }
  };

  useEffect(() => {
    if (checkState === true) {
      onChange(counterState, getId);
      setCheckState(false);
    }
  }, [checkState, counterState, getId, onChange]);

  useEffect(() => {
    setCounterState(item?.qty);
  }, [item]);

  return (
    <>
      <Box
        className="counter"
        sx={{
          border: "1px solid #D0D0D0",
          width: "150px",
          height: "100%",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <Box
          className="btn__container"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box onClick={() => decrease(counterState - 1, item?.id)}>
            <RemoveIcon
              sx={{
                border: "none",
                backgroundColor: "#fff",
                fontSize: "16px",
                cursor: "pointer",
                opacity: item?.qty === item?.product?.min_qty ? 0.5 : 1,
              }}
            />
          </Box>

          <input
            style={{
              border: "none",
              fontWeight: "bold",
              fontSize: "16px",
              width: "70%",
              textAlign: "center",
            }}
            type="number"
            value={counterState}
            // value={item?.qty}
            // onChange={(e) => onHandleChange(e.currentTarget.value, item?.id)}
            // onChange={(e) => setQuantity(parseInt(e.currentTarget.value))}
          />

          <Box onClick={() => increase(counterState + 1, item?.id)}>
            <AddIcon
              sx={{
                border: "none",
                backgroundColor: "#fff",
                fontSize: "16px",
                cursor: "pointer",
                opacity: item?.qty === item?.product?.max_qty ? 0.5 : 1,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
