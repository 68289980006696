import { promotionList } from "redux/restApi";
import { PROMOTION_TYPE } from "./action.types/action.types";

export const requestPromotion = () => {
  return {
    type: PROMOTION_TYPE.REQUEST,
  };
};

export const successPromotion = (payload: any) => {
  return {
    type: PROMOTION_TYPE.SUCCESS,
    payload,
  };
};

export const faliurePromotion = (payload: any) => {
  return {
    type: PROMOTION_TYPE.FALIURE,
    payload,
  };
};

export const promotionAction = () => {
  return (dispatch: any) => {
    dispatch(requestPromotion());
    promotionList()
      .then((response) => {
        if(response?.status === 200 && response){
          dispatch(successPromotion(response));
        }
      })
      .catch((error) => dispatch(faliurePromotion(error)));
  };
};
