import { Box, Icon, Typography } from "@mui/material";
import React from "react";

type Props = {
  singleBlogData?: any;
};
const URL = process.env.REACT_APP_API_URL;

export const GetCommentBox = ({ singleBlogData }: Props) => {
  return (
    <>
      {singleBlogData?.comments?.map((comment: any, id: any) => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                padding: "10px 20px",
                lineHeight: "23px",
                //   background: "#f5f5f5",
                // marginBottom: "20px",
                gap: "25px",
                width: { xs: "100%", md: "75%" },
                // alignItems: "start",
              }}
            >
              <Box
                borderRadius="50%"
                // overflow="hidden"
                width="80px"
                height="80px"
              >
                <img
                  // src="https://secure.gravatar.com/avatar/173a8e01aacd06d39e660ed526c4b086?s=90&d=mm&r=g"
                  src={`${URL}${singleBlogData?.auther?.image}`}
                  alt=""
                  loading="lazy"
                  height="100%"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                  gap: "12px",
                }}
              >
                <Typography
                  sx={{
                    letterSpacing: "0.7px",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  {localStorage.getItem("displayName")}
                </Typography>
                <Typography
                  sx={{
                    letterSpacing: "0.7px",
                    textTransform: "capitalize",
                    //   fontWeight: "bold",
                    fontSize: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  Your comment is awaiting moderation.
                </Typography>
                <Typography
                  sx={{
                    // fontFamily: "Open Sans",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // color: theme.palette.primary.main,
                  }}
                >
                  <Typography>
                    {comment?.comment}
                    <Icon
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    ></Icon>
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </>
        );
      })}
    </>
  );
};
