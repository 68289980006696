import { Box } from "@mui/material";
import React from "react";

type Props = {};

export const DataNotFound = (props: Props) => {
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <img
        width="50%"
        src="https://cdn.dribbble.com/users/1348407/screenshots/17444029/media/cd6bbce5b665ef75c2f7c94f9e6d118c.png"
        alt="/"
      />
    </Box>
  );
};
