import { getWishlist, patchWishlist } from "redux/restApi";
import { GET_WISHLIST_TYPE, PATCH_WISHLIST_TYPE } from "./action.types/action.types";
import { errorToaster, successToaster } from "utils/toaster";

export const requestwishlist = () => {
  return {
    type: GET_WISHLIST_TYPE.REQUEST,
  };
};

export const successwishlist = (payload: any) => {
  return {
    type: GET_WISHLIST_TYPE.SUCCESS,
    payload,
  };
};

export const faliurewishlist = (payload: any) => {
  return {
    type: GET_WISHLIST_TYPE.FALIURE,
    payload,
  };
};

export const getWishlistAction = () => {
  return (dispatch: any) => {
    dispatch(requestwishlist());
    getWishlist()
      .then((response) => {
        dispatch(successwishlist(response));
      })
      .catch((error) => dispatch(faliurewishlist(error)));
  };
};




export const patchRequestWishlist = (data:any) => ({
  type: PATCH_WISHLIST_TYPE.REQUEST,
  payload: data,
});

export const patchSuccessWishlist = (response:any) => ({
  type: PATCH_WISHLIST_TYPE.SUCCESS,
  payload: response,
});

export const patchFailureWishlist = (error:any) => ({
  type: PATCH_WISHLIST_TYPE.FALIURE,
  payload: error,
});

export const patchWishlistAction = (data :any) => {
  return (dispatch:any) => {
    dispatch(patchRequestWishlist(data));

    patchWishlist(data)
      .then((response) => {
        if(response?.status === 200 && response){
          dispatch(patchSuccessWishlist(response?.data));
          successToaster(response?.data?.message); 
        
        }
      })
      .catch((error) => {
        dispatch(patchFailureWishlist(error));
        errorToaster(error?.data?.message);  

      });
  };
};


export const resetWishList = () => {
  return (dispatch:any) => {
          dispatch(patchSuccessWishlist([]));
    
    
  };
};