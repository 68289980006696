import React from "react";
import { Box } from "@mui/material";
import SwiperSlider from "./SwiperSlider";
import "./SwiperSlider.css";
import { SectionHeading } from "components/SectionHeading/SectionHeading";
import { Logos } from "utils/constant";

type Props = {};

const SwiperContainer = (props: Props) => {
  return (
    <>
      <Box>
        <SectionHeading heading="Some of our clients..." />
        <Box
          className="clientSwiper"
          sx={{ width: "90%", margin: "0 auto", padding: "20px 0" }}
        >
          <SwiperSlider data={Logos} />
        </Box>
      </Box>
    </>
  );
};

export default SwiperContainer;
