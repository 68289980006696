export const GET_USER_ADDRESS_TYPE = {
    REQUEST: "REQUEST_GET_USER_ADDRESS",
    SUCCESS: "SUCCESS_GET_USER_ADDRESS",
    FALIURE: "FALIURE_GET_USER_ADDRESS",
  };


  export const PATCH_USER_ADDRESS_TYPE = {
    REQUEST: 'PATCH_REQUEST_USER_ADDRESS',
    SUCCESS: 'PATCH_SUCCESS_USER_ADDRESS',
    FAILURE: 'PATCH_FAILURE_USER_ADDRESS',
  };


  export const PATCH_USER_PROFILE_TYPE = {
    REQUEST: 'PATCH_REQUEST_USER_PROFILE',
    SUCCESS: 'PATCH_SUCCESS_USER_PROFILE',
    FAILURE: 'PATCH_FAILURE_USER_PROFILE',
  };


  export const GET_USER_PROFILE_TYPE = {
    REQUEST: "REQUEST_GET_USER_PROFILE",
    SUCCESS: "SUCCESS_GET_USER_PROFILE",
    FALIURE: "FALIURE_GET_USER_PROFILE",
  };
  