import axios from "axios";

const url = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: url,
  headers: {
    "content-type": "application/json",
  },
});

// instance.interceptors.request.use(
//   (options) => {
//     // eslint-disable-next-line dot-notation
//     options.headers['Authorization'] = `Bearer ${localStorage.getItem('guesttoken')}`;

//     return options;
//   },
//   (error) => Promise.reject(error)
// );

export default instance;
 