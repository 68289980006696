import { BasicButton } from "components/Button";
import { BasicInput } from "components/Input/BasicInput";
import { Box, InputAdornment, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  couponRemoved,
  getCouponVerificationAction,
} from "redux/Actions/product.actions";
// import { IconButton } from "material-ui";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  display?: any;
  className?: any;
  checkout?: boolean;
  heading?: string;
};

export const StyledCoupon = ({
  display,
  className,
  checkout,
  heading,
}: Props) => {
  const dispatch = useDispatch();
  const [coupon, setCoupon]: any = useState("");
  const getCart = useSelector(
    (state: any) => state?.getCartReducer?.listArr?.data?.results
  );
  const couponUpdate = useSelector((state: any) => state?.getCouponUpdated);
  const verifyCoupon = () => {
    const cartIds = getCart.map((item: any) => item?.product?.id);
    const amount = getCart?.reduce(
      (acc: any, item: any) => acc + item.product.base_price * item.qty,
      0
    );
    const payload = {
      coupon,
      amount,
      product: cartIds,
    };
    //@ts-ignore
    dispatch(getCouponVerificationAction(payload));
  };
  const isClear = () => {
    setCoupon("");
    dispatch(couponRemoved());
    sessionStorage.removeItem("couponValue");
    sessionStorage.removeItem("coupon");
  };

  const changeCoupon = (e: any) => {
    sessionStorage.setItem("couponValue", e?.target?.value);
    setCoupon(e?.target?.value);
    // dispatch(couponUpdatedTrue())
  };

  useEffect(() => {
    if (sessionStorage?.getItem("coupon")) {
      if (sessionStorage?.getItem("couponValue")) {
        setCoupon(sessionStorage?.getItem("couponValue"));
      }
    }
  }, [coupon, couponUpdate?.updated]);

  return (
    <Box
      sx={{
        display: display,
        border: checkout ? "1px solid #d0d0d0" : "none",
        backgroundColor: checkout ? "#f5f5f5" : "#fff",
        color: "#555",
        margin: checkout ? "20px 0 30px" : "0",
        padding: checkout ? "25px 30px" : "0",
        width: {
          md: checkout ? "100%" : "50%",
          sm: checkout ? "100%" : "50%",
          xs: checkout ? "100%" : "100%",
        },
      }}
      className={className}
    >
      <Box sx={{ marginBottom: "20px" }}>
        {heading ? (
          <Typography sx={{ fontSize: "1.2rem", fontWeight: "400" }}>
            {heading}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: "600" }}>
            Coupon Apply
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <BasicInput
          value={coupon}
          InputProps={{
            endAdornment: coupon && (
              <InputAdornment position="end" onClick={() => isClear()}>
                {/* <IconButton> */}
                <CloseIcon sx={{ cursor: "pointer" }} fontSize="small" />
                {/* </IconButton> */}
              </InputAdornment>
            ),
          }}
          placeholder={"Enter coupon code here..."}
          size="100%"
          onChange={changeCoupon}
        />
        <BasicButton
          placeholder={"Apply"}
          height="42px"
          width="170px"
          onClick={verifyCoupon}
        />
      </Box>
    </Box>
  );
};
