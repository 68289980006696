import React, { useState, useRef, Dispatch, useEffect } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useDispatch } from "react-redux";
import { fileUploadAction } from "redux/Actions/fileUpload.action";

export const FileUpload: any = ({ cartId }: any) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const fileInputRef = useRef(null);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const files = Array.from(event.dataTransfer.files);
    if (files.length > 0) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files?.length > 0) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const handleBoxClick = () => {
    //@ts-ignore
    fileInputRef.current.click();
  };

  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (selectedFiles?.length) {
      dispatch(fileUploadAction(selectedFiles, cartId));
    }
  }, [cartId, dispatch, selectedFiles]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "2px dashed #ccc",
          borderRadius: "4px",
          padding: "16px",
          cursor: "pointer",
          margin: "10px 0",
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleBoxClick}
      >
        <FileDownloadIcon />
        <input
          type="file"
          accept=".jpg,.png,.pdf"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <button onClick={() => handleRemoveFile(index)}>Remove</button>
            </li>
          ))}
        </ul>
        {selectedFiles.length === 0 && (
          <span
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Drag and drop files or click to select files
          </span>
        )}
      </div>
      {selectedFiles.length > 0 && (
        <div>
          <h2>Selected Files:</h2>
          <ul style={{}}>
            {selectedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
