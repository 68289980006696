import { Box, Typography } from "@mui/material";
import { BreadCrumbs, ContainerBox } from "components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";

export const EmailSentVerify = () => {
  const showMessageSelector = useSelector(
    (state: any) => state?.lostPasswordReducer?.listArr?.data
  );
  return (
    <>
      <Box sx={{ background: "#f5f5f5", padding: "15px 0", marginTop: "20px" }}>
        <ContainerBox>
          <BreadCrumbs link="Page" />
        </ContainerBox>
      </Box>

      <Box sx={{ padding: "10px" }}>
        <ContainerBox>
          <Box sx={{ marginLeft: "10px" }}>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px 0",
              }}
            >
              <CheckCircleIcon sx={{ color: "green" }} />
              <Typography sx={{ fontSize: "1.3rem", fontWeight: "600" }}>
                {showMessageSelector?.message}
              </Typography>
            </Box>
            <Typography
              sx={{ fontSize: "1.3rem", fontWeight: 500, marginBottom: "14px" }}
            >
              A password reset email has been sent to the email address on file
              for your account, but may take several minutes to show up in your
              inbox. Please wait at least 10 minutes before attempting another
              reset.
            </Typography>
          </Box>
        </ContainerBox>
      </Box>
    </>
  );
};

// export default EmailSentVerify;
