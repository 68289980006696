import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import store from "./store";
import { LoadingComponent } from "components";
import { theme } from "theme";
const App = lazy(() =>
  import("./App").then(({ App }) => ({
    default: App,
  }))
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback={<LoadingComponent />}>
          <App />
        </Suspense>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
);
