import {
  GET_ORDER_TYPE,
  ORDER_TYPE,
  PLACE_ORDER_TYPE,
  SINGLE_ORDER_TYPE,
  UPDATE_CART_PRODUCT_QUANTITY_TYPE,
} from "redux/Actions/action.types/order.types";

const initialState = {
  listArr: [],
  loading: false,
};

export const orderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ORDER_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case ORDER_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    case "RESET_POST_ORDER":
      return {
        listArr: [],
      };
    default:
      return state;
  }
};

export const getorderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ORDER_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case GET_ORDER_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getSingleOrderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SINGLE_ORDER_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SINGLE_ORDER_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case SINGLE_ORDER_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const placeOrderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PLACE_ORDER_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PLACE_ORDER_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case PLACE_ORDER_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// update basket

export const updateCartProductQuantityReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_CART_PRODUCT_QUANTITY_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CART_PRODUCT_QUANTITY_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case UPDATE_CART_PRODUCT_QUANTITY_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
      case UPDATE_CART_PRODUCT_QUANTITY_TYPE.RESET:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
