import { successToaster } from "utils/toaster";
import { allBlogsList, postBlogComments, singleBlogList } from "../restApi";
import {
  ALL_BLOGS_LIST_TYPE,
  POST_BLOG_COMMENTS,
  SINGLE_BLOG_TYPE,
} from "./action.types/action.types";

export const requestBlogsList = () => {
  return {
    type: ALL_BLOGS_LIST_TYPE.REQUEST,
  };
};

export const successBlogsList = (payload: any) => {
  return {
    type: ALL_BLOGS_LIST_TYPE.SUCCESS,
    payload,
  };
};

export const faliureBlogsList = (payload: any) => {
  return {
    type: ALL_BLOGS_LIST_TYPE.FALIURE,
    payload,
  };
};

export const allBlogsListAction = (id?: any,categoryId?:any) => {
  return (dispatch: any) => {
    dispatch(requestBlogsList());
    allBlogsList(id ,categoryId)
      .then((response) => {
        if(response?.status === 200 && response){
          dispatch(successBlogsList(response));
        }
      })
      .catch((error) => dispatch(faliureBlogsList(error)));
  };
};

////////SINGLE BLOG ACTION

export const requestSingleBlog = () => {
  return {
    type: SINGLE_BLOG_TYPE.REQUEST,
  };
};

export const successSingleBlog = (payload: any) => {
  return {
    type: SINGLE_BLOG_TYPE.SUCCESS,
    payload,
  };
};

export const faliureSingleBlog = (payload: any) => {
  return {
    type: SINGLE_BLOG_TYPE.FALIURE,
    payload,
  };
};

export const singleBlogListAction = (id:any) => {
  return (dispatch: any) => {
    dispatch(requestSingleBlog());
    singleBlogList(id)
      .then((response) => {
        if(response?.status === 200 && response){
          dispatch(successSingleBlog(response));
        }
      })
      .catch((error) => dispatch(faliureSingleBlog(error)));
  };
};





export const requestBlogComments = () => {
  return {
    type: POST_BLOG_COMMENTS.REQUEST,
  };
};

export const successBlogComments = (payload: any) => {
  return {
    type: POST_BLOG_COMMENTS.SUCCESS,
    payload,
  };
};

export const faliureBlogComments = (payload: any) => {
  return {
    type: POST_BLOG_COMMENTS.FALIURE,
    payload,
  };
};

export const addCommentAction = (data: any) => {
  return (dispatch: any) => {
    dispatch(requestBlogComments());
    postBlogComments(data)
      .then((response) => {
        dispatch(successBlogComments(response));
        successToaster(response?.data?.message);
      })
      .catch((error) => dispatch(faliureBlogComments(error)));
  };
};
