import axios from "axios";
import {
  GET_ORDER_TYPE,
  ORDER_TYPE,
  PLACE_ORDER_TYPE,
  SINGLE_ORDER_TYPE,
  UPDATE_CART_PRODUCT_QUANTITY_TYPE,
} from "./action.types/order.types";
import { ORDER_API } from "redux/constantAPI";
import { errorToaster, successToaster } from "utils/toaster";
import {
  orderList,
  placeOrderList,
  singleOrderList,
  updateCartProductQuantity,
} from "redux/restApi";

export const requestGetOrder = () => {
  return {
    type: GET_ORDER_TYPE.REQUEST,
  };
};

export const successGetOrder = (payload: any) => {
  return {
    type: GET_ORDER_TYPE.SUCCESS,
    payload,
  };
};

export const faliureGetOrder = (payload: any) => {
  return {
    type: GET_ORDER_TYPE.FALIURE,
    payload,
  };
};

export const getOrderAction = () => {
  return (dispatch: any) => {
    dispatch(requestGetOrder());
    orderList()
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(successGetOrder(response));
        }
      })
      .catch((error) => dispatch(faliureGetOrder(error)));
  };
};

export const postOrderAction =
  (
    amount: any,
    tax: any,
    product: any,
    agreement: boolean,
    payment_mode: any,
    description: any,
    shipping_address: any,
    billing_address: any,
    stripePaymentRes?: any,
    isStripe?: boolean
  ) =>
  async (dispatch: any) => {
    
    const getCoupon = sessionStorage.getItem("couponValue") || "";
    let token = localStorage.getItem("guesttoken");
    let checkout_id = sessionStorage.getItem("checkoutId");
    //? this is a temporary fix as country name is always going to be static
    shipping_address && (shipping_address.country_name = "Canada");
    billing_address && (billing_address.country_name = "Canada");

    //? this is another temporary fix for province as it is being picked from api instead of input value
    if (sessionStorage.getItem("province")) {
      billing_address &&
        (billing_address.province = sessionStorage?.getItem("province"));
      shipping_address &&
        (shipping_address.province = sessionStorage?.getItem("province"));
    }

    let payment_response;
    if (isStripe) {
      payment_response = stripePaymentRes;
    }
    payment_response = {};

    if (localStorage.getItem("logtoken")) {
      token = localStorage.getItem("logtoken");
    } else {
      token = localStorage.getItem("guesttoken");
    }
    const payload = {
      amount,
      tax,
      checkout_id,
      payment_response,
      product,
      deliver_to_different_address: agreement,
      payment_mode,
      description,
      shipping_address,
      billing_address,
      coupon: getCoupon,
    };
    dispatch({
      type: ORDER_TYPE.REQUEST,
      payload,
    });
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${ORDER_API}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // const navigate = useNavigate();
        const details = response?.data;
        dispatch({ type: ORDER_TYPE.SUCCESS, payload: { details } });
        // navigate("/orders");
        successToaster(details.message);
        sessionStorage.removeItem("couponValue");
        sessionStorage.removeItem("coupon");
      } else {
        const error = response.data;

        dispatch({ type: ORDER_TYPE.FALIURE, payload: { error } });

        // errorToaster(error.response.data.message);
      }
    } catch (error: any) {
      dispatch({ type: ORDER_TYPE.FALIURE, payload: { error: error.message } });
      errorToaster(error.response.data.message);
    }
  };

export const resetPostOrder = () => {
  return async (dispatch: any) => {
    dispatch({ type: "RESET_POST_ORDER" });
  };
};

// Single Order Get
export const requestSingleOrder = () => {
  return {
    type: SINGLE_ORDER_TYPE.REQUEST,
  };
};

export const successSingleOrder = (payload: any) => {
  return {
    type: SINGLE_ORDER_TYPE.SUCCESS,
    payload,
  };
};

export const faliureSingleOrder = (payload: any) => {
  return {
    type: SINGLE_ORDER_TYPE.FALIURE,
    payload,
  };
};

export const singleOrderListAction = (id: any) => {
  return (dispatch: any) => {
    dispatch(requestSingleOrder());
    singleOrderList(id)
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(successSingleOrder(response));
        }
      })
      .catch((error) => dispatch(faliureSingleOrder(error)));
  };
};

//  Order Patch API

export const requestPlaceOrder = () => {
  return {
    type: PLACE_ORDER_TYPE.REQUEST,
  };
};

export const successPlaceOrder = (payload: any) => {
  return {
    type: PLACE_ORDER_TYPE.SUCCESS,
    payload,
  };
};

export const faliurePlaceOrder = (payload: any) => {
  return {
    type: PLACE_ORDER_TYPE.FALIURE,
    payload,
  };
};

export const placeOrderListAction = (id: any) => {
  return (dispatch: any) => {
    dispatch(requestPlaceOrder());
    placeOrderList(id)
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(successPlaceOrder(response));
        }
      })
      .catch((error) => dispatch(faliurePlaceOrder(error)));
  };
};

// Update Basket
export const requestUpdateCartProductQuantity = () => {
  return {
    type: UPDATE_CART_PRODUCT_QUANTITY_TYPE.REQUEST,
  };
};

export const successUpdateCartProductQuantity = (payload: any) => {
  return {
    type: UPDATE_CART_PRODUCT_QUANTITY_TYPE.SUCCESS,
    payload,
  };
};

export const faliureUpdateCartProductQuantity = (payload: any) => {
  return {
    type: UPDATE_CART_PRODUCT_QUANTITY_TYPE.FALIURE,
    payload,
  };
};

export const resetUpdateCartProductQuantity = (payload: any) => {
  return {
    type: UPDATE_CART_PRODUCT_QUANTITY_TYPE.RESET,
    payload,
  };
};

export const updateCartProductQuantityListAction = (id: any) => {
  return (dispatch: any) => {
    dispatch(requestUpdateCartProductQuantity());
    updateCartProductQuantity(id)
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(successUpdateCartProductQuantity(response));
        }
      })
      .catch((error) => dispatch(faliureUpdateCartProductQuantity(error)));
  };
};


export const resetUpdateCartProductQuantityListAction = () => {
  return (dispatch: any) => {
    dispatch(resetUpdateCartProductQuantity([]));
  };
};