import { FAQ_TYPE } from "redux/Actions/action.types/action.types";


const initialState = {
  listArr: [],
  loading: true,
};

export const faqReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FAQ_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FAQ_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload.data,
        loading: false,
      };
    case FAQ_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
