import React, { useEffect, Dispatch } from "react";
import { Box } from "@mui/material";
import {
  ContainerBox,
  LoadingComponent,
  MediaCard,
  SimpleAccordion,
} from "components";
import Yelp from "assets/image/yelp.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import { goToTop } from "utils/goToTop";
import { useDispatch, useSelector } from "react-redux";
import { faqAction } from "redux/Actions/faq.actions";
// import { Icon } from "@material-ui/core";
import { FaqMediaCard } from "assets";

type Props = {};

export const FAQs = (props: Props) => {
  const faqListResult = useSelector((state: any) => state?.faqReducer?.listArr);
  const loading = useSelector((state: any) => state?.faqReducer?.loading);
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    dispatch(faqAction());
  }, [dispatch]);

  const faqList = faqListResult?.results;

  const iconList = [
    {
      icon: <FacebookIcon />,
      color: "#1b4e9b",
      link: "https://www.facebook.com/printcalgary",
    },
    {
      icon: <InstagramIcon />,
      color: "#262626",
      link: "https://www.instagram.com/printcalgary",
    },

    {
      icon: <img src={Yelp} alt="Yelp" width="49px" height="49px" />,
      color: "",
      link: `https://www.yelp.ca/biz/print-calgary-calgary-4`,
      name: "yelp",
    },
  ];
  return (
    <>
      <Box sx={{ marginBottom: "60px" }}>
        <MediaCard
          title="FAQ's"
          minHeight="400px"
          backgroundImage={FaqMediaCard}
        />
        <SimpleAccordion data={faqList} />
      </Box>
      {loading ? (
        <LoadingComponent />
      ) : (
        <section>
          <ContainerBox>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19109079.34684659!2d-116.75826397049038!3d54.22166111741308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0d03d337cc6ad9%3A0x9968b72aa2438fa5!2sCanada!5e0!3m2!1sen!2sin!4v1681365306584!5m2!1sen!2sin"
              width="100%"
              height="350"
              style={{ border: "0" }}
              loading="lazy"
              title="map"
            ></iframe>
          </ContainerBox>
          <section>
            <ContainerBox>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                {iconList?.map((item: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <Link to={item?.link} onClick={goToTop} target="_blank">
                        <Box
                          sx={{
                            background: item?.color,
                            padding: item?.name === "yelp" ? "" : ".8rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "2rem",
                            borderRadius: "5px",
                          }}
                        >
                          {item?.icon}
                        </Box>
                      </Link>
                    </React.Fragment>
                  );
                })}
              </Box>
            </ContainerBox>
          </section>
        </section>
      )}
    </>
  );
};
