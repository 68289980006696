import { fileUpload } from "redux/restApi";
import { FILE_UPLOAD_TYPE } from "./action.types/fileUpload.type";

export const requestFileUpload = () => {
  return {
    type: FILE_UPLOAD_TYPE.REQUEST,
  };
};

export const successFileUpload = (payload: any) => {
  return {
    type: FILE_UPLOAD_TYPE.SUCCESS,
    payload,
  };
};

export const faliureFileUpload = (payload: any) => {
  return {
    type: FILE_UPLOAD_TYPE.FALIURE,
    payload,
  };
};

export const fileUploadAction = (data: any, cartId: any) => {
  return (dispatch: any) => {
    dispatch(requestFileUpload());
    fileUpload(data, cartId)
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(successFileUpload(response));
        }
      })
      .catch((error) => dispatch(faliureFileUpload(error)));
  };
};
