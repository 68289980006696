import {
  getUserAddress,
  getUserProfile,
  patchUserAddress,
  patchUserProfile,
} from "redux/restApi";
import {
  GET_USER_ADDRESS_TYPE,
  GET_USER_PROFILE_TYPE,
  PATCH_USER_ADDRESS_TYPE,
} from "./action.types/user.address.types";
import { successToaster } from "utils/toaster";

export const requestUserAddress = () => {
  return {
    type: GET_USER_ADDRESS_TYPE.REQUEST,
  };
};

export const successUserAddress = (payload: any) => {
  return {
    type: GET_USER_ADDRESS_TYPE.SUCCESS,
    payload,
  };
};

export const faliureUserAddress = (payload: any) => {
  return {
    type: GET_USER_ADDRESS_TYPE.FALIURE,
    payload,
  };
};

export const getUserAddressAction = () => {
  return (dispatch: any) => {
    dispatch(requestUserAddress());
    getUserAddress()
      .then((response) => {
        dispatch(successUserAddress(response));
      })
      .catch((error) => dispatch(faliureUserAddress(error)));
  };
};

export const patchRequest = (data: any) => ({
  type: PATCH_USER_ADDRESS_TYPE.REQUEST,
  payload: data,
});

export const patchSuccess = (response: any) => ({
  type: PATCH_USER_ADDRESS_TYPE.SUCCESS,
  payload: response,
});

export const patchFailure = (error: any) => ({
  type: PATCH_USER_ADDRESS_TYPE.FAILURE,
  payload: error,
});

export const patchUserAddressAction = (formData: any) => {
  return (dispatch: any) => {
    //STATIC VALUE OF COUNTRY NAME CANADA IS GIVEN
    formData.country_name = "Canada";
    dispatch(patchRequest(formData));

    patchUserAddress(formData)
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(patchSuccess(response.data));
          successToaster(response?.data?.message);
        }
      })
      .catch((error) => {
        dispatch(patchFailure(error));
      });
  };
};

//PROFILE

export const requestUserProfile = () => {
  return {
    type: GET_USER_PROFILE_TYPE.REQUEST,
  };
};

export const successUserProfile = (payload: any) => {
  return {
    type: GET_USER_PROFILE_TYPE.SUCCESS,
    payload,
  };
};

export const faliureUserProfile = (payload: any) => {
  return {
    type: GET_USER_PROFILE_TYPE.FALIURE,
    payload,
  };
};

export const getUserProfileAction = () => {
  return (dispatch: any) => {
    dispatch(requestUserProfile());
    getUserProfile()
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(successUserProfile(response));
        }
      })
      .catch((error) => dispatch(faliureUserProfile(error)));
  };
};

export const patchRequestUserProfile = (data: any) => ({
  type: PATCH_USER_ADDRESS_TYPE.REQUEST,
  payload: data,
});

export const patchSuccessUserProfile = (response: any) => ({
  type: PATCH_USER_ADDRESS_TYPE.SUCCESS,
  payload: response,
});

export const patchFailureUserProfile = (error: any) => ({
  type: PATCH_USER_ADDRESS_TYPE.FAILURE,
  payload: error,
});

export const patchUserUserProfileAction = (formData: any) => {
  return (dispatch: any) => {
    dispatch(patchRequestUserProfile(formData));

    patchUserProfile(formData)
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(patchSuccessUserProfile(response.data));
          successToaster(response?.data?.message);
        }
      })
      .catch((error) => {
        dispatch(patchFailureUserProfile(error));
      });
  };
};
