
export const ALL_PRODUCT_LIST_TYPE = {
  REQUEST: "REQUEST_ALL_PRODUCT_LIST",
  SUCCESS: "SUCCESS_ALL_PRODUCT_LIST",
  FALIURE: "FALIURE_ALL_PRODUCT_LIST",
};

export const ALL_BLOGS_LIST_TYPE = {
  REQUEST: "REQUEST_ALL_BLOGS_LIST",
  SUCCESS: "SUCCESS_ALL_BLOGS_LIST",
  FALIURE: "FALIURE_ALL_BLOGS_LIST",
};

export const SINGLE_BLOG_TYPE = {
  REQUEST: "REQUEST_SINGLE_BLOG",
  SUCCESS: "SUCCESS_SINGLE_BLOG",
  FALIURE: "FALIURE_SINGLE_BLOG",
};

// get product basket
export const GET_PRODUCT_BASKET_TYPE = {
  REQUEST: "REQUEST_GET_PRODUCT_BASKET_TYPE",
  SUCCESS: "SUCCESS_GET_PRODUCT_BASKET_TYPE",
  FALIURE: "FALIURE_GET_PRODUCT_BASKET_TYPE",
};

// UPDATE PRODUCT BASKET
export const UPDATE_PRODUCT_BASKET_TYPE = {
  REQUEST: "REQUEST_UPDATE_PRODUCT_BASKET_TYPE",
  SUCCESS: "SUCCESS_UPDATE_PRODUCT_BASKET_TYPE",
  FALIURE: "FALIURE_UPDATE_PRODUCT_BASKET_TYPE",
};

export const SINGLE_PRODUCT_TYPE = {
  REQUEST: "REQUEST_SINGLE_PRODUCT",
  SUCCESS: "SUCCESS_SINGLE_PRODUCT",
  FALIURE: "FALIURE_SINGLE_PRODUCT",
};

export const PROMOTION_TYPE = {
  REQUEST: "REQUEST_PROMOTION",
  SUCCESS: "SUCCESS_PROMOTION",
  FALIURE: "FALIURE_PROMOTION",
};

export const DESIGN_GALLERY_TYPE = {
  REQUEST: "REQUEST_DESIGN_GALLERY",
  SUCCESS: "SUCCESS_DESIGN_GALLERY",
  FALIURE: "FALIURE_DESIGN_GALLERY",
};

export const FAQ_TYPE = {
  REQUEST: "REQUEST_FAQ",
  SUCCESS: "SUCCESS_FAQ",
  FALIURE: "FALIURE_FAQ",
};

export const TEMPLATE_TYPE = {
  REQUEST: "REQUEST_TEMPLATE",
  SUCCESS: "SUCCESS_TEMPLATE",
  FALIURE: "FALIURE_TEMPLATE",
};

export const ADD_TO_CART_TYPE = {
  REQUEST: "REQUEST_ADD_TO_CART",
  SUCCESS: "SUCCESS_ADD_TO_CART",
  FALIURE: "FALIURE_ADD_TO_CART",
};

export const GET_CART_TYPE = {
  REQUEST: "REQUEST_GET_CART",
  SUCCESS: "SUCCESS_GET_CART",
  FALIURE: "FALIURE_GET_CART",
};

export const GET_WISHLIST_TYPE = {
  REQUEST: "REQUEST_GET_WISHLIST",
  SUCCESS: "SUCCESS_GET_WISHLIST",
  FALIURE: "FALIURE_GET_WISHLIST",
};

export const PATCH_WISHLIST_TYPE = {
  REQUEST: "REQUEST_PATCH_WISHLIST",
  SUCCESS: "SUCCESS_PATCH_WISHLIST",
  FALIURE: "FALIURE_PATCH_WISHLIST",
};

export const DELETE_CART_TYPE = {
  REQUEST: "REQUEST_DELETE_CART",
  SUCCESS: "SUCCESS_DELETE_CART",
  FALIURE: "FALIURE_DELETE_CART",
};
// add review
export const ADD_REVIEW_TYPE = {
  REQUEST: "REQUEST_ADD_REVIEW",
  SUCCESS: "SUCCESS_ADD_REVIEW",
  FALIURE: "FALIURE_ADD_REVIEW",
};

// request a quote
export const REQUEST_A_QUOTE_TYPE = {
  REQUEST: "REQUEST_REQUEST_A_QUOTE",
  SUCCESS: "SUCCESS_REQUEST_A_QUOTE",
  FALIURE: "FALIURE_REQUEST_A_QUOTE",
};

export const POST_BLOG_COMMENTS = {
  REQUEST: "REQUEST_POST_BLOG_COMMENTS",
  SUCCESS: "SUCCESS_POST_BLOG_COMMENTS",
  FALIURE: "FALIURE_POST_BLOG_COMMENTS",
};
