import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneCallbackOutlinedIcon from "@mui/icons-material/PhoneCallbackOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Westjet, Lululemon, BostonPizza, Enmax } from "assets";

export const Logos = [
  {
    img: Enmax,
  },
  {
    img: BostonPizza,
  },
  {
    img: Lululemon,
  },
  {
    img: Westjet,
  },
];

export const CompanyListArr = [
  {
    name: "Blogs",
    link: "/blogs",
  },
  {
    name: "Faqs",
    link: "/faqs",
  },
  {
    name: "Location",
    link: "/location",
  },
  {
    name: "Templates",
    link: "/templates",
  },
];

// Footer Constant Data

export const FooterDataQuickLinks: QuickLinks[] = [
  {
    heading: "Quick Links",
    URL: "/QuickLinks",
    links: [
      { name: "FAQs", link: "/faqs" },
      { name: "Blog", link: "/blogs" },
      { name: "Wishlist", link: "/Wishlist" },
      { name: "All Products", link: "/products" },
      { name: "Request A Quote", link: "/request-a-quote" },
      { name: "Layout GuideLines", link: "/templates" },
    ],
  },
];

export const FooterDataContactUs: FooterContactUs[] = [
  {
    heading: "Contact Us",
    URL: "/Contact-us",
    links: [
      {
        name: "Store Location",
        subHeading: "3632 Burnsland Rd SE",
        icon: <LocationOnIcon />,
      },
      {
        name: "Call Us",
        subHeading: "(403) 235-1616",
        icon: <PhoneCallbackOutlinedIcon />,
        link: "tel:4032351616",
      },
      {
        name: "Drop A Line",
        subHeading: "orders@printcalgary.com",
        link: "mailto:orders@printcalgary.com",
        icon: <MailOutlineIcon />,
      },
    ],
  },
];

export const FooterDataRecentNews: FooterRecentNews[] = [
  {
    heading: "Recent News",
    URL: "/RecentNews",
    links: [
      {
        name: "Tips to create an effective signage Calgary",
        link: "/tips-to-create-an-effective-signage-calgary",
        color: "#EC0A90",
        Date: "January 19, 2022",
      },
      {
        name: "Building a Relationship with a Good Printer",
        link: "/building-a-relationship-with-a-good-printer",
        color: "#EC0A90",
        Date: "October 24,2021",
      },
    ],
  },
];

interface StateWiseTax {
  id: number;
  province: string;
  taxPercent: number;
}

export const stateWiseTax: StateWiseTax[] = [
  { id: 1, province: "Alberta", taxPercent: 5 },
  { id: 2, province: "British Columbia", taxPercent: 12 },
  { id: 3, province: "Manitoba", taxPercent: 12 },
  { id: 4, province: "New Brunswick", taxPercent: 15 },
  { id: 5, province: "Newfoundland and Labrador", taxPercent: 15 },
  { id: 6, province: "Northwest Territories", taxPercent: 5 },
  { id: 7, province: "Nova Scotia", taxPercent: 15 },
  { id: 8, province: "Nunavat", taxPercent: 5 },
  { id: 9, province: "Ontario", taxPercent: 13 },
  { id: 10, province: "Prince Edward Island", taxPercent: 15 },
  { id: 11, province: "Quebec", taxPercent: 14.975 },
  { id: 12, province: "Saskatchewan", taxPercent: 11 },
  { id: 13, province: "Yukon", taxPercent: 5 },
];
