import {
  BreadCrumbs,
  ContainerBox,
  LoadingComponent,
  TableComponent,
} from "components";
import React, { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tempalteAction } from "redux/Actions/template.actions";
import { Box, Typography } from "@mui/material";
import { DataNotFound } from "components/Containers/DataNotFound";

type Props = {};

export const TemplatePage = (props: Props) => {
  const loading = useSelector((state: any) => state?.templateReducer?.loading);

  const columns = [
    { title: "Size and type", key: "size_and_type" },
    { title: "AI", key: "ai_link" },
    { title: "PDF", key: "pdf_link" },
    { title: "PSD", key: "psd_link" },
    { title: "InDesign", key: "indesign_link" },
  ];

  const templateList = useSelector(
    (state: any) => state.templateReducer?.listArr?.data
  );

  const dispatch: Dispatch<any> = useDispatch();
  useEffect(() => {
    dispatch(tempalteAction());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <ContainerBox>
          <BreadCrumbs link="Template" />
          <Box sx={{ paddingTop: "1rem" }}>
            {templateList?.length > 0 ? (
              <>
                {templateList?.map((template: any, index: number) => {
                  return (
                    <Box
                      sx={{
                        marginBottom: "2rem",
                      }}
                      key={index}
                    >
                      {template?.details?.length > 0 && template ? (
                        <>
                          <Typography
                            sx={{
                              fontSize: "1.9rem",
                              fontFamily: "sans-serif",
                              fontWeight: "800",
                            }}
                          >
                            {template?.title}
                          </Typography>

                          <TableComponent
                            columns={columns}
                            data={template?.details}
                          />
                        </>
                      ) : null}
                    </Box>
                  );
                })}
              </>
            ) : (
              <DataNotFound />
            )}
          </Box>
        </ContainerBox>
      )}
    </>
  );
};
