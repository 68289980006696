import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { goToTop } from "utils/goToTop";
import { useTheme } from "@mui/material/styles";

type Props = {
  productList?: any | undefined;
};

export const SimpleCard = ({ productList }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        maxWidth: "100%",
        padding: " 24px 24px 20px",
      }}
    >
      <Link
        onClick={goToTop}
        to={`/products/${productList?.name
          .toLowerCase()
          .split(" ")
          .filter((elem: any) => elem !== "–")
          .join("-")}/`}
        state={{
          id: productList?.id,
        }}
      >
        {/* <Link to={`singleProduct/${productList?.id}`} onClick={goToTop}> */}
        <Box>
          <img
            height="100%"
            width="100%"
            src={productList?.base_image_url}
            alt=""
          />
        </Box>
        <Box>
          <Box>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
                lineHeight: "20px",
              }}
            >
              {productList?.name}
            </Typography>

            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: "16px",
                fontWeight: "bold",
                lineHeight: "20px",
              }}
            >
              {`$${productList?.base_price}`}
            </Typography>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};
