// theme.ts
import { createTheme,  } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ["Rajdhani", "sans-serif", "oswald"].join(","),
  },
  palette: {
    primary: {
      main: "#EC0A90",
    },
    secondary: {
      main: "#00ff00",
    },
  },
});
  