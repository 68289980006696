import { Box, Typography } from "@mui/material";
import { BasicButton, BasicInput, Required } from "components";
import React, { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "redux/Actions/login.actions";
import { goToTop } from "utils/goToTop";

type Props = {
  data?: any;
  payload?: any;
  setPayload?: any;
  handleOnchange?: any;
  handleSubmit?: any;
};

export const CommentBox = ({
  data,
  payload,
  setPayload,
  handleOnchange,
  handleSubmit,
}: Props) => {
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  const handleLogOut = () => {
    const logOutText = "Do you really want to logout?";
    if (window.confirm(logOutText) === true) {
      navigate("/myAccount");
      dispatch(logout());
    }
  };

  return (
    <Box sx={{ width: { xs: "100%", md: "75%" } }}>
      <Typography sx={{ fontSize: "1.8rem", fontWeight: "800" }}>
        Leave a Reply
      </Typography>
      {localStorage?.getItem("logtoken") ? (
        <form onSubmit={handleSubmit}>
          <Box>
            <Box
              sx={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                mb="5px"
                sx={{
                  fontWeight: "400",
                  color: "#969696",
                  display: "flex",
                  alignItems: "end",
                  gap: "5px",
                }}
              >
                <span style={{ display: "flex" }}>
                  Logged in as {localStorage.getItem("displayName")}
                  {" . "}
                </span>
                <span
                  style={{
                    color: "#000",
                    display: "flex",
                    gap: "5px",
                    alignItems: "flex-end",
                  }}
                >
                  {" "}
                  <Link to="/myAccount" onClick={goToTop}>
                    Edit My Profile
                  </Link>
                  <span style={{ cursor: "pointer" }} onClick={handleLogOut}>
                    Logout?
                  </span>
                  Required fields are marked <Required />
                </span>
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "10px" }}>
              <BasicInput
                size={"80%"}
                name={"comment"}
                onChange={(e: any) => {
                  handleOnchange(e);
                }}
                value={payload.comment}
                type="text"
                label={"Your Comment:"}
                multiline={"multiline"}
                rows={8}
                isRequired
              />
            </Box>
            <BasicButton
              buttonType="submit"
              placeholder={"submit"}
              width={"150px"}
              borderRadius={"5px"}
              height={"40px"}
            />
          </Box>
        </form>
      ) : (
        "You must be logged in to post a comment."
      )}
    </Box>
  );
};
