import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import { Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { BasicButton, BasicInput, Required } from "components";
import Ratings from "components/Ratings";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { addReviewAction } from "redux/Actions/review.actions";
import { singleProductAction } from "redux/Actions/product.actions";
import { goToTop } from "utils/goToTop";
import { errorToaster } from "utils/toaster";
type Props = {
  description?: any;
  productId?: number;
  productReviews?: any;
};

const DescriptionTabs = ({ description, productId, productReviews }: Props) => {
  const [value, setValue] = useState("1");
  const [reviewData, setReviewData] = useState({ rating: "", review: "" });
  const dispatch: Dispatch<any> = useDispatch();

  const tabsData = [
    { name: "Description", value: "1" },
    {
      name: productReviews?.total_reviews
        ? `Reviews (${productReviews?.total_reviews})`
        : `Reviews (0)`,
      value: "2",
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  // const handleReview = (e: any) => {
  //   setReviewData({ ...reviewData, [e.target.name]: e.target.value });
  // };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (reviewData?.rating === "") {
      // Display an error message or handle the lack of rating or review.
      errorToaster("Please provide rating");
    } else if (reviewData?.review.trim() === "") {
      errorToaster("Please provide reviews");
    } else {
      const data = {
        product: productId,
        rating: reviewData?.rating,
        review: reviewData?.review,
      };
      dispatch(addReviewAction(data));
      setReviewData({ ...reviewData, rating: "", review: "" });
      dispatch(singleProductAction(productId));
      goToTop();
    }
  };

  const obj = productReviews?.reviews?.my_rating || {};

  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
        padding: "20px 0",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabsData?.map((item: any, index: number) => {
              return (
                <Tab
                  key={index}
                  label={item?.name}
                  value={item?.value}
                  sx={{
                    backgroundColor: "#d0d0d0",
                    border: "1px solid #d0d0d0",
                  }}
                />
              );
            })}

            {/* <Tab
              label="Reviews"
              value="2"
              sx={{ backgroundColor: "#d0d0d0", border: "1px solod #d0d0d0" }}
            /> */}
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: "0px" }}>
          {description}
        </TabPanel>
        <TabPanel value="2" sx={{ padding: "0px" }}>
          <Typography sx={{ fontSize: "3rem", fontWeight: "800" }}>
            Reviews
          </Typography>
          <Box>
            {/* self reviews */}

            {Object.keys(obj)?.length === 0 ? null : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    padding: "15px 0",
                  }}
                >
                  <Box>
                    <AccountCircleIcon sx={{ fontSize: "40px" }} />
                  </Box>
                  <Box>
                    {obj?.is_approved === false ? (
                      <Typography sx={{ fontSize: "1.1rem" }}>
                        Your review is awaiting approval
                      </Typography>
                    ) : (
                      <Typography sx={{ fontSize: "1.1rem" }}>
                        {obj?.display_name}
                      </Typography>
                    )}

                    <Ratings value={obj?.rating} readOnly={true} />
                    <Typography sx={{ fontSize: "1.1rem" }}>
                      {obj?.review}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}

            {productReviews?.reviews?.others_rating?.length <= 0 ? (
              <Typography sx={{ fontSize: "1.3rem" }}>
                There are no reviews yet.
              </Typography>
            ) : (
              <>
                {productReviews?.reviews?.others_rating?.map(
                  (item: any, index: number) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          padding: "15px 0",
                        }}
                      >
                        <Box>
                          <AccountCircleIcon sx={{ fontSize: "40px" }} />
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: "1.1rem" }}>
                            {item?.display_name}
                          </Typography>
                          <Ratings value={item?.rating} readOnly={true} />
                          <Typography sx={{ fontSize: "1.1rem" }}>
                            {item?.review}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }
                )}
              </>
            )}
          </Box>
          {/* <Box>
            <Typography sx={{ fontSize: "1.6rem", fontWeight: "600" }}>
              Be The First To Review “Gift Certificates”
            </Typography>
            <Typography>You must be logged in to post a review.</Typography>
          </Box> */}

          {localStorage?.getItem("logtoken") ? (
            <Box>
              <Typography sx={{ fontSize: "1.8rem", fontWeight: "800" }}>
                Add A Review
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box>
                  <Box
                    sx={{
                      marginBottom: "10px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      mb="5px"
                      sx={{ fontWeight: "400", color: "#969696" }}
                    >
                      {"Your Rating"}
                      {<Required />}
                    </Typography>
                    <Ratings
                      value={reviewData?.rating}
                      name={"rating"}
                      onChange={(event: any, newValue: any) => {
                        setReviewData({ ...reviewData, rating: newValue });
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "100%", marginBottom: "10px" }}>
                    <BasicInput
                      size={"50%"}
                      name={"review"}
                      onChange={(e: any) => {
                        setReviewData({
                          ...reviewData,
                          review: e.target.value,
                        });
                      }}
                      value={reviewData?.review}
                      type="text"
                      label="Your Review"
                      multiline={"multiline"}
                      rows={8}
                      isRequired
                    />
                  </Box>
                  <BasicButton
                    buttonType="submit"
                    placeholder={"submit"}
                    width={"150px"}
                    borderRadius={"5px"}
                    height={"40px"}
                  />
                </Box>
              </form>
            </Box>
          ) : (
            ""
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default DescriptionTabs;
