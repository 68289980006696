import {
  ContainerBox,
  LoadingComponent,
  CartCounter,
  BasicButton,
} from "components";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  couponUpdatedFalse,
  deleteCartAction,
  deleteCartResetAction,
  getCartAction,
  getCartCheckout,
} from "redux/Actions/product.actions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Box,
  Paper,
  createTheme,
  ThemeProvider,
  CircularProgress,
} from "@mui/material";
import { ArrowBack, Delete } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { FileUpload } from "./FileUpload";
import { Link, useNavigate } from "react-router-dom";
import { goToTop } from "utils/goToTop";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  resetUpdateCartProductQuantityListAction,
  updateCartProductQuantityListAction,
} from "redux/Actions/order.actions";
import { StyledCoupon } from "components/Coupon";

type Props = {
  cartItems?: any;
};
const theme = createTheme({
  typography: {
    fontFamily: ["sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#EC0A90",
    },
    secondary: {
      main: "#00ff00",
    },
  },
});
export const CartTable = ({ cartItems }: Props) => {
  const [counter, setCounter] = useState(0);
  const theme = useTheme();
  const dispatch: Dispatch<any> = useDispatch();
  const [loaderId, setLoaderId] = useState(null);

  const loading = useSelector((state: any) => state?.getCartReducer?.loading);

  const qtyReducer = useSelector(
    (state: any) => state?.updateCartProductQuantityReducer?.listArr?.data
  );

  const onItemDelete = (id: any) => {
    dispatch(deleteCartAction(id));
  };

  const handleCounterChange = (newValue: any, id: any) => {
    setLoaderId(id);
    const payload = {
      id: id,
      qty: newValue,
    };
    const quantityDebounce = setTimeout(() => {
      dispatch(updateCartProductQuantityListAction(payload));
    }, 500);

    return () => clearTimeout(quantityDebounce);
  };

  useEffect(() => {
    if (qtyReducer?.success === true) {
      dispatch(getCartAction(true));
      dispatch(resetUpdateCartProductQuantityListAction());
    }
  }, [dispatch, qtyReducer]);

  const headings = ["Product", "Price", "Quantity", "Total", ""];

  return (
    <>
      <TableContainer component={Paper}>
        <Box sx={{ maxHeight: "100vh", overflowY: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                {headings?.map((item: any, index: number) => {
                  return (
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        fontFamily: "sans-serif",
                      }}
                      key={index}
                    >
                      {item}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {cartItems?.map((item: any, index: any) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          // alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={item?.product?.base_image_url}
                            alt={item?.product?.name}
                            width={50}
                            height={50}
                            loading="lazy"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                              justifyContent: "space-between",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <Link
                                to={`/products/${item?.product?.name
                                  .toLowerCase()
                                  .split(" ")
                                  .filter((elem: any) => elem !== "–")
                                  .join("-")}/`}
                                state={{
                                  id: item?.product?.id,
                                }}
                                onClick={goToTop}
                              >
                                {/* <Link to={`singleProduct/${item?.product?.id}`}> */}
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    fontFamily: "sans-serif",
                                    ml: 2,
                                  }}
                                  variant="body1"
                                >
                                  {item?.product?.name}
                                </Typography>
                              </Link>

                              <Link
                                // here Ascii key is sent so we have to filter using that
                                to={`/products/updateCart/${item?.product?.name
                                  .toLowerCase()
                                  .split(" ")
                                  .filter((elem: any) => elem !== "–")
                                  .join("-")}/`}
                                state={{
                                  id: item?.id,
                                }}
                                onClick={goToTop}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "0.7rem",
                                    fontWeight: "500",
                                    "&:hover": {
                                      color: "#EC0A90",
                                    },
                                  }}
                                >
                                  Edit Options
                                </Typography>
                              </Link>
                            </Box>
                            <Box
                              sx={{
                                margin: "0 15px  ",
                                width: "-webkit-fill-available",
                              }}
                            >
                              <Typography>
                                {JSON.parse(
                                  item?.product?.short_description
                                )?.map((value: any) => {
                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        // border: "1px solid",
                                        justifyContent: "space-between",
                                        fontSize: "10px",
                                        gap: "10px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#000",
                                          fontWeight: "500",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {value?.key}
                                      </Typography>
                                      <br />
                                      <Typography
                                        sx={{
                                          color: theme.palette.primary.main,
                                          fontSize: "10px",
                                        }}
                                      >
                                        {value?.value}
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ width: "100%" }}>
                          <FileUpload cartId={item?.id} />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        fontFamily: "sans-serif",
                      }}
                      className="white-space"
                    >
                      {`$${item?.product?.base_price?.toFixed(2)}`}
                    </TableCell>
                    <TableCell>
                      {loading && item?.id === loaderId ? (
                        <Box
                          sx={{
                            display: "flex",
                            height: "5px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress size={40} />
                        </Box>
                      ) : (
                        <CartCounter
                          item={item}
                          onChange={handleCounterChange}
                          counter={counter}
                          setCounter={setCounter}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        fontFamily: "sans-serif",
                        // ml: 2,
                      }}
                      className="white-space"
                    >
                      {`$${(item?.product?.base_price * item?.qty)?.toFixed(
                        2
                      )}`}{" "}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => onItemDelete(item?.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </>
  );
};

export const Cart = (props: Props) => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const [link, setLink] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [couponAvailable, setCouponAvailable] = useState(false);

  const deleteCartItems = useSelector(
    (state: any) => state?.deleteCartReducer?.listArr
  );
  const loading = useSelector((state: any) => state?.getCartReducer?.loading);
  const getCart = useSelector(
    (state: any) => state?.getCartReducer?.listArr?.data?.results
  );
  const couponUpdate = useSelector((state: any) => state?.getCouponUpdated);

  useEffect(() => {
    //? flow is detailed and commented on checkout page -------- pages/Checkout/index.tsx
    //? flow can be modularized but used 2 times instead temporarily
    if (sessionStorage?.getItem("coupon")) {
      setCouponAvailable(true);
      const coupon = sessionStorage?.getItem("coupon");
      const couponRes = coupon && JSON.parse(coupon);
      const products = getCart?.map((item: any) => item?.product?.id);
      const productsWithPrice = getCart?.map((item: any) => ({
        id: item?.product?.id,
        base_price: item?.product?.base_price,
        qty: item?.qty,
      }));
      //@ts-ignore
      const sortedProducts =
        //@ts-ignore

        products && [...new Set(products)]?.sort((a, b) => a - b);
      const couponEnabledProducts = couponRes?.data?.product;

      if (couponRes?.data?.is_percentage) {
        if (
          JSON.stringify(sortedProducts) ===
          JSON.stringify(couponEnabledProducts)
        ) {
          const discountValue = (totalAmount * couponRes?.data?.discount) / 100;
          setDiscount(discountValue);
          setDiscountedAmount(totalAmount - discountValue);
        } else {
          const couponVerifiedProducts = sortedProducts?.filter(
            (element: any) => couponEnabledProducts?.includes(element)
          );
          const couponVerifiedProductsWithPrice = productsWithPrice?.filter(
            (element: any) => couponVerifiedProducts?.includes(element?.id)
          );
          const priceEligibleForDiscount =
            couponVerifiedProductsWithPrice?.reduce(
              (acc: any, item: any) => acc + item?.base_price * item?.qty,
              0
            );
          const discountValue =
            (priceEligibleForDiscount * couponRes?.data?.discount) / 100;
          setDiscount(discountValue);
          setDiscountedAmount(totalAmount - discountValue);
        }
      } else {
        const discountValue = couponRes?.data?.discount;
        if (discountValue > totalAmount) {
          setDiscount(totalAmount);
        } else {
          setDiscount(discountValue);
        }
        setDiscountedAmount(Math?.max(totalAmount - discountValue, 0));
      }
    } else {
      setDiscountedAmount(totalAmount);
    }
    dispatch(couponUpdatedFalse());
  }, [dispatch, getCart, totalAmount, couponUpdate?.updated]);

  useEffect(() => {
    if (couponUpdate?.removed === true) {
      setDiscount(0);
      setDiscountedAmount(totalAmount);
      setCouponAvailable(false);
    }
  }, [totalAmount, couponUpdate?.removed]);

  const handleCheckout = () => {
    const cartIds = getCart.map((item: any) => item?.id);
    const payload = { cart_items: cartIds };
    dispatch(getCartCheckout(payload));
    navigate(`/${link}`);
    goToTop();
  };
  const handleContinueShopping = () => {
    navigate("/products");
  };

  useEffect(() => {
    if (deleteCartItems?.success === true) {
      dispatch(getCartAction());
      dispatch(deleteCartResetAction());
    }
  }, [deleteCartItems, dispatch]);

  useEffect(() => {
    dispatch(getCartAction(true));
  }, [dispatch]);
  useEffect(() => {
    // if (localStorage.getItem("logtoken")) {
    setLink("checkout");
    // } else {
    // setLink("myAccount");
    // }
  }, []);

  useEffect(() => {
    setTotalAmount(
      getCart?.reduce(
        (acc: any, item: any) => acc + item?.product?.base_price * item?.qty,
        0
      )
    );
  }, [getCart]);

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <ContainerBox>
            {getCart?.length > 0 ? (
              <>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "2.5rem",
                      fontFamily: "sans-serif",
                      mb: 2,
                    }}
                  >
                    Cart
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "2rem",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                    <CartTable cartItems={getCart} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "20px 0",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                        onClick={handleContinueShopping}
                      >
                        <ArrowBack
                          sx={{
                            color: "#767676",
                            "&:hover": {
                              color: "#EC0A90",
                            },
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#767676",
                            "&:hover": {
                              color: "#EC0A90",
                            },
                          }}
                        >
                          Continue Shopping
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                        }}
                        // onClick={handleClick}
                      >
                        <RefreshIcon
                          sx={{
                            color: "#767676",
                            "&:hover": {
                              color: "#EC0A90",
                            },
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#767676",
                            "&:hover": {
                              color: "#EC0A90",
                            },
                          }}
                        >
                          Update Cart
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ padding: "10px 0" }}>
                      <StyledCoupon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: { xs: "100%", md: "30%" },
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        padding: "1rem 1.5rem",
                        background: "#f5f5f5",
                        height: "fit-content  ",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          padding: "10px 0",
                        }}
                      >
                        Basket Totals
                      </Typography>
                      <hr />
                      <Box
                        sx={{
                          padding: "10px 0",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          color: "#555555",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Subtotal</Typography>
                          <Box>
                            <Typography
                              sx={{ fontWeight: "700", color: "#000" }}
                            >
                              {"$"}
                              {getCart
                                ?.reduce(
                                  (acc: any, item: any) =>
                                    acc + item.product.base_price * item.qty,
                                  0
                                )
                                ?.toFixed(2)}
                            </Typography>
                            <Typography sx={{ fontSize: "0.8rem" }}>
                              {/* (incl. tax) */}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "3rem",
                            // lineHeight: "30px",
                          }}
                        >
                          <Typography>Shipping</Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "end",
                            }}
                          >
                            <Typography sx={{ lineHeight: "30px" }}>
                              Free Shipping
                            </Typography>
                            {/* <Typography
                              sx={{ lineHeight: "30px", color: "#767676" }}
                            >
                              Enter your address to view shipping options.
                            </Typography> */}
                          </Box>
                        </Box>
                      </Box>
                      <hr />
                      {couponAvailable && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px 0",
                          }}
                        >
                          <Typography>Coupon Applied </Typography>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1rem",
                              color: "#EC0A90",
                            }}
                          >
                            {`$${discount?.toFixed(2)}`}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 0",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
                        >
                          Total
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "end",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                              color: "#EC0A90",
                            }}
                          >
                            {`$${discountedAmount?.toFixed(2)}`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <BasicButton
                      placeholder="Checkout"
                      width="100%"
                      onClick={handleCheckout}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <section>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "10px 0",
                    fontSize: "1.3rem",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <p>No Items in Cart</p>
                  <Link to="/products">
                    <p style={{ textDecoration: "underline" }}>
                      Browse Products
                    </p>
                  </Link>
                </Box>
              </section>
            )}
          </ContainerBox>
        </>
      )}
    </ThemeProvider>
  );
};
