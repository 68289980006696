import React from "react";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import { goToTop } from "utils/goToTop";
import { useTheme } from "@mui/material/styles";
type Props = {};

export const SocialBox = (props: Props) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#000",
          padding: "24px",
          margin: "0px 60px 0px 0px",
        }}
      >
        <Box sx={{ padding: "10px" }}>
          <Typography
            sx={{
              textAlign: "left",
              color: theme.palette.primary.main,
              fontSize: "17px",
              fontWeight: "500",
              lineHeight: "23px",
            }}
          >
            We are open to support Calgary businesses producing rush orders
            everyday.
          </Typography>

          <Box
            className="socialIcons"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              padding: "15px 0",
            }}
          >
            <a
              href="https://www.facebook.com/printcalgary"
              target="_blank"
              rel="noreferrer"
              onClick={goToTop}
            >
              <Typography
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "50px",
                  alignItems: "center",
                  display: "flex",
                  padding: "10px",
                }}
              >
                <FacebookOutlinedIcon style={{ color: "#fff" }} />
              </Typography>
            </a>
            <a
              href="https://www.instagram.com/print.calgary/"
              target="_blank"
              rel="noreferrer"
              onClick={goToTop}
            >
              <Typography
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "50px",
                  alignItems: "center",
                  display: "flex",
                  padding: "10px",
                }}
              >
                {<InstagramIcon style={{ color: "#fff" }} />}
              </Typography>
            </a>
          </Box>

          <Box
            sx={{
              margin: "50px 0px 0px 0px",
              padding: "20px 20px 20px 20px",
              backgroundColor: " #342828",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                color: "#fff",
                fontSize: "17px",
                fontWeight: "500",
                lineHeight: "23px",
              }}
            >
              Copyright @ 2023 Print Calgary. All Rights Reserved.
            </Typography>
          </Box>

          <Box sx={{ padding: "10px 0 10px 20px" }}>
            <Typography sx={{ color: "#fff", fontSize: "18px" }}>
              Built By{" "}
              <a href="https://x360digital.com/">x360 Digital Inc.</a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
