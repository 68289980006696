import { designGallery } from "redux/restApi";
import { DESIGN_GALLERY_TYPE } from "./action.types/action.types";

export const requestDesignGallery = () => {
  return {
    type: DESIGN_GALLERY_TYPE.REQUEST,
  };
};

export const successDesignGallery = (payload: any) => {
  return {
    type: DESIGN_GALLERY_TYPE.SUCCESS,
    payload,
  };
};

export const faliureDesignGallery = (payload: any) => {
  return {
    type: DESIGN_GALLERY_TYPE.FALIURE,
    payload,
  };
};

export const designGalleryAction = () => {
  return (dispatch: any) => {
    dispatch(requestDesignGallery());
    designGallery()
      .then((response) => {
        if(response?.status === 200 && response){
          dispatch(successDesignGallery(response));
        }
      })
      .catch((error) => dispatch(faliureDesignGallery(error)));
  };
};
