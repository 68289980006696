import React from "react";
import { Box } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useTheme } from "@mui/material/styles";

type Props = {
  onClick?: any;
};

export const AddToCartButton = ({ onClick }: Props) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          backgroundColor: theme?.palette?.primary?.main,
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <AddShoppingCartIcon sx={{ color: "white" }} />
      </Box>
    </>
  );
};
