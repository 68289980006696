import { Dispatch } from "react";
import { Box, Icon, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommentAction,
  singleBlogListAction,
} from "./../../redux/Actions/blog.actions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { BreadCrumbs, ContainerBox, LoadingComponent } from "components";
import { goToTop } from "utils/goToTop";
import { LoadingImage } from "assets";
import LazyImage from "components/LazyImage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import moment from "moment";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { CommentBox } from "./CommentBox";
import { errorToaster } from "utils/toaster";
import { GetCommentBox } from "./GetCommentBox";
type Props = {};

const URL = process.env.REACT_APP_API_URL;

export const SingleBlog = (props: Props) => {
  // const commentBoxRef = useRef(null);
  const location = useLocation();

  const id = location?.state?.id;

  const [payload, setPayload] = useState({
    id: id,
    comment: "",
  });

  // const { id } = useParams<{ id: any }>();
  //newchanges

  const singleBlogData = useSelector(
    (state: any) => state?.singleBlogReducer?.listArr?.data?.data
  );
  const loading = useSelector(
    (state: any) => state?.singleBlogReducer?.loading
  );

  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    dispatch(singleBlogListAction(id));
  }, [dispatch, id]);

  function MyComponent(content: any) {
    const data = (
      <div
        //@ts-ignore

        dangerouslySetInnerHTML={{
          __html: content?.replace(/<br>/g, "<br/><br/>"),
        }}
      />
    );
    return data;
  }

  const handleOnchange = (e: any) => {
    setPayload({
      id: id,
      comment: e.target.value,
    });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (payload?.comment === "") {
      errorToaster("Please Enter comment");
    } else {
      dispatch(addCommentAction(payload));
    }
  };

  const iconList = [
    {
      icon: <FacebookIcon />,
      color: "#1b4e9b",
      link: `http://www.facebook.com/sharer.php?u=https%3A%2F%2Fprintcalgary.com%2F${singleBlogData?.title
        .toLowerCase()
        .split(" ")
        .filter((elem: any) => elem !== "–")
        .join("-")}%2F&amp;t=${singleBlogData?.title}`,
    },
    {
      icon: <TwitterIcon />,
      color: "#1c9deb",
      link: `https://twitter.com/intent/tweet?text=${
        singleBlogData?.title
      }&url=https%3A%2F%2Fprintcalgary.com%2F${singleBlogData?.title
        .toLowerCase()
        .split(" ")
        .filter((elem: any) => elem !== "–")
        .join("-")}%2F`,
    },
    {
      icon: <LinkedInIcon />,
      color: "#026fac",
      link: `http://www.linkedin.com/shareArticle?url=https%3A%2F%2Fprintcalgary.com%2F${singleBlogData?.title
        .toLowerCase()
        .split(" ")
        .filter((elem: any) => elem !== "–")
        .join("-")}%2F&amp;title=${singleBlogData?.title}`,
      //? when we upload this site live then we have to use below link as in our url /blogs/blogName should come as endpoint
      // link: `http://www.linkedin.com/shareArticle?url=https%3A%2F%2Fprintcalgary.com%2Fblogs%2F${singleBlogData?.title.toLowerCase().split(" ").filter((elem: any) => elem != "–").join("-")}%2F&amp;title=${singleBlogData?.title}`
    },
    {
      icon: <EmailIcon />,
      color: "#d44f42",
      link: `mailto:?subject=Tips to create an effective signage Calgary&body=https%3A%2F%2Fprintcalgary.com%2F${singleBlogData?.title}%2F`,
    },
  ];
  const navigate = useNavigate();
  // let updatedIndex = singleBlogData;
  const handleNext = (updatedIndex?: any, title?: any) => {
    if (updatedIndex) {
      // updatedIndex = updatedIndex + 1;
      navigate(
        `/blogs/${title
          ?.toLowerCase()
          .split(" ")
          .filter((elem: any) => elem !== "–")
          .join("-")}`,
        {
          state: { id: updatedIndex },
        }
      );
    }
    goToTop();
  };
  const handlePrev = (updatedIndex?: any, title?: any) => {
    if (updatedIndex) {
      // updatedIndex = updatedIndex + 1;
      navigate(
        `/blogs/${title
          ?.toLowerCase()
          .split(" ")
          .filter((elem: any) => elem !== "–")
          .join("-")}`,
        {
          state: { id: updatedIndex },
        }
      );
    }
    goToTop();
  };

  const handleIconClick = () => {
    const commentHeading = document.getElementById("comment-heading");

    if (commentHeading) {
      const yOffset = -150; // Adjust this value as needed
      const y =
        commentHeading.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <>
      <Box sx={{ background: "#f5f5f5", padding: "15px 0" }}>
        <ContainerBox>
          <BreadCrumbs link="blogs" />
        </ContainerBox>
      </Box>
      {loading ? (
        <LoadingComponent />
      ) : (
        <ContainerBox>
          <section>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row-reverse",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: { xs: "", md: "0 0 75%" },
                  maxWidth: { md: "75%", sm: "75%", xs: "100%" },
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontFamily: "oswald",
                    fontWeight: "700",
                  }}
                >
                  {singleBlogData?.title}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    fontFamily: "Open Sans, san-serif",
                  }}
                >
                  <Typography sx={{}}>
                    {moment(singleBlogData?.createddate).format(
                      "MMMM DD, YYYY"
                    )}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      // src="https://secure.gravatar.com/avatar/173a8e01aacd06d39e660ed526c4b086?s=90&d=mm&r=g"
                      src={`${URL}${singleBlogData?.auther?.image}`}
                      alt=""
                      loading="lazy"
                      height="20px"
                    />

                    <Box
                      sx={{
                        display: "flex",
                        gap: "12px",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          color: "#EC0A90",
                        }}
                      >
                        <Link
                          to={`/blogs/author/${singleBlogData?.auther?.name
                            ?.split(" ")
                            ?.join("-")
                            ?.toLowerCase()}`}
                          // to="/blogs"
                          onClick={goToTop}
                          style={{ display: "flex" }}
                          state={{ id: singleBlogData?.auther?.id }}
                        >
                          {singleBlogData?.auther?.name}
                        </Link>
                      </Typography>
                    </Box>
                  </Box>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                      cursor: "pointer",
                    }}
                    onClick={handleIconClick}
                  >
                    <QuestionAnswerIcon />
                    {` ${singleBlogData?.comments?.length} `}
                    comments
                  </Typography>

                  <Typography sx={{ color: "#EC0A90" }}>
                    <Link
                      to={`/blogs/category/${singleBlogData?.category?.name
                        ?.split(" ")
                        ?.join("-")
                        ?.toLowerCase()}`}
                      // to="/blogs"
                      onClick={goToTop}
                      style={{ display: "flex" }}
                      state={{ categoryId: singleBlogData?.category?.id }}
                    >
                      {singleBlogData?.category?.name}
                    </Link>
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  {iconList?.map((item: any, index: any) => {
                    return (
                      <React.Fragment key={index}>
                        <Link to={item?.link} target="_blank" onClick={goToTop}>
                          <Icon
                            sx={{
                              background: item?.color,
                              padding: "1.3rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#fff",
                              fontSize: "2rem",
                            }}
                          >
                            {item?.icon}
                          </Icon>
                        </Link>
                      </React.Fragment>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    height: "333px",
                    width: { md: "500px", sm: "500px", xs: "100%" },
                  }}
                >
                  {/* <img
                    width="100%"
                    height="100%"
                    aspect-ratio="auto 500 / 300"
                    src={singleBlogData?.base_image_uri}
                    alt=""
                    loading="lazy"
                  /> */}

                  <LazyImage
                    skeletonSrc={LoadingImage}
                    actualSrc={`${URL}${singleBlogData?.base_image_uri}`}
                    alt={singleBlogData?.name}
                    width="100%"
                    height="100%"
                    aspectratio="auto 500 / 300"
                  />
                </Box>
                <Box
                  sx={{
                    lineHeight: "32px",
                    fontSize: "17px",
                    color: "#555",
                  }}
                >
                  {MyComponent(singleBlogData?.content)}
                </Box>
                {/* See More Post Block  */}
                <Box
                  sx={{
                    display: "flex",
                    padding: "35px",
                    lineHeight: "23px",
                    background: "#f5f5f5",
                    gap: "35px",
                    // alignItems: "start",
                  }}
                >
                  <Box borderRadius="50%" overflow="hidden">
                    <img
                      // src="https://secure.gravatar.com/avatar/173a8e01aacd06d39e660ed526c4b086?s=90&d=mm&r=g"
                      src={`${URL}${singleBlogData?.auther?.image}`}
                      alt=""
                      loading="lazy"
                      height="100%"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      // alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Typography sx={{ letterSpacing: "0.7px" }}>
                      {singleBlogData?.auther?.name}
                    </Typography>
                    <Typography
                      sx={{
                        // fontFamily: "Open Sans",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // color: theme.palette.primary.main,
                      }}
                    >
                      <Link
                        to={`/blogs/author/${singleBlogData?.auther?.name
                          ?.split(" ")
                          ?.join("-")
                          ?.toLowerCase()}`}
                        // to="/blogs"
                        onClick={goToTop}
                        style={{ display: "flex" }}
                        state={{ id: singleBlogData?.auther?.id }}
                      >
                        See All Authors Post
                        <Icon
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ArrowForwardIosIcon />
                        </Icon>
                      </Link>
                    </Typography>
                  </Box>
                </Box>

                {/* <NavigationBox
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                /> */}
                <>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "50px 0",
                        // border: "1px solid",
                        fontFamily: "Open Sans, Sans-serif",
                        gap: "12px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "40%",
                          "&:hover": {
                            color: "#EC0A90",
                          },
                        }}
                      >
                        {Object.keys(singleBlogData?.previos_blog)?.length >
                        0 ? (
                          <Box
                            onClick={() =>
                              handlePrev(
                                singleBlogData?.previos_blog?.id,
                                singleBlogData?.previos_blog?.title
                              )
                            }
                            sx={{ textDecoration: "none", cursor: "pointer" }}
                          >
                            <Typography
                              sx={{
                                display: "flex",
                                color: "#555",
                                "&:hover": {
                                  color: "#EC0A90",
                                },
                              }}
                            >
                              <ArrowBackIcon />
                              PREVIOUS
                            </Typography>
                            <Typography
                              sx={{ fontWeight: "700", wordWrap: "break-word" }}
                            >
                              {singleBlogData?.previos_blog?.title}
                            </Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box
                        sx={{
                          width: "40%",
                          "&:hover": {
                            color: "#EC0A90",
                          },
                        }}
                      >
                        {Object.keys(singleBlogData?.next_blog)?.length > 0 ? (
                          <Box
                            onClick={() =>
                              handleNext(
                                singleBlogData?.next_blog?.id,
                                singleBlogData?.next_blog?.title
                              )
                            }
                            sx={{ textDecoration: "none", cursor: "pointer" }}
                          >
                            <Typography
                              sx={{
                                display: "flex",
                                color: "#555",
                                "&:hover": {
                                  color: "#EC0A90",
                                },
                              }}
                            >
                              NEXT
                              <ArrowForwardIcon />
                            </Typography>
                            <Typography
                              sx={{ fontWeight: "700", wordWrap: "break-word" }}
                            >
                              {singleBlogData?.next_blog?.title}
                            </Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              </Box>
              <Box sx={{ width: { xs: "100%", md: "75%" } }}>
                <hr style={{ width: "100%" }} />
              </Box>
            </Box>
            <br />
            <br />

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row-reverse",
                width: "100%",
              }}
            >
              <Typography
                id="comment-heading"
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  width: { xs: "100%", md: "75%" },
                  marginBottom: "10px",
                }}
              >
                Comments {` ${singleBlogData?.comments?.length} `}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row-reverse",
                  width: "100%",
                }}
              >
                <GetCommentBox singleBlogData={singleBlogData} />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row-reverse",
              }}
            >
              <CommentBox
                data={singleBlogData}
                payload={payload}
                setPayload={setPayload}
                handleOnchange={handleOnchange}
                handleSubmit={handleSubmit}
              />
            </Box>
          </section>
        </ContainerBox>
      )}
    </>
  );
};
