import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  LOGOUT,
} from "./action.types/login.types";
import { errorToaster, successToaster } from "utils/toaster";

import { LOGIN_API } from "redux/constantAPI";
import { guestAction } from "./guest.actions";
// import { loginApi } from "redux/restApi";

export const login =
  (
    email: string,
    password: string,
    guestToken: string

    //@ts-ignore
  ) =>
  async (dispatch: any) => {
    dispatch({
      type: LOGIN_REQUEST,
      payload: {
        email: JSON.stringify(email),
        password: JSON.stringify(password),
        guestToken: JSON.stringify(guestToken),
      },
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${LOGIN_API}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            AUTHORIZATION: guestToken,
          },
          body: JSON.stringify({ email, password, guestToken }),
        }
      );

      if (response.ok) {
        const user = await response.json();
        dispatch({ type: LOGIN_SUCCESS, payload: { user } });
        localStorage.setItem("logtoken", user?.data?.token);
        localStorage.removeItem("guesttoken");
        localStorage.setItem("displayName", user?.data?.display_name);
        successToaster("user logged in");
        // window.location.reload()
      } else {
        const error = await response.text();
        const errorMsg = JSON.parse(error);
        dispatch({ type: LOGIN_FAILURE, payload: { error } });

        errorToaster(errorMsg?.message);
      }
    } catch (error: any) {
      dispatch({ type: LOGIN_FAILURE, payload: { error: error.message } });
      errorToaster(error);
    }
  };

export const logout = () => {
  successToaster("User logged out");
  localStorage.clear();
  return async (dispatch: any) => {
    dispatch(guestAction());
    dispatch({ type: LOGOUT });
    window.location.reload();
  };
};
