import { Box, Typography } from "@mui/material";
import ArrowCircleRightOutlined from "@mui/icons-material/ArrowCircleRightOutlined";
import { BannerImg } from "assets";
import { BasicButton } from "components";
import { useNavigate } from "react-router-dom";

type Props = {};

export const Banner = (props: Props) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        height: { xs: "", sm: "100%", md: "80vh", lg: "70vh " },
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "50%" },
          padding: "50px",
          backgroundColor: "#1A1E5D",
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          fontSize="80px"
          fontWeight="500"
          fontFamily="Oswald"
          lineHeight={1.2}
        >
          RUSH <br />
          PRINTING
        </Typography>
        <Typography fontSize="35px" fontWeight="700">
          24 - 48 Hour Turnaround
        </Typography>
        <Typography
          fontSize="24px"
          fontWeight="500"
          borderLeft="3px solid #FCD537"
          sx={{ width: { xs: "100%", sm: "70%" }, paddingLeft: "20px" }}
        >
          We are open to support Calgary businesses producing rush orders
          everyday.
        </Typography>
        <BasicButton
          padding="45px 40px"
          height="75px"
          width={{ xs: "95%", sm: "100%", md: "70%" }}
          fontSize="25px"
          textAlign="center"
          margin="20px 0"
          placeholder="REQUEST A QUOTE"
          icon={<ArrowCircleRightOutlined />}
          //change made by suraj
          onClick={() => navigate("/request-a-quote")}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", sm: "50%" },
          background: `url(${BannerImg}) no-repeat left / cover`,
        }}
      ></Box>
    </Box>
  );
};
