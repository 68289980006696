export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export interface RegisterRequestAction {
  type: typeof REGISTER_REQUEST;
  payload: {
    email: string;
  };
}

export interface RegisterSuccessAction {
  type: typeof REGISTER_SUCCESS;
  payload: {
    //@ts-ignore
    user: User;
  };
}

export interface RegisterFailureAction {
  type: typeof REGISTER_FAILURE;
  payload: {
    error: string;
  };
}

export type RegisterActionTypes =
  | RegisterRequestAction
  | RegisterSuccessAction
  | RegisterFailureAction;
