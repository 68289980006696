import { Box } from "@mui/material";
import {
  Cards,
  ContainerBox,
  LoadingComponent,
  SectionHeading,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch, useEffect } from "react";
import { getWishlistAction } from "redux/Actions/wishlist.actions";
import { Grid } from "@mui/material";

type Props = {};

export const Wishlist = (props: Props) => {
  const loading = useSelector(
    (state: any) => state?.allProductListReducer?.loading
  );
  const getWishlist = useSelector(
    (state: any) => state?.getWishlistReducer?.listArr?.data?.data
  );

  const dispatch: Dispatch<any> = useDispatch();
  useEffect(() => {
    dispatch(getWishlistAction());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <ContainerBox>
          <section>
            <SectionHeading heading="My Wishlist" />
            {getWishlist && getWishlist?.length ? (
              <Box sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
                <Grid container spacing={2}>
                  {getWishlist?.map((item: any, index: number) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <Cards
                          height="300px"
                          key={index}
                          Products={item}
                          maxWidth="300px"
                          wishlist={true}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "10px 0",
                  fontSize: "1.3rem",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <p>No Items to show</p>
                <Link to="/products">
                  <p style={{ textDecoration: "underline" }}>Browse Products</p>
                </Link>
              </Box>
            )}
          </section>
        </ContainerBox>
      )}
    </>
  );
};
