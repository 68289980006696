import { faq } from "redux/restApi";
import { FAQ_TYPE } from "./action.types/action.types";


export const requestFaq = () => {
  return {
    type: FAQ_TYPE.REQUEST,
  };
};

export const successFaq = (payload: any) => {
  return {
    type: FAQ_TYPE.SUCCESS,
    payload,
  };
};

export const faliureFaq = (payload: any) => {
  return {
    type: FAQ_TYPE.FALIURE,
    payload,
  };
};

export const faqAction = () => {
  return (dispatch: any) => {
    dispatch(requestFaq());
    faq()
      .then((response) => {
        if(response?.status === 200 && response){
          dispatch(successFaq(response));
        }
      })
      .catch((error) => dispatch(faliureFaq(error)));
  };
};
