import React, { Dispatch, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { AddToCartButton } from "components/Button";
import Counter from "components/Counter/Counter";
import Ratings from "components/Ratings";
import { goToTop } from "utils/goToTop";
import { useDispatch } from "react-redux";
import {
  addToCartAction,
  singleProductAction,
} from "redux/Actions/product.actions";
import { useTheme } from "@mui/material/styles";
import { WishlistItem } from "components/Button/WishlistItem";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { BasicModal } from "components/Modal";
import { SingleProduct } from "pages";
import { ToHTML } from "utils/ToHTML";
import { ProductSwiperMain } from "./SwiperCard/ProductSwiperMain";

type Props = {
  Products?: any | undefined;
  maxWidth?: any | undefined;
  height?: any | undefined;
  isCart?: boolean;
  flexDirection?: any | undefined;
  isList?: boolean;
  wishlist?: any;
};

export const Cards = ({
  Products,
  maxWidth,
  height,
  isCart,
  flexDirection,
  isList,
  wishlist,
}: Props) => {
  const theme = useTheme();
  const dispatch: Dispatch<any> = useDispatch();

  const [quantity, setQuantity] = useState(1);

  const setDecrease = () => {
    quantity > Products?.min_qty ? setQuantity(quantity - 1) : setQuantity(1);
  };

  const setIncrease = () => {
    quantity <= Products?.max_qty - 1
      ? setQuantity(quantity + 1)
      : setQuantity(quantity);
  };

  const addToCart = (product_id: any, quantity: any) => {
    const buyNow = false;
    dispatch(addToCartAction(product_id, quantity, [], buyNow));
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleModalOpen = (id: any) => {
    setIsModalOpen(true);
    if (id) {
      dispatch(singleProductAction(id));
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          marginBottom: "30px",
          "&:hover": {
            boxShadow: "0px 0px 0px 1px #e1e1e1",
          },
        }}
      >
        <Card
          sx={{
            // maxWidth: maxWidth,
            padding: " 24px 24px 20px",
            boxShadow: "none",
            display: "flex",
            flexDirection: flexDirection ? flexDirection : "column",
            // gap: " 2rem",
            gap: isList ? "2rem" : "",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            sx={{
              overflow: "hidden",
              height: height,
              position: "relative",
              width: maxWidth,
            }}
          >
            <Link
              to={`/products/${Products?.name
                .toLowerCase()
                .split(" ")
                .filter((elem: any) => elem !== "–")
                .join("-")}/`}
              state={{
                id: Products?.id,
              }}
              // onClick={goToTop}
            >
              <ProductSwiperMain data={Products?.image_url} />
            </Link>

            {isHovered && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0px",
                  left: "0px",
                  // backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  zIndex: "111",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                  }}
                >
                  <WishlistItem
                    productId={Products?.id}
                    isWishlisted={Products?.is_wishlist}
                  />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "white",
                  }}
                  onClick={() => handleModalOpen(Products?.id)}
                >
                  <IconButton disableFocusRipple disableRipple>
                    <RemoveRedEyeIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>

          {/* <Link to={`singleProduct/${Products?.id}`} onClick={goToTop}> */}
          <BasicModal
            open={isModalOpen}
            handleModalClose={handleModalClose}
            handleModalOpen={handleModalOpen}
          >
            {/* <Link to={`/singleProduct/${Products?.id}`} onClick={goToTop}> */}
            <SingleProduct flag={1} />
            {/* </Link> */}
          </BasicModal>
          {/* </Link> */}

          <Box
            sx={{
              padding: isList ? "" : "25px 0 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: isList ? "1rem" : "",
            }}
          >
            {isList ? (
              <Box sx={{ width: "50vw" }}>
                {ToHTML(Products?.short_description)}
              </Box>
            ) : null}

            <Box
              sx={{
                // width: "90%",
                // margin: "0 auto",
                display: "flex",
                gap: "10px",
              }}
            >
              <Counter
                setQuantity={setQuantity}
                quantity={quantity}
                setDecrease={setDecrease}
                setIncrease={setIncrease}
                item={Products}
                singleProduct={true}
              />
              <AddToCartButton
                onClick={() => addToCart(Products?.id, quantity)}
              />
            </Box>

            {/* here padding is given (Suraj)*/}

            <CardContent sx={{ padding: "16px 0" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "start",
                  justifyContent: "space-between",
                  // margin: "5px 0 12px",
                  // width: "100%",
                }}
              >
                <Box>
                  <Link
                    to={`/products/${Products?.name
                      .toLowerCase()
                      .split(" ")
                      .filter((elem: any) => elem !== "–")
                      .join("-")}/`}
                    state={{
                      id: Products?.id,
                    }}
                    onClick={goToTop}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                        lineHeight: "20px",
                      }}
                    >
                      {Products?.name}
                    </Typography>
                  </Link>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      margin: "5px 0 12px",
                    }}
                  >
                    <Ratings value={Products?.overall_rating} readOnly={true} />
                    {/* here sx is added in typography Suraj  */}
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: "16px", lineHeight: 1.2 }}
                    >
                      {Products?.overall_rating}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Typography
                variant="body2"
                sx={{
                  color: theme?.palette?.primary?.main,
                  fontSize: "16px",
                  fontWeight: "bold",
                  lineHeight: "20px",
                }}
              >
                {`$${Products?.base_price}`}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: theme?.palette?.primary?.main,
                  fontSize: "16px",
                  fontWeight: "bold",
                  lineHeight: "20px",
                }}
              ></Typography>
            </CardContent>
          </Box>
        </Card>
      </Box>
    </>
  );
};
