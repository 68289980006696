import React from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  heading: string | undefined;
  justifyContent?: string | undefined;
  color?: any | undefined;
  zIndex?: any | undefined;
  fontSize?: any | undefined;
  fontFamily?: any | undefined;
  marginBottom?: any | undefined;
};

export const SectionHeading = (props: Props) => {
  return (
    <Box
      display="flex"
      marginBottom=  {props?.marginBottom || "20px"}
      justifyContent={props?.justifyContent ? props?.justifyContent : "center"}
      alignItems="center"
      zIndex={props.zIndex}
    >
      <Typography
        fontSize={props.fontSize ? props.fontSize : "50px"}
        color={props.color ? props.color : "inherit"}
        fontFamily={props?.fontFamily}
        fontWeight="600"
        sx={{
          fontSize: props.fontSize
            ? props.fontSize
            : { md: "50px", sm: "50px", xs: "30px" },
          fontWeight: "500",
          letterSpacing: "0.7px",
          textAlign: "center",
          fontFamily: props?.fontFamily ? props?.fontFamily : "oswald",
        }}
      >
        {props?.heading}
      </Typography>
    </Box>
  );
};
