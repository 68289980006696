import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography, Box } from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { BasicButton } from "components/Button";
import { useTheme } from "@mui/material/styles";
import "./card.css";
import { useNavigate } from "react-router-dom";
import { goToTop } from "utils/goToTop";

type Props = {
  flexDirection?: string | undefined;
  maxWidth?: string | undefined;
  imgWidth?: string | undefined;
  isHorizontal?: boolean | undefined;
  data?: any | undefined;
};

export const DisplayCard = ({
  flexDirection,
  maxWidth,
  imgWidth,
  isHorizontal,
  data,
}: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <>
      {data?.map((item: any, index: number) => {
        return (
          <Box key={index}>
            <Card
              sx={{
                border: "none",
                boxShadow: isHorizontal ? "rgba(0, 0, 0, 0) 0px 0px 0px" : "",

                maxWidth: maxWidth ? maxWidth : 362,
                display: "flex",
                flexDirection: {
                  xs: "column-reverse",
                  sm: flexDirection
                    ? index % 2 === 0
                      ? "row"
                      : "row-reverse"
                    : "column",
                  md: flexDirection
                    ? index % 2 === 0
                      ? "row"
                      : "row-reverse"
                    : "column",
                },
                alignItems: "center",
                justifyContent: "space-around",
                padding: "20px",
                transition: " box-shadow 0.3s",
                "&:hover": {
                  boxShadow: isHorizontal
                    ? ""
                    : "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                },
                // height: "100%",
              }}
            >
              {/* <LazyImage
                alt="Promotional image"
                skeletonSrc={LoadingImage}
                actualSrc={item?.base_image_url}
                width="100%"
                height="100%"
                aspectratio="auto 500 / 300"
              /> */}

              <CardMedia
                sx={{ padding: "13px", width: imgWidth }}
                component="img"
                alt="green iguana"
                loading="lazy"
                image={item?.base_image_url}
              />

              <CardContent
                sx={{
                  padding: "20px",
                  margin: "23px 0 5px 0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: isHorizontal ? "1rem" : "",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h3"
                  component="div"
                  color="#000000"
                  fontFamily="Oswald"
                  fontSize="27px"
                  fontWeight="600"
                  lineHeight="1.6em"
                  letterSpacing="0.7px"
                  textAlign="center"
                >
                  {item?.title}
                </Typography>
                <Typography
                  sx={{ color: theme.palette.primary.main }}
                  fontSize="17px"
                  fontWeight={500}
                  textAlign="center"
                >
                  {item?.sub_title}
                </Typography>
                <Typography
                  sx={{ color: theme.palette.primary.main }}
                  textAlign="center"
                >
                  --------------------------
                </Typography>
                <Typography
                  variant="h4"
                  color="#000000"
                  fontSize="25px"
                  fontWeight={600}
                  textAlign="center"
                >
                  Promo Code – {item?.promo_code}
                </Typography>
                <CardActions sx={{ marginBottom: "11px" }}>
                  <BasicButton
                    placeholder="REQUEST A QUOTE"
                    fontSize="22px"
                    icon={<ArrowCircleRightOutlinedIcon />}
                    // change by suraj
                    // onClick={() => navigate("/send-your-files")}
                    onClick={() => {
                      navigate("/request-a-quote");
                      goToTop();
                    }}
                  />
                </CardActions>
              </CardContent>
            </Card>
          </Box>
        );
      })}
    </>
  );
};

export default DisplayCard;
