import {
  ADD_REVIEW_API,
  ALL_BLOGS_API,
  ALL_PRODUCT_API,
  CHANGE_PASSWORD_API,
  DESIGN_GALLERY,
  FAQ_API,
  FILE_UPLOAD_API,
  GET_CART_API,
  GET_CART_CHECKOUT_API,
  GET_COUPON_VERIFICATION_API,
  GET_TEMPLATE_API,
  GET_USER_ADDRESS_API,
  GET_USER_PROFILE_API,
  GET_WISHLIST_API,
  GUEST_USER_API,
  LOGIN_API,
  LOST_PASSWORD_API,
  ORDER_API,
  POST_BLOG_COMMENTS,
  PROMOTION_LIST_API,
  REQUEST_A_QUOTE_API,
  UPDATE_CART_PRODUCT_QUANTITY_API,
  UPDATE_PRODUCT_BASKET_API,
} from "./constantAPI";
import instance from "./instance";

// LOGIN API

export const guestUser = async () => {
  const result = await instance.post(GUEST_USER_API, {
    headers: { "Content-Type": "application/json" },
  });
  return result;
};

export const loginApi = async (data: any) => {
  const result: any = await instance.post(LOGIN_API, data, {
    headers: {
      AUTHORIZATION: data.guestToken,
    },
  });
  return result;
};

// LIST

export const allProductList = async (sort?: any) => {
  let token =
    localStorage.getItem("logtoken") || localStorage.getItem("guesttoken");

  try {
    let result;

    if (sort !== undefined) {
      result = await instance.get(`${ALL_PRODUCT_API}?sort_by=${sort}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
      result = await instance.get(ALL_PRODUCT_API, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }

    return result; // Return the data from the API response
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export const orderList = async () => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(`${ORDER_API}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const singleOrderList = async (id: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(`${ORDER_API}${id}/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const singleProduct = async (id?: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(`${ALL_PRODUCT_API}${id}/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const allBlogsList = async (id?:any , categoryId?:any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  let result;
  if(id){
     result = await instance.get(`${ALL_BLOGS_API}?auther=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }else if(categoryId){
     result = await instance.get(`${ALL_BLOGS_API}?category=${categoryId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }else{
    result = await instance.get(`${ALL_BLOGS_API}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
  return result;
};

export const singleBlogList = async (id: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(`${ALL_BLOGS_API}${id}/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const promotionList = async () => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else{
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(PROMOTION_LIST_API, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const designGallery = async () => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(DESIGN_GALLERY, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const faq = async () => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(FAQ_API, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const template = async () => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(GET_TEMPLATE_API, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const getCart = async () => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(GET_CART_API, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const getWishlist = async () => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(GET_WISHLIST_API, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const getUserAddress = async () => {
  let token = localStorage.getItem("logtoken");
  const result = await instance.get(GET_USER_ADDRESS_API, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const getUserProfile = async () => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(GET_USER_PROFILE_API, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return result;
};
// get update basket

export const getProductBasket = async (id: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(`${GET_CART_API}${id}/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};
// update product basket
export const updateProductBasket = async (id: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.get(`${UPDATE_PRODUCT_BASKET_API}${id}/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

// lost password api
export const getLostPassword = async (data: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.post(`${LOST_PASSWORD_API}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const postBlogComments = async (data: any) => {
  
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.post(`${POST_BLOG_COMMENTS}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

// change Password
export const changePassword = async (data: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.post(`${CHANGE_PASSWORD_API}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

//PATCH API

export const patchUserAddress = async (data: any) => {
  let token = localStorage.getItem("logtoken");

  const result = await instance.patch(GET_USER_ADDRESS_API, data, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return result;
};

export const patchWishlist = async (data: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.patch(GET_WISHLIST_API, data, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return result;
};

export const patchUserProfile = async (data: any) => {
  let token = localStorage.getItem("logtoken");
  const result = await instance.patch(GET_USER_PROFILE_API, data, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return result;
};
// place order list
export const placeOrderList = async (id: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.patch(`${ORDER_API}${id}/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};
// update Cart Product quantity
export const updateCartProductQuantity = async (data: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.post(
    `${UPDATE_CART_PRODUCT_QUANTITY_API}`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return result;
};

export const deleteCart = async (id: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.delete(`${GET_CART_API}${id}/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const getCartCheckoutApi = async (payload: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.post(
    `${GET_CART_CHECKOUT_API}`,
    JSON.stringify(payload),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return result;
};

// add review

export const addReview = async (payload: any) => {
  let token = localStorage.getItem("logtoken");

  const result = await instance.post(
    `${ADD_REVIEW_API}`,
    JSON.stringify(payload),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return result;
};

export const getCouponVerificationApi = async (payload: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }

  const result = await instance.post(
    `${GET_COUPON_VERIFICATION_API}`,
    JSON.stringify(payload),
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return result;
};

// REQUEST A QUOTE
export const requestAQuote = async (data: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const result = await instance.post(`${REQUEST_A_QUOTE_API}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result;
};

export const fileUpload = async (data: any, cartId: any) => {
  let token = localStorage.getItem("guesttoken");
  if (localStorage.getItem("logtoken")) {
    token = localStorage.getItem("logtoken");
  } else {
    token = localStorage.getItem("guesttoken");
  }
  const formData = new FormData();
  // formData.append(vscode-file://vscode-app/c:/Users/durap/AppData/Local/Programs/Microsoft%20VS%20Code/resources/app/out/vs/code/electron-sandbox/workbench/workbench.html"file", data);
  data?.map((item: any) => {
    return formData.append("file", item);
  });
  formData.append("cart_id", cartId);
  const result = await instance.post(`${FILE_UPLOAD_API}`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return result;
};
