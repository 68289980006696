import { COUPON_UPDATED, GET_CART_CHECKOUT } from 'redux/Actions/action.types/order.types';
import {
  ADD_TO_CART_TYPE,
  ALL_PRODUCT_LIST_TYPE,
  DELETE_CART_TYPE,
  GET_CART_TYPE,
  GET_PRODUCT_BASKET_TYPE,
  SINGLE_PRODUCT_TYPE,
  UPDATE_PRODUCT_BASKET_TYPE,
} from "../Actions/action.types/action.types";

const initialState = {
  listArr: [],
  loading: true,
  cartCheckout: {}
};

export const allProductListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ALL_PRODUCT_LIST_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_PRODUCT_LIST_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload.data,
        loading: false,
      };
    case ALL_PRODUCT_LIST_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const singleProductReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SINGLE_PRODUCT_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SINGLE_PRODUCT_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case SINGLE_PRODUCT_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getCartReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CART_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CART_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case GET_CART_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addToCartReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_TO_CART_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_TO_CART_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case ADD_TO_CART_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteCartReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DELETE_CART_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CART_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case DELETE_CART_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// get update basket

export const getProductBasketReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PRODUCT_BASKET_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCT_BASKET_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case GET_PRODUCT_BASKET_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// UPDATE BASKET
export const updateProductBasketReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_PRODUCT_BASKET_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PRODUCT_BASKET_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case UPDATE_PRODUCT_BASKET_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};


export const getCartCheckoutReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CART_CHECKOUT.REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_CART_CHECKOUT.SUCCESS:
      return {
        ...state,
        cartCheckout: action.payload,
        loading: false
      };

    case GET_CART_CHECKOUT.FALIURE:
      return {
        ...state,
        loading: false,
        listArr: action.payload
      };

    default:
      return state;
  }
}

export const getCouponUpdated = (state = {}, action: any) => {
  switch (action.type) {
    case COUPON_UPDATED.TRUE:
      return {
        ...state,
        updated: true
      }

    case COUPON_UPDATED.FALSE:
      return {
        ...state,
        updated: false
      }

    case COUPON_UPDATED.REMOVE:
      return {
        ...state,
        removed: true,
        updated: false
      }

    default:
      return { updated: false }
  }
}

