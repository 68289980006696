import React, { useState } from "react";

type Props = {
  imgs?: any;
};
const URL = process.env.REACT_APP_API_URL;
const MyImage = ({ imgs }: Props) => {
  const [selectedImage, setSelectedImage] = useState(0);

  const handleImageClick = (index: any) => {
    setSelectedImage(index);
  };

  return (
    <div
      style={{
        display: "flex",

        flexDirection: "column",
        gap: "20px",
      }}
    >
      <img
        src={`${URL}${imgs[selectedImage]}`}
        alt={`Product ${selectedImage + 1}`}
        style={{
          width: "400px",
          height: "400px",
          transition: "opacity 0.5s ease",
          // border: "1px solid",
        }}
      />
      <div
        style={{
          display: "flex",
          // flexDirection: "column",
          marginRight: "20px",
          alignItems: "center",
          gap: "10px",
          overflow: "auto",
          maxWidth: "400px",
        }}
      >
        {imgs?.map((url: any, index: any) => (
          <div
            key={index}
            style={{ marginBottom: "10px" }}
            // id={`set${currentSet}`}
            onClick={() => handleImageClick(index)}
          >
            <img
              src={`${URL}${url}`}
              // alt={`Product ${startIndex + index + 1}`}
              alt=""
              style={{
                width: "50px",
                height: "50px",
                border: index === selectedImage ? "2px solid #007bff" : "none",
                cursor: "pointer",
                transition: "opacity 0.5s ease",
                // opacity: index === selectedImage || changingImage ? 1 : 0.5,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyImage;
