import { CHANGE_PASSWORD_TYPE } from "redux/Actions/action.types/change.password.types";

const initialState = {
  listArr: [],
  loading: true,
};

export const changePasswordReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHANGE_PASSWORD_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_PASSWORD_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case CHANGE_PASSWORD_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
