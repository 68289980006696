export function ToHTML(content: any) {
  const data = (
    <div
      //@ts-ignore

      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
  return data;
}
