import React, { SVGProps } from "react";
import { Typography } from "@mui/material";
import { Theme } from "@material-ui/core";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

type Props = {
  placeholder?: string | undefined;
  icon?: SVGProps<Theme> | any;
  height?: any | undefined;
  width?: any | undefined;
  margin?: any | undefined;
  fontSize?: any | undefined;
  textAlign?: any | undefined;
  padding?: any | undefined;
  flexDirection?: any | undefined;
  buttonType?: any | undefined;
  onClick?: any;
  borderRadius?: string;
  isBlue?: boolean;
};

export const BasicButton = (props: Props) => {
  const theme = useTheme();
  return (
    <Button
      sx={{
        padding: props?.padding ? props?.padding : "15px 20px 15px 20px",
        borderRadius: props?.borderRadius
          ? props?.borderRadius
          : " 0px 0px 0px 0px",
        height: props?.height,
        width: props?.width,
        margin: props?.margin,
        fontWeight: "bold",
        textTransform: "uppercase",
        backgroundColor: props?.isBlue
          ? "#00AEEF"
          : theme?.palette?.primary?.main,

        borderStyle: "none",
        // borderRadius: "0px 0px 0px 0px",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        textAlign: props?.textAlign,
        alignItems: "center",
        flexDirection: props?.flexDirection,
        gap: "10px",
        transition: "all .2s linear",
        cursor: "pointer",
        "&:hover": {
          color: "#ffffff",
          backgroundColor: "#00AEEF",
        },
      }}
      onClick={props?.onClick}
      type={props?.buttonType}
    >
      <Typography
        sx={{
          fontSize: props?.fontSize ? props?.fontSize : "17px",
          fontWeight: "bold",
        }}
      >
        {props?.placeholder}
      </Typography>
      <Typography display="flex">{props?.icon}</Typography>
    </Button>
  );
};
