import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import { goToTop } from "utils/goToTop";

type Props = {
  totalPosts?: any | undefined;
  paginate?: any | undefined;
  postsPerPage?: any | undefined;
  currentPage?: any | undefined;
};

export default function PaginationComponent(props: Props) {
  const [page, setPage] = useState(1);

  let pageNumbers = [];

  for (let i = 1; i <= Math.ceil(props.totalPosts / props.postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleChange = (event: any, value: any) => {
    goToTop();
    setPage(value);
    props.paginate(value);
  };

  return (
    <>
      <Pagination
        count={pageNumbers?.length}
        page={page}
        onChange={handleChange}
      />
    </>
  );
}
