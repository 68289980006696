import { Box } from "@mui/material";
import { MediaCard } from "components";
import ImageViewer from "components/GalleryList";
import { DesignMediaCard } from "assets";

type Props = {};

export const DesignGallery = (props: Props) => {
  return (
    <>
      <Box sx={{ marginBottom: "60px" }}>
        <MediaCard
          title="Design Gallery"
          minHeight="250px"
          backgroundImage={DesignMediaCard}
        />
      </Box>
      <section>
        <Box sx={{ padding: "0 3rem" }}>
          <ImageViewer />
        </Box>
      </section>
    </>
  );
};
