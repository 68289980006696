import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = {
  icon?: any | undefined;
  badgeContent?: any | undefined;
};

export const Notification = (props: Props) => {
  const CustomBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#fcd537",
      fontWeight: "bold",
      fontSize: "16px",
      color: "#000",
    },
  }));
  return (
    <CustomBadge badgeContent={props?.badgeContent}>{props?.icon}</CustomBadge>
  );
};
