import { REQUEST_A_QUOTE_TYPE } from "redux/Actions/action.types/action.types";

const initialState = {
  listArr: [],
  loading: true,
};

export const requestAQuoteReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case REQUEST_A_QUOTE_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_A_QUOTE_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case REQUEST_A_QUOTE_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
