import React, { useEffect } from "react";
import { Box, TextField, Typography, MenuItem } from "@mui/material";
import { BasicButton, Required } from "components";
import { BasicInput } from "components/Input/BasicInput";
import { stateWiseTax } from "utils/constant";

type Props = {
  title?: any;
  data: any;
  isCheckout?: boolean;
  none?: boolean;
  formData?: any;
  setFormData?: any;
  handleSubmit?: any;
  handleChange?: any;
};

export const AddressForm = ({
  title,
  data,
  isCheckout,
  none,
  formData,
  setFormData,
  handleSubmit,
  handleChange,
}: Props) => {
  useEffect(() => {
    setFormData(data);
  }, [data, setFormData]);

  useEffect(() => {
    const selectedProvince = document.getElementById("provinceSelectBox");
    //@ts-ignore
    sessionStorage.setItem("province", selectedProvince?.textContent);
  }, []);
  return (
    <Box>
      <Typography sx={{ fontSize: "1.6rem", fontWeight: "600" }}>
        {title}
      </Typography>

      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          {/* First Name */}
          <BasicInput
            isRequired
            type="name"
            size="100%"
            label="First Name"
            color="#000"
            name="first_name"
            value={formData?.first_name}
            onChange={handleChange}
          />

          {/* Last Name */}
          <BasicInput
            type="name"
            size="100%"
            color="#000"
            label="Last Name"
            isRequired
            name="last_name"
            value={formData?.last_name}
            onChange={handleChange}
          />
        </Box>

        {/* Company Name */}
        <Box>
          <BasicInput
            type="name"
            size="100%"
            color="#000"
            label="Company name (optional)"
            name="company_name"
            value={formData?.company_name}
            onChange={handleChange}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyItems: "space-between",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <BasicInput
            type="name"
            size="100%"
            color="#000"
            label="Country"
            isRequired
            // value={formData?.country_name}
            value="Canada"
            InputProps={{
              readOnly: true,
            }}

            // onChange={handleChange}
          />
          {/* Street Address 1 */}
          <BasicInput
            type="name"
            size="100%"
            color="#000"
            label="Street address"
            isRequired
            name="street_address_1"
            placeholder="Street Address 1"
            value={formData?.street_address_1}
            onChange={handleChange}
          />

          {/* Street Address 2 */}
          <BasicInput
            type="name"
            size="100%"
            color="#000"
            // isRequired
            name="street_address_2"
            placeholder="Street Address 2(Optional)"
            value={formData?.street_address_2}
            onChange={handleChange}
          />
        </Box>

        <Box>
          {/* Town / City */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <BasicInput
              type="name"
              size="100%"
              color="#000"
              label="Town / City"
              isRequired
              name="town_city"
              value={formData?.town_city}
              onChange={handleChange}
            />

            {/* Province */}
            <Typography>
              Province <Required />
            </Typography>
            <TextField
              select
              size="small"
              id="provinceSelectBox"
              fullWidth
              // label="Province"
              // value={formData?.province === "" ? stateWiseTax[0]?.province : formData?.province} //? this is to be enabled for production as province can be fetched from api
              defaultValue={stateWiseTax[0]?.province}
              name="province"
              onChange={handleChange}
              // sx={{
              //   "& .css-1dfj7k1-MuiFormLabel-root-MuiInputLabel-root": {
              //     left: "-8px",
              //     top: "-9px",
              //   },
              // }}
            >
              {stateWiseTax?.map((item: any, index: number) => (
                <MenuItem key={index} value={item.province}>
                  {item.province}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          {/* Postcode */}
          <BasicInput
            type="postcode"
            size="100%"
            color="#000"
            label="Postcode"
            isRequired
            name="postcode"
            value={formData?.postcode}
            onChange={handleChange}
          />

          {/* Phone */}
          <BasicInput
            type="phone"
            size="100%"
            color="#000"
            label="Phone"
            isRequired
            name="phone"
            value={formData?.phone}
            onChange={handleChange}
            isPhone
          />

          {/* Email Address */}
          <BasicInput
            type="emailid"
            size="100%"
            color="#000"
            label="Email address"
            isRequired
            name="email_address"
            value={formData?.email_address}
            onChange={handleChange}
          />
        </Box>

        {!none ? (
          <Box sx={{ paddingTop: "1rem" }}>
            <BasicButton placeholder="Save Address" buttonType="submit" />
          </Box>
        ) : (
          ""
        )}
      </form>
    </Box>
  );
};
