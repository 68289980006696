import React from "react";
import { Box } from "@mui/material";
type Props = {
  children: React.ReactNode;
  display?: string | undefined;
  justifyContent?: string | undefined;
  alignItems?: string | undefined;
  flexDirection?: any | undefined;
  maxWidth?: any | undefined;
  overflow?: any;
};

export const ContainerBox = ({
  children,
  display,
  justifyContent,
  alignItems,
  flexDirection,
  maxWidth,
  overflow,
}: Props) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: maxWidth ? maxWidth : "1080px",
          margin: "auto",
          display: display,
          justifyContent: justifyContent,
          alignItems: alignItems,
          flexDirection: flexDirection,
          padding: { xs: "0 20px", lg: "0" },
          overflow: overflow,
        }}
      >
        {children}
      </Box>
    </>
  );
};
