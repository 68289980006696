import {
  GET_USER_ADDRESS_TYPE,
  GET_USER_PROFILE_TYPE,
  PATCH_USER_ADDRESS_TYPE,
  PATCH_USER_PROFILE_TYPE,
} from "redux/Actions/action.types/user.address.types";

const initialState = {
  listArr: [],
  loading: true,
};
export const getUserAddressReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USER_ADDRESS_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_ADDRESS_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case GET_USER_ADDRESS_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const patchUserAddressReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PATCH_USER_ADDRESS_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PATCH_USER_ADDRESS_TYPE.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case PATCH_USER_ADDRESS_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

/// PROFILE

export const getUserProfileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USER_PROFILE_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_PROFILE_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload,
        loading: false,
      };
    case GET_USER_PROFILE_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const patchUserProfileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PATCH_USER_PROFILE_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PATCH_USER_PROFILE_TYPE.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case PATCH_USER_PROFILE_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
