import { guestUser } from "../restApi";
import { GUEST_USER_TYPE } from "./action.types/guest.types";
import { allBlogsListAction } from "./blog.actions";
import { allProductListAction } from "./product.actions";

export const requestGuest = () => {
  return {
    type: GUEST_USER_TYPE.REQUEST,
  };
};

export const successGuest = (payload: any) => {
  return {
    type: GUEST_USER_TYPE.SUCCESS,
    payload,
  };
};

export const faliureGuest = (payload: any) => {
  return {
    type: GUEST_USER_TYPE.FALIURE,
    payload,
  };
};

export const guestAction = () => {
  return (dispatch: any) => {
    dispatch(requestGuest());
    guestUser()
      .then((response) => {
        if (localStorage.getItem("token") === null || undefined) {
          localStorage.setItem("guesttoken", response.data.data.guest_user_id);
        }
        if(response?.status === 200 && response){
          dispatch(successGuest(response.data));
          dispatch(allBlogsListAction())
          dispatch(allProductListAction())
        }
      })
      .catch((error) => dispatch(faliureGuest(error)));
  };
};

