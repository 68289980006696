import React from "react";
import { Box } from "@mui/material";
import { ProductMediaCard } from "assets";
import { MediaCard, ProductCardList } from "components";
import { NavLink } from "react-router-dom";

type Props = {};

export const Products = (props: Props) => {
  return (
    <>
      <NavLink to="/promotions">
        <MediaCard
          title="Products"
          minHeight="200px"
          backgroundImage={ProductMediaCard}
        />
      </NavLink>

      <Box>
        {/* {productList?.length > 0 ? ( */}
        <>
          <ProductCardList
            height="240px"
            maxWidth="240px"
            sliceData={12}
            // productList={productList}
            pagination="true"
          />
        </>
        {/* // ) : (
          //   <DataNotFound />
          // )} */}
      </Box>
    </>
  );
};
