import { addReview } from "redux/restApi";
import { ADD_REVIEW_TYPE } from "./action.types/action.types";
import { successToaster } from "utils/toaster";

export const requestAddReview = () => {
  return {
    type: ADD_REVIEW_TYPE.REQUEST,
  };
};

export const successAddReview = (payload: any) => {
  return {
    type: ADD_REVIEW_TYPE.SUCCESS,
    payload,
  };
};

export const faliureAddReview = (payload: any) => {
  return {
    type: ADD_REVIEW_TYPE.FALIURE,
    payload,
  };
};

export const addReviewAction = (data: any) => {
  return (dispatch: any) => {
    dispatch(requestAddReview());
    addReview(data)
      .then((response) => {
        dispatch(successAddReview(response));
        successToaster(response?.data?.message);
      })
      .catch((error) => dispatch(faliureAddReview(error)));
  };
};
