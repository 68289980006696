import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { allProductListAction } from "../../redux/Actions/product.actions";
import { Box, Typography } from "@mui/material";
import SwiperContainer from "components/Swiper/Swiper";
import { Banner } from "pages/Banner/Banner";
import { ProductList } from "pages/Products/ProductList";
import {
  ContainerBox,
  DisplayCard,
  ProductCardList,
  SectionHeading,
} from "components";
import "../../components/Cards/SwiperCard/SwiperCard.css";
import { promotionAction } from "redux/Actions/promotion.action";
import { useTheme } from "@mui/material/styles";
import { DataNotFound } from "components/Containers/DataNotFound";
import { goToTop } from "utils/goToTop";

type Props = {};

export const HomePage = (props: Props) => {
  const theme = useTheme();
  const productListSelector = useSelector(
    (state: any) => state.allProductListReducer.listArr.results
  );

  const [productList, setProductList] = useState([]);
  const dispatch: Dispatch<any> = useDispatch();
  const fullpromotionList = useSelector(
    (state: any) => state.promotionReducer.listArr
  );

  const promotionList = fullpromotionList.results?.slice(0, 3);

  useEffect(() => {
    dispatch(allProductListAction());
    dispatch(promotionAction());
  }, [dispatch]);

  useEffect(() => {
    setProductList(productListSelector);
  }, [productListSelector]);

  useEffect(() => {
    goToTop();
  }, []);
  return (
    <>
      <Banner />
      <ProductList />

      {/* paddding top is given Suraj  */}

      <section style={{ paddingTop: "0px" }}>
        <ContainerBox maxWidth="1200px">
          {/* Promotions spelling is changed (Suraj) */}
          <SectionHeading heading="Promotions" justifyContent="center" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {promotionList ? (
              <DisplayCard data={promotionList} />
            ) : (
              <DataNotFound />
            )}
          </Box>
        </ContainerBox>
      </section>
      <section>
        <ContainerBox maxWidth={"1200px"}>
          <SectionHeading heading="Popular Products" marginBottom="0px" />
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: "20px",
              fontWeight: "600",
              textAlign: "center",
              // margin change by Suraj
              margin: "0px 25px",
            }}
          >
            View a few of our most popular products.
          </Typography>
          {productList?.length > 0 ? (
            <ProductCardList
              height="250px"
              maxWidth="250px"
              sliceData={8}
              productList={productList}
            />
          ) : (
            <DataNotFound />
          )}
        </ContainerBox>
      </section>

      <SwiperContainer />
    </>
  );
};
