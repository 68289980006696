import React from "react";
import { CircularProgress, Box } from "@mui/material";

type Props = {};

export const LoadingComponent = (props: Props) => {
  return (
    <Box
      sx={{
        display: "grid",
        height: "100vh",
        placeContent: "center",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#ffffffba",
        zIndex: 10,
      }}
    >
      <CircularProgress size={70} />
    </Box>
  );
};
