import {
  ALL_BLOGS_LIST_TYPE,
  POST_BLOG_COMMENTS,
  SINGLE_BLOG_TYPE,
} from "../Actions/action.types/action.types";

const initialState = {
  listArr: [],
  loading: true,
};

export const allBlogListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ALL_BLOGS_LIST_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_BLOGS_LIST_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action.payload.data,
        loading: false,
      };
    case ALL_BLOGS_LIST_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

/////SINGLE BLOG REDUCER

export const singleBlogReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SINGLE_BLOG_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SINGLE_BLOG_TYPE.SUCCESS:
      return {
        ...state,
        listArr: action?.payload,
        loading: false,
      };
    case SINGLE_BLOG_TYPE.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};


export const blogCommentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case POST_BLOG_COMMENTS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_BLOG_COMMENTS.SUCCESS:
      return {
        ...state,
        listArr: action?.payload,
        loading: false,
      };
    case POST_BLOG_COMMENTS.FALIURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};