import { Dispatch, useEffect, useState } from "react";
import { Box, Pagination, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { allBlogsListAction } from "../../redux/Actions/blog.actions";
import {
  ContainerBox,
  HorizontalCard,
  SimpleCard,
  LoadingComponent,
} from "components";
import Skeleton from "@mui/material/Skeleton";
import { useLocation } from "react-router-dom";
import { goToTop } from "utils/goToTop";

type Props = {};

export const Blog = (props: Props) => {
  const location = useLocation();
  const id = location?.state?.id;
  const categoryId = location?.state?.categoryId;

  const loading = useSelector(
    (state: any) => state?.allBlogListReducer?.loading
  );

  const blogList = useSelector(
    (state: any) => state?.allBlogListReducer?.listArr?.results
  );

  const allproductList = useSelector(
    (state: any) => state?.allProductListReducer?.listArr?.results
  );

  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 5;

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogList?.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber: number) => {
    goToTop();
    setCurrentPage(pageNumber);
  };

  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    dispatch(allBlogsListAction(id, categoryId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const productList = allproductList?.slice(0, 4);

  function Variants() {
    return (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Box width="40%" height="100%">
          <Skeleton variant="rectangular" width="100%" height="300px" />
        </Box>
        <Box width="60%" display="flex" gap="10px" flexDirection="column">
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      {blogList?.length > 0 ? (
        <ContainerBox maxWidth="1200px">
          <section>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                gap: "1rem",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Box sx={{ width: { xs: "100%", sm: "60%", md: "70%" } }}>
                {loading ? (
                  <>
                    <Variants />
                    <br />
                    <hr />
                    <br />
                    <Variants />
                    <br />
                    <Variants />
                  </>
                ) : (
                  <>
                    {currentBlogs?.map((item: any, index: any) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            borderBottom: "1px solid #d0d0d0",
                          }}
                        >
                          <HorizontalCard
                            category={item?.category}
                            imageSrc={item?.base_image_uri}
                            title={item?.title}
                            date={item?.publish_date}
                            description={item?.content}
                            id={item?.id}
                            width="40%"
                            blogs
                          />
                        </Box>
                      );
                    })}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Pagination
                        count={Math.ceil(blogList.length / blogsPerPage)}
                        page={currentPage}
                        onChange={(event, page) => paginate(page)}
                        sx={{ marginTop: "2rem", justifyContent: "center" }}
                      />
                    </Box>
                  </>
                )}
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "30%", md: "25%" } }}>
                <Typography variant="h5" sx={{ paddingLeft: "24px" }}>
                  Featured Products
                </Typography>
                {productList?.map((item: any, index: any) => {
                  return (
                    <Box key={index}>
                      <SimpleCard productList={item} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </section>
        </ContainerBox>
      ) : (
        <LoadingComponent />
      )}
    </Box>
  );
};
