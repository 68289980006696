import React from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./SwiperCard.css";
import { Box } from "@mui/material";

type Props = {
  data?: any | undefined;
  children?: any | undefined;
  isMain?: Boolean | undefined;
};

const URL = process.env.REACT_APP_API_URL;

export const ProductSwiperMain = ({ isMain, data }: Props) => {
  return (
    // <ContainerBox >
    <Swiper
      className={
        isMain
          ? "swiperProductSliderWrapperModal"
          : "swiperProductSliderWrapper"
      }
      style={{ height: isMain ? "" : "100%", width: "100%" }}
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={50}
      loop={true}
      slidesPerView={1}
      speed={1500}
      navigation
    >
      <Box className="productCarousel">
        {data?.map((val: any, idx: any) => {
          return (
            <SwiperSlide className="customProductSwiper" key={idx}>
              <img
                loading="lazy"
                src={`${URL}${val}`}
                alt=""
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
              />
            </SwiperSlide>
          );
        })}
      </Box>
    </Swiper>
    // </ContainerBox>
  );
};
