import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./Accordion.css";
import { useTheme } from "@mui/material/styles";
import { ToHTML } from "utils/ToHTML";

type Props = {
  data?: any;
};

export function SimpleAccordion(props: Props) {
  const theme = useTheme();

  return (
    <section>
      <Box className="accordion" sx={{ width: "90%", margin: "0 auto" }}>
        {props?.data?.map((value: any, index: number) => {
          return (
            <>
              <Accordion key={index} className="accordionContainer">
                <AccordionSummary
                  className="accordionHeading"
                  //  sx= {{color:color? "#EC0A90" : "black"}}
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      textTransform: "uppercase",
                      fontWeight: "600",
                      lineHeight: "1.5em",
                      letterSpacing: "0.6px",
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    {value?.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ fontSize: "17px", fontWeight: "500" }}>
                  <Typography sx={{ marginBottom: "20px" }}>
                    {ToHTML(value?.description)}
                  </Typography>
                  {/* <Typography sx={{ marginBottom: "20px" }}>
                    {value?.answer[1]}
                  </Typography>
                  <Typography sx={{ marginBottom: "20px" }}>
                    {value?.answer[2]}
                  </Typography> */}
                </AccordionDetails>
              </Accordion>
            </>
          );
        })}
      </Box>
    </section>
  );
}
