import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
type Props = {
  icon?: any | undefined;
  placeholder?: any | undefined;
  color?: any | undefined;
  justifyContent?: any | undefined;
  fontWeight?: any | undefined;
  href?: any | undefined;
  onclick?: any | undefined;
};
export const CTA = (props: Props) => {
  const theme = useTheme();
  return (
    <a href={props.href} style={{ cursor: "pointer" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: props?.justifyContent
            ? props?.justifyContent
            : "center",
          transition: "all .2s linear",
          //change made by suraj
          // cursor: "pointer",
          "&:hover": {
            color: "#00AEEF",
          },
        }}
        onClick={props.onclick}
      >
        <Typography
          display="flex"
          fontSize="18px"
          color={theme.palette.primary.main}
          sx={{
            "&:hover": {
              color: "#00AEEF",
            },
          }}
        >
          {props?.icon}
        </Typography>

        <Typography
          color={props?.color ? props?.color : "#54595F"}
          letterSpacing=".6px"
          margin="0 10px"
          fontWeight={props?.fontWeight ? props?.fontWeight : "inherit"}
          sx={{
            "&:hover": {
              color: "#00AEEF",
            },
          }}
        >
          {props?.placeholder}
        </Typography>
      </Box>
    </a>
  );
};
