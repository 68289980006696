import { Typography } from "@mui/material";
import React from "react";

type Column = {
  title: string;
  key: string;
};

type Props = {
  columns: Column[];
  data: any;
};

const rows = {
  ai_link:
    "https://printcalgary.com/wp-content/uploads/2021/11/adobe-illustrator.svg",
  pdf_link: "https://printcalgary.com/wp-content/uploads/2021/11/pdf.svg",
  psd_link:
    "https://printcalgary.com/wp-content/uploads/2021/11/adobe-photoshop.svg",
  indesign_link:
    "https://printcalgary.com/wp-content/uploads/2021/11/indesign.svg",
};

export const TableComponent: React.FC<Props> = ({ columns, data }) => {
  return (
    <table
      style={{
        border: "1px solid black",
        borderCollapse: "collapse",
        width: "100%",
      }}
    >
      <thead>
        <tr>
          {columns?.map((column: any) => (
            <th
              key={column?.key}
              style={{
                border: "1px solid black",
                padding: "10px",
                textAlign: "center",
                fontSize: "1.7rem",
              }}
            >
              {column?.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row: any, index: number) => (
          <tr key={index}>
            {columns?.map((column: any, index: number) => (
              <td
                key={column?.key}
                style={{
                  border: "1px solid black",
                  padding: "10px",
                  textAlign: "center",
                  // width: "20%",
                  width: column?.key === "size_and_type" ? "30%" : "18%",
                }}
              >
                {column?.key === "size_and_type" ? (
                  <Typography sx={{ fontFamily: "sans-serif" }}>
                    {row[column?.key]}
                  </Typography>
                ) : (
                  <a
                    href={row[column?.key]}
                    type="download"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={
                        //@ts-ignore
                        rows?.[column?.key]
                      }
                      alt={column?.title}
                      width={30}
                    />
                  </a>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
